/* Container Layout Font */
@font-face {
  font-family: 'visuelt-regular-pro';
  src: url('./visuelt-regular-pro.eot');
  src: url('./visuelt-regular-pro.woff2') format('woff2'),
       url('./visuelt-regular-pro.woff') format('woff'),
       url('./visuelt-regular-pro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'visuelt-medium-pro';
  src: url('./visuelt-medium-pro.eot');
  src: url('./visuelt-medium-pro.woff2') format('woff2'),
       url('./visuelt-medium-pro.woff') format('woff'),
       url('./visuelt-medium-pro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'visuelt-bold-pro';
  src: url('./visuelt-bold-pro.eot');
  src: url('./visuelt-bold-pro.woff2') format('woff2'),
       url('./visuelt-bold-pro.woff') format('woff'),
       url('./visuelt-bold-pro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
