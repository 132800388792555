@import '../style/colors';
@import '../style/typography';

.Button {
  @include fontMedium;

  transition: background-color 0.1s;

  display: inline-block;
  padding: 0.5em 1em;
  position: relative;
  margin: 0;

  box-sizing: border-box;

  border: none;
  border-radius: 6px;

  font-size: 1rem;
  font-weight: inherit;
  line-height: 1.25;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;

  transition: all 400ms;

  white-space: nowrap;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &:focus {
    box-shadow: 0 0 0 1px $color-black-100;
  }

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
    margin: 0;
  }
}

.intent-primary {
  background-color: $color-geckoboard-green;

  &,
  &:visited,
  &:hover:not(:disabled),
  &:link,
  &:focus {
    color: $color-black-100;
  }

  &:hover:not(:disabled) {
    color: $color-black-100; // Prevent global button and anchor styles affecting the color
    background-color: darken($color-geckoboard-green, 10%);
  }
}

.intent-secondary {
  background-color: $color-background-dark;
  color: $color-black-100;

  &:hover:not(:disabled) {
    color: $color-black-100; // Prevent global button and anchor styles affecting the color
    background-color: darken($color-background-dark, 10%);
  }
}

.intent-link {
  @include fontBold;

  background-color: transparent;
  color: $color-black-100;
  text-decoration-line: underline;
  text-underline-offset: 3px;
  text-decoration-thickness: 1px;

  &:hover:not(:disabled) {
    color: $color-black-70;
    cursor: pointer;
  }

  &:hover,
  &:focus {
    text-decoration-thickness: 2px;
  }
}

.intent-negative {
  background-color: $color-negative;
  color: #ffffff;

  &:hover:not(:disabled) {
    color: #ffffff; // Prevent global button and anchor styles affecting the color
    background-color: darken($color-negative, 10%);
  }
}

.stretched {
  width: 100%;
}

.size-extra-small {
  font-size: 0.75rem;
  height: 27px;

  .spinner {
    transform: translateY(1px); // Center loading spinner vertically
  }
}

.size-small {
  font-size: 0.875rem;
  line-height: 1;
  height: 28px;

  .spinner {
    transform: translateY(1px); // Center loading spinner vertically
  }
}

.size-medium {
  font-size: 1rem;
  height: 36px;
}

.size-large {
  font-size: 1.25rem;
  line-height: 1;
  height: 40px;
}

.size-auto {
  height: auto;
  line-height: 1;
  padding: 0;
}

.loading {
  &:disabled,
  &:hover {
    opacity: 1;
  }
}

.icon {
  margin-right: 0.5rem;

  &.iconNoChildren {
    margin-right: 0;
  }
}

.spinner {
  display: inline-block;
  padding-right: 10px;
  opacity: 1;
  transform: translateY(2px); // Center loading spinner vertically
}

.spinnerTransitionEnter {
  opacity: 0;
  font-size: 0rem;
  padding-right: 0px;
}

.spinnerTransitionEnterActive {
  transition: all 0.2s;
  padding-right: 10px;
  opacity: 1;
  font-size: 1rem;
}

.spinnerTransitionExit {
  opacity: 1;
  font-size: 1rem;
  padding-right: 10px;
}

.spinnerTransitionExitActive {
  transition: all 0.2s;
  padding-right: 0px;
  opacity: 0;
  font-size: 0rem;
}

.hasIcon {
  text-decoration: none;
}
