@import '../../src/ui/style/colors';
@import '../../src/ui/style/typography';

body {
  margin: 0;
  background-color: $color-background-light;
}

.page {
  @include fontMedium;
  color: $color-black-100;

  & * {
    box-sizing: border-box;
  }
}

.headerSection {
  position: relative;
  width: 100%;
  margin: 0 auto;
  color: #ffffff;
  padding: 3rem 2rem;
  text-align: center;
}

.logo {
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-bottom: 2rem;
}

.curve {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: -100px;
  border-top: 500px solid $color-black-100;
  width: 100%;
}

.heading {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 2rem;

  h2 {
    font-size: 2rem;
    font-weight: normal;
    margin: 0 0 0.5rem;
  }

  p {
    font-size: 1.5rem;
    margin: 0;
  }
}

.formSection {
  margin-top: -2rem;
  padding: 0 2rem;

  fieldset {
    border: none;
    margin: 0;
  }
}

.formBox {
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: 30rem;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 2rem;
  border: 3px solid $color-black-100;
}

.footerSection {
  padding: 1.5rem 0 0.5rem;
  font-size: 1.2rem;
  text-align: center;

  a {
    transition-property: color, border-bottom-color;
    transition-duration: 0.2s;

    text-decoration: underline;
    border-bottom: none;
    color: $color-black-100;

    &:focus,
    &:hover {
      color: darken($color-geckoboard-green, 10%);
      outline: none;
    }
  }
}
