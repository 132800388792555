/* This is a duplicate of a file that also exists in polecat,
 * so if changes are made, both must be updated
 */

// Visualisations
$gecko-var-bar: "\e600";
$gecko-var-column: "\e601";
$gecko-var-funnel: "\e602";
$gecko-var-geckometer: "\e603";
$gecko-var-leaderboard: "\e604";
$gecko-var-line: "\e605";
$gecko-var-number: "\e606";
$gecko-var-rag: "\e607";
$gecko-var-text: "\e608";
$gecko-var-table: "\e609";
$gecko-var-feed: "\e610";

$gecko-var-monitoring: "\e800";
$gecko-var-bullet: "\e801";
$gecko-var-highcharts: "\e802";
$gecko-var-pie: "\e803";

$gecko-var-about: "\e900";
$gecko-var-filter: "\e901";
$gecko-var-format: "\e902";
$gecko-var-goal: "\e903";
$gecko-var-split-by: "\e904";
$gecko-var-split-column: "\e905";
$gecko-var-split-line: "\e906";
$gecko-var-clock: "\e907";
$gecko-var-sort-up: "\e908";
$gecko-var-sort-down: "\e909";

$gecko-var-sort: "\e90a";
$gecko-var-more-decimals: "\e90b";
$gecko-var-less-decimals: "\e90c";
$gecko-var-map: "\e90d";

$gecko-var-slack: "\e90e";
$gecko-var-star: "\e90f";
