@import '../../../src/ui/style/colors';
@import '../../../src/ui/style/typography';
@import '../../../style/font/visuelt/include';
@import '../../../style/vendor/font-awesome-variables';

.blob {
  position: absolute;
  top: 0;
  left: -16px;
  height: 48px;
  display: flex;
  align-items: center;

  &::after {
    position: absolute;
    left: 12px;
    top: 12px;
    @include fontAwesome;
    content: '\f0e0';
    color: $color-black-100;
    font-size: 20px;
    font-weight: 900;
  }
}

.formField {
  position: relative;
  margin-bottom: 1.5rem;
}

.emailInput {
  @include fontMedium;

  margin: 0;
  padding: 1rem 1rem 1rem 2.5rem;
  height: 48px;
  width: 100%;
  background: #ffffff;
  border: 2px solid $color-black-100;
  border-radius: 10px;
  color: $color-black-100;
  font-size: 1rem;
  appearance: none;

  &:focus {
    border-color: $color-geckoboard-blue;
    outline: none;
  }
}

.errorMessage {
  padding-bottom: 2rem;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    @include fontAwesome;
    content: fa-content($fa-var-exclamation-triangle);
    font-size: 1rem;
    margin-right: 0.5rem;
    color: $color-negative;
  }

  a {
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      opacity: 0.75;
    }
  }
}
