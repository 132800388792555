$color-grey-5: #f6f6f6;
$color-grey-10: #ececec;
$color-grey-20: #dadada;
$color-grey-40: #b7b7b7;
$color-grey-60: #929292;
$color-grey-80: #6e6e6e;
$color-grey-100: #4a4a4a;
$color-grey-120: #0e0e0e;

// Brand colors in CSS
// Note: These colors need to be in sync with
// the colors in the Javascript (colors.js file)
$color-geckoboard-green: #0FD354;
$color-geckoboard-yellow: #FFD339;
$color-geckoboard-blue: #00D1FF;
$color-geckoboard-white: #E9E7F9;
$color-geckoboard-mint: #3CD3D3;
$color-geckoboard-pink: #FF97AA;

$color-positive: #0FD354;
$color-warning: #FEB554;
$color-negative: #EC373C;

$color-positive-on-dark: #7ACD78;
$color-negative-on-dark: #FC5C6A;

$color-black-10: #E8E7EB;
$color-black-20: #D0CFD8;
$color-black-40: #A2A0B0;
$color-black-60: #737089;
$color-black-70: #5C5875;
$color-black-90: #2E294E;
$color-black-100: #16113A;

$color-background-light: #F8F8F9;
$color-background-mid: #E6E9F1;
$color-background-dark: #DFE4EE;

$color-marketing-mint: #3CD3DC;
