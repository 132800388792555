/**** FONT AWESOME V6.2.1 *****/

@mixin fontAwesome {
  font-family: "Font Awesome 6 Pro";
}

// Font Awesome font weights
$fa-weight-light: 300;
$fa-weight-regular: 400;
$fa-weight-solid: 900;

// fa-content - convenience function used to set content property
@function fa-content($fa-var) {
  @return unquote("\"#{ $fa-var }\"");
}

// Font Awesome variables (copied from font awesome SASS pack)
$fa-var-0: \30;
$fa-var-1: \31;
$fa-var-2: \32;
$fa-var-3: \33;
$fa-var-4: \34;
$fa-var-5: \35;
$fa-var-6: \36;
$fa-var-7: \37;
$fa-var-8: \38;
$fa-var-9: \39;
$fa-var-fill-drip: \f576;
$fa-var-arrows-to-circle: \e4bd;
$fa-var-circle-chevron-right: \f138;
$fa-var-chevron-circle-right: \f138;
$fa-var-wagon-covered: \f8ee;
$fa-var-line-height: \f871;
$fa-var-bagel: \e3d7;
$fa-var-transporter-7: \e2a8;
$fa-var-at: \40;
$fa-var-rectangles-mixed: \e323;
$fa-var-phone-arrow-up-right: \e224;
$fa-var-phone-arrow-up: \e224;
$fa-var-phone-outgoing: \e224;
$fa-var-trash-can: \f2ed;
$fa-var-trash-alt: \f2ed;
$fa-var-circle-l: \e114;
$fa-var-head-side-goggles: \f6ea;
$fa-var-head-vr: \f6ea;
$fa-var-text-height: \f034;
$fa-var-user-xmark: \f235;
$fa-var-user-times: \f235;
$fa-var-face-hand-yawn: \e379;
$fa-var-gauge-simple-min: \f62d;
$fa-var-tachometer-slowest: \f62d;
$fa-var-stethoscope: \f0f1;
$fa-var-coffin: \f6c6;
$fa-var-message: \f27a;
$fa-var-comment-alt: \f27a;
$fa-var-salad: \f81e;
$fa-var-bowl-salad: \f81e;
$fa-var-info: \f129;
$fa-var-robot-astromech: \e2d2;
$fa-var-ring-diamond: \e5ab;
$fa-var-fondue-pot: \e40d;
$fa-var-theta: \f69e;
$fa-var-face-hand-peeking: \e481;
$fa-var-square-user: \e283;
$fa-var-down-left-and-up-right-to-center: \f422;
$fa-var-compress-alt: \f422;
$fa-var-explosion: \e4e9;
$fa-var-file-lines: \f15c;
$fa-var-file-alt: \f15c;
$fa-var-file-text: \f15c;
$fa-var-wave-square: \f83e;
$fa-var-ring: \f70b;
$fa-var-building-un: \e4d9;
$fa-var-dice-three: \f527;
$fa-var-tire-pressure-warning: \f633;
$fa-var-wifi-fair: \f6ab;
$fa-var-wifi-2: \f6ab;
$fa-var-calendar-days: \f073;
$fa-var-calendar-alt: \f073;
$fa-var-mp3-player: \f8ce;
$fa-var-anchor-circle-check: \e4aa;
$fa-var-tally-4: \e297;
$fa-var-rectangle-history: \e4a2;
$fa-var-building-circle-arrow-right: \e4d1;
$fa-var-volleyball: \f45f;
$fa-var-volleyball-ball: \f45f;
$fa-var-sun-haze: \f765;
$fa-var-text-size: \f894;
$fa-var-ufo: \e047;
$fa-var-fork: \f2e3;
$fa-var-utensil-fork: \f2e3;
$fa-var-arrows-up-to-line: \e4c2;
$fa-var-mobile-signal: \e1ef;
$fa-var-barcode-scan: \f465;
$fa-var-sort-down: \f0dd;
$fa-var-sort-desc: \f0dd;
$fa-var-folder-arrow-down: \e053;
$fa-var-folder-download: \e053;
$fa-var-circle-minus: \f056;
$fa-var-minus-circle: \f056;
$fa-var-face-icicles: \e37c;
$fa-var-shovel: \f713;
$fa-var-door-open: \f52b;
$fa-var-films: \e17a;
$fa-var-right-from-bracket: \f2f5;
$fa-var-sign-out-alt: \f2f5;
$fa-var-face-glasses: \e377;
$fa-var-nfc: \e1f7;
$fa-var-atom: \f5d2;
$fa-var-soap: \e06e;
$fa-var-icons: \f86d;
$fa-var-heart-music-camera-bolt: \f86d;
$fa-var-microphone-lines-slash: \f539;
$fa-var-microphone-alt-slash: \f539;
$fa-var-closed-captioning-slash: \e135;
$fa-var-calculator-simple: \f64c;
$fa-var-calculator-alt: \f64c;
$fa-var-bridge-circle-check: \e4c9;
$fa-var-sliders-up: \f3f1;
$fa-var-sliders-v: \f3f1;
$fa-var-location-minus: \f609;
$fa-var-map-marker-minus: \f609;
$fa-var-pump-medical: \e06a;
$fa-var-fingerprint: \f577;
$fa-var-ski-boot: \e3cc;
$fa-var-standard-definition: \e28a;
$fa-var-rectangle-sd: \e28a;
$fa-var-h1: \f313;
$fa-var-hand-point-right: \f0a4;
$fa-var-magnifying-glass-location: \f689;
$fa-var-search-location: \f689;
$fa-var-message-bot: \e3b8;
$fa-var-forward-step: \f051;
$fa-var-step-forward: \f051;
$fa-var-face-smile-beam: \f5b8;
$fa-var-smile-beam: \f5b8;
$fa-var-light-ceiling: \e016;
$fa-var-message-exclamation: \f4a5;
$fa-var-comment-alt-exclamation: \f4a5;
$fa-var-bowl-scoop: \e3de;
$fa-var-bowl-shaved-ice: \e3de;
$fa-var-square-x: \e286;
$fa-var-utility-pole-double: \e2c4;
$fa-var-flag-checkered: \f11e;
$fa-var-chevrons-up: \f325;
$fa-var-chevron-double-up: \f325;
$fa-var-football: \f44e;
$fa-var-football-ball: \f44e;
$fa-var-user-vneck: \e461;
$fa-var-school-circle-exclamation: \e56c;
$fa-var-crop: \f125;
$fa-var-angles-down: \f103;
$fa-var-angle-double-down: \f103;
$fa-var-users-rectangle: \e594;
$fa-var-people-roof: \e537;
$fa-var-square-arrow-right: \f33b;
$fa-var-arrow-square-right: \f33b;
$fa-var-location-plus: \f60a;
$fa-var-map-marker-plus: \f60a;
$fa-var-lightbulb-exclamation-on: \e1ca;
$fa-var-people-line: \e534;
$fa-var-beer-mug-empty: \f0fc;
$fa-var-beer: \f0fc;
$fa-var-crate-empty: \e151;
$fa-var-diagram-predecessor: \e477;
$fa-var-transporter: \e042;
$fa-var-calendar-circle-user: \e471;
$fa-var-arrow-up-long: \f176;
$fa-var-long-arrow-up: \f176;
$fa-var-person-carry-box: \f4cf;
$fa-var-person-carry: \f4cf;
$fa-var-fire-flame-simple: \f46a;
$fa-var-burn: \f46a;
$fa-var-person: \f183;
$fa-var-male: \f183;
$fa-var-laptop: \f109;
$fa-var-file-csv: \f6dd;
$fa-var-menorah: \f676;
$fa-var-union: \f6a2;
$fa-var-chevrons-left: \f323;
$fa-var-chevron-double-left: \f323;
$fa-var-circle-heart: \f4c7;
$fa-var-heart-circle: \f4c7;
$fa-var-truck-plane: \e58f;
$fa-var-record-vinyl: \f8d9;
$fa-var-bring-forward: \f856;
$fa-var-square-p: \e279;
$fa-var-face-grin-stars: \f587;
$fa-var-grin-stars: \f587;
$fa-var-sigma: \f68b;
$fa-var-camera-movie: \f8a9;
$fa-var-bong: \f55c;
$fa-var-clarinet: \f8ad;
$fa-var-truck-flatbed: \e2b6;
$fa-var-spaghetti-monster-flying: \f67b;
$fa-var-pastafarianism: \f67b;
$fa-var-arrow-down-up-across-line: \e4af;
$fa-var-leaf-heart: \f4cb;
$fa-var-house-building: \e1b1;
$fa-var-cheese-swiss: \f7f0;
$fa-var-spoon: \f2e5;
$fa-var-utensil-spoon: \f2e5;
$fa-var-jar-wheat: \e517;
$fa-var-envelopes-bulk: \f674;
$fa-var-mail-bulk: \f674;
$fa-var-file-circle-exclamation: \e4eb;
$fa-var-bow-arrow: \f6b9;
$fa-var-cart-xmark: \e0dd;
$fa-var-hexagon-xmark: \f2ee;
$fa-var-times-hexagon: \f2ee;
$fa-var-xmark-hexagon: \f2ee;
$fa-var-circle-h: \f47e;
$fa-var-hospital-symbol: \f47e;
$fa-var-merge: \e526;
$fa-var-pager: \f815;
$fa-var-cart-minus: \e0db;
$fa-var-address-book: \f2b9;
$fa-var-contact-book: \f2b9;
$fa-var-pan-frying: \e42c;
$fa-var-grid: \e195;
$fa-var-grid-3: \e195;
$fa-var-football-helmet: \f44f;
$fa-var-hand-love: \e1a5;
$fa-var-trees: \f724;
$fa-var-strikethrough: \f0cc;
$fa-var-page: \e428;
$fa-var-k: \4b;
$fa-var-diagram-previous: \e478;
$fa-var-gauge-min: \f628;
$fa-var-tachometer-alt-slowest: \f628;
$fa-var-folder-grid: \e188;
$fa-var-eggplant: \e16c;
$fa-var-ram: \f70a;
$fa-var-landmark-flag: \e51c;
$fa-var-lips: \f600;
$fa-var-pencil: \f303;
$fa-var-pencil-alt: \f303;
$fa-var-backward: \f04a;
$fa-var-caret-right: \f0da;
$fa-var-comments: \f086;
$fa-var-paste: \f0ea;
$fa-var-file-clipboard: \f0ea;
$fa-var-desktop-arrow-down: \e155;
$fa-var-code-pull-request: \e13c;
$fa-var-pumpkin: \f707;
$fa-var-clipboard-list: \f46d;
$fa-var-pen-field: \e211;
$fa-var-blueberries: \e2e8;
$fa-var-truck-ramp-box: \f4de;
$fa-var-truck-loading: \f4de;
$fa-var-note: \e1ff;
$fa-var-arrow-down-to-square: \e096;
$fa-var-user-check: \f4fc;
$fa-var-cloud-xmark: \e35f;
$fa-var-vial-virus: \e597;
$fa-var-book-blank: \f5d9;
$fa-var-book-alt: \f5d9;
$fa-var-golf-flag-hole: \e3ac;
$fa-var-message-arrow-down: \e1db;
$fa-var-comment-alt-arrow-down: \e1db;
$fa-var-face-unamused: \e39f;
$fa-var-sheet-plastic: \e571;
$fa-var-circle-9: \e0f6;
$fa-var-blog: \f781;
$fa-var-user-ninja: \f504;
$fa-var-pencil-slash: \e215;
$fa-var-bowling-pins: \f437;
$fa-var-person-arrow-up-from-line: \e539;
$fa-var-down-right: \e16b;
$fa-var-scroll-torah: \f6a0;
$fa-var-torah: \f6a0;
$fa-var-blinds-open: \f8fc;
$fa-var-fence: \e303;
$fa-var-up: \f357;
$fa-var-arrow-alt-up: \f357;
$fa-var-broom-ball: \f458;
$fa-var-quidditch: \f458;
$fa-var-quidditch-broom-ball: \f458;
$fa-var-drumstick: \f6d6;
$fa-var-square-v: \e284;
$fa-var-face-awesome: \e409;
$fa-var-gave-dandy: \e409;
$fa-var-dial-off: \e162;
$fa-var-toggle-off: \f204;
$fa-var-face-smile-horns: \e391;
$fa-var-box-archive: \f187;
$fa-var-archive: \f187;
$fa-var-grapes: \e306;
$fa-var-person-drowning: \e545;
$fa-var-dial-max: \e15e;
$fa-var-circle-m: \e115;
$fa-var-calendar-image: \e0d4;
$fa-var-circle-caret-down: \f32d;
$fa-var-caret-circle-down: \f32d;
$fa-var-arrow-down-9-1: \f886;
$fa-var-sort-numeric-desc: \f886;
$fa-var-sort-numeric-down-alt: \f886;
$fa-var-face-grin-tongue-squint: \f58a;
$fa-var-grin-tongue-squint: \f58a;
$fa-var-shish-kebab: \f821;
$fa-var-spray-can: \f5bd;
$fa-var-alarm-snooze: \f845;
$fa-var-scarecrow: \f70d;
$fa-var-truck-monster: \f63b;
$fa-var-gift-card: \f663;
$fa-var-w: \57;
$fa-var-code-pull-request-draft: \e3fa;
$fa-var-square-b: \e264;
$fa-var-elephant: \f6da;
$fa-var-earth-africa: \f57c;
$fa-var-globe-africa: \f57c;
$fa-var-rainbow: \f75b;
$fa-var-circle-notch: \f1ce;
$fa-var-tablet-screen-button: \f3fa;
$fa-var-tablet-alt: \f3fa;
$fa-var-paw: \f1b0;
$fa-var-message-question: \e1e3;
$fa-var-cloud: \f0c2;
$fa-var-trowel-bricks: \e58a;
$fa-var-square-3: \e258;
$fa-var-face-flushed: \f579;
$fa-var-flushed: \f579;
$fa-var-hospital-user: \f80d;
$fa-var-microwave: \e01b;
$fa-var-tent-arrow-left-right: \e57f;
$fa-var-cart-circle-arrow-up: \e3f0;
$fa-var-trash-clock: \e2b0;
$fa-var-gavel: \f0e3;
$fa-var-legal: \f0e3;
$fa-var-sprinkler-ceiling: \e44c;
$fa-var-browsers: \e0cb;
$fa-var-trillium: \e588;
$fa-var-music-slash: \f8d1;
$fa-var-truck-ramp: \f4e0;
$fa-var-binoculars: \f1e5;
$fa-var-microphone-slash: \f131;
$fa-var-box-tissue: \e05b;
$fa-var-circle-c: \e101;
$fa-var-star-christmas: \f7d4;
$fa-var-chart-bullet: \e0e1;
$fa-var-motorcycle: \f21c;
$fa-var-tree-christmas: \f7db;
$fa-var-tire-flat: \f632;
$fa-var-sunglasses: \f892;
$fa-var-badge: \f335;
$fa-var-message-pen: \f4a4;
$fa-var-comment-alt-edit: \f4a4;
$fa-var-message-edit: \f4a4;
$fa-var-bell-concierge: \f562;
$fa-var-concierge-bell: \f562;
$fa-var-pen-ruler: \f5ae;
$fa-var-pencil-ruler: \f5ae;
$fa-var-chess-rook-piece: \f448;
$fa-var-chess-rook-alt: \f448;
$fa-var-square-root: \f697;
$fa-var-album-collection-circle-plus: \e48e;
$fa-var-people-arrows: \e068;
$fa-var-people-arrows-left-right: \e068;
$fa-var-face-angry-horns: \e368;
$fa-var-mars-and-venus-burst: \e523;
$fa-var-tombstone: \f720;
$fa-var-square-caret-right: \f152;
$fa-var-caret-square-right: \f152;
$fa-var-scissors: \f0c4;
$fa-var-cut: \f0c4;
$fa-var-list-music: \f8c9;
$fa-var-sun-plant-wilt: \e57a;
$fa-var-toilets-portable: \e584;
$fa-var-hockey-puck: \f453;
$fa-var-mustache: \e5bc;
$fa-var-hyphen: \2d;
$fa-var-table: \f0ce;
$fa-var-user-chef: \e3d2;
$fa-var-message-image: \e1e0;
$fa-var-comment-alt-image: \e1e0;
$fa-var-users-medical: \f830;
$fa-var-sensor-triangle-exclamation: \e029;
$fa-var-sensor-alert: \e029;
$fa-var-magnifying-glass-arrow-right: \e521;
$fa-var-tachograph-digital: \f566;
$fa-var-digital-tachograph: \f566;
$fa-var-face-mask: \e37f;
$fa-var-pickleball: \e435;
$fa-var-star-sharp-half: \e28c;
$fa-var-users-slash: \e073;
$fa-var-clover: \e139;
$fa-var-meat: \f814;
$fa-var-reply: \f3e5;
$fa-var-mail-reply: \f3e5;
$fa-var-star-and-crescent: \f699;
$fa-var-empty-set: \f656;
$fa-var-house-fire: \e50c;
$fa-var-square-minus: \f146;
$fa-var-minus-square: \f146;
$fa-var-helicopter: \f533;
$fa-var-bird: \e469;
$fa-var-compass: \f14e;
$fa-var-square-caret-down: \f150;
$fa-var-caret-square-down: \f150;
$fa-var-heart-half-stroke: \e1ac;
$fa-var-heart-half-alt: \e1ac;
$fa-var-file-circle-question: \e4ef;
$fa-var-laptop-code: \f5fc;
$fa-var-joystick: \f8c5;
$fa-var-grill-fire: \e5a4;
$fa-var-rectangle-vertical-history: \e237;
$fa-var-swatchbook: \f5c3;
$fa-var-prescription-bottle: \f485;
$fa-var-bars: \f0c9;
$fa-var-navicon: \f0c9;
$fa-var-keyboard-left: \e1c3;
$fa-var-people-group: \e533;
$fa-var-hourglass-end: \f253;
$fa-var-hourglass-3: \f253;
$fa-var-heart-crack: \f7a9;
$fa-var-heart-broken: \f7a9;
$fa-var-face-beam-hand-over-mouth: \e47c;
$fa-var-droplet-percent: \f750;
$fa-var-humidity: \f750;
$fa-var-square-up-right: \f360;
$fa-var-external-link-square-alt: \f360;
$fa-var-face-kiss-beam: \f597;
$fa-var-kiss-beam: \f597;
$fa-var-corn: \f6c7;
$fa-var-roller-coaster: \e324;
$fa-var-photo-film-music: \e228;
$fa-var-radar: \e024;
$fa-var-sickle: \f822;
$fa-var-film: \f008;
$fa-var-coconut: \e2f6;
$fa-var-ruler-horizontal: \f547;
$fa-var-shield-cross: \f712;
$fa-var-cassette-tape: \f8ab;
$fa-var-square-terminal: \e32a;
$fa-var-people-robbery: \e536;
$fa-var-lightbulb: \f0eb;
$fa-var-caret-left: \f0d9;
$fa-var-comment-middle: \e149;
$fa-var-trash-can-list: \e2ab;
$fa-var-block: \e46a;
$fa-var-circle-exclamation: \f06a;
$fa-var-exclamation-circle: \f06a;
$fa-var-school-circle-xmark: \e56d;
$fa-var-arrow-right-from-bracket: \f08b;
$fa-var-sign-out: \f08b;
$fa-var-face-frown-slight: \e376;
$fa-var-circle-chevron-down: \f13a;
$fa-var-chevron-circle-down: \f13a;
$fa-var-sidebar-flip: \e24f;
$fa-var-unlock-keyhole: \f13e;
$fa-var-unlock-alt: \f13e;
$fa-var-temperature-list: \e299;
$fa-var-cloud-showers-heavy: \f740;
$fa-var-headphones-simple: \f58f;
$fa-var-headphones-alt: \f58f;
$fa-var-sitemap: \f0e8;
$fa-var-pipe-section: \e438;
$fa-var-space-station-moon-construction: \e034;
$fa-var-space-station-moon-alt: \e034;
$fa-var-circle-dollar-to-slot: \f4b9;
$fa-var-donate: \f4b9;
$fa-var-memory: \f538;
$fa-var-face-sleeping: \e38d;
$fa-var-road-spikes: \e568;
$fa-var-fire-burner: \e4f1;
$fa-var-squirrel: \f71a;
$fa-var-arrow-up-to-line: \f341;
$fa-var-arrow-to-top: \f341;
$fa-var-flag: \f024;
$fa-var-face-cowboy-hat: \e36e;
$fa-var-hanukiah: \f6e6;
$fa-var-chart-scatter-3d: \e0e8;
$fa-var-square-code: \e267;
$fa-var-feather: \f52d;
$fa-var-volume-low: \f027;
$fa-var-volume-down: \f027;
$fa-var-xmark-to-slot: \f771;
$fa-var-times-to-slot: \f771;
$fa-var-vote-nay: \f771;
$fa-var-box-taped: \f49a;
$fa-var-box-alt: \f49a;
$fa-var-comment-slash: \f4b3;
$fa-var-swords: \f71d;
$fa-var-cloud-sun-rain: \f743;
$fa-var-album: \f89f;
$fa-var-circle-n: \e118;
$fa-var-compress: \f066;
$fa-var-wheat-awn: \e2cd;
$fa-var-wheat-alt: \e2cd;
$fa-var-ankh: \f644;
$fa-var-hands-holding-child: \e4fa;
$fa-var-asterisk: \2a;
$fa-var-key-skeleton-left-right: \e3b4;
$fa-var-comment-lines: \f4b0;
$fa-var-luchador-mask: \f455;
$fa-var-luchador: \f455;
$fa-var-mask-luchador: \f455;
$fa-var-square-check: \f14a;
$fa-var-check-square: \f14a;
$fa-var-shredder: \f68a;
$fa-var-book-open-cover: \e0c0;
$fa-var-book-open-alt: \e0c0;
$fa-var-sandwich: \f81f;
$fa-var-peseta-sign: \e221;
$fa-var-square-parking-slash: \f617;
$fa-var-parking-slash: \f617;
$fa-var-train-tunnel: \e454;
$fa-var-heading: \f1dc;
$fa-var-header: \f1dc;
$fa-var-ghost: \f6e2;
$fa-var-face-anguished: \e369;
$fa-var-hockey-sticks: \f454;
$fa-var-abacus: \f640;
$fa-var-film-simple: \f3a0;
$fa-var-film-alt: \f3a0;
$fa-var-list: \f03a;
$fa-var-list-squares: \f03a;
$fa-var-tree-palm: \f82b;
$fa-var-square-phone-flip: \f87b;
$fa-var-phone-square-alt: \f87b;
$fa-var-cart-plus: \f217;
$fa-var-gamepad: \f11b;
$fa-var-border-center-v: \f89d;
$fa-var-circle-dot: \f192;
$fa-var-dot-circle: \f192;
$fa-var-clipboard-medical: \e133;
$fa-var-face-dizzy: \f567;
$fa-var-dizzy: \f567;
$fa-var-egg: \f7fb;
$fa-var-up-to-line: \f34d;
$fa-var-arrow-alt-to-top: \f34d;
$fa-var-house-medical-circle-xmark: \e513;
$fa-var-watch-fitness: \f63e;
$fa-var-clock-nine-thirty: \e34d;
$fa-var-campground: \f6bb;
$fa-var-folder-plus: \f65e;
$fa-var-jug: \f8c6;
$fa-var-futbol: \f1e3;
$fa-var-futbol-ball: \f1e3;
$fa-var-soccer-ball: \f1e3;
$fa-var-snow-blowing: \f761;
$fa-var-paintbrush: \f1fc;
$fa-var-paint-brush: \f1fc;
$fa-var-lock: \f023;
$fa-var-arrow-down-from-line: \f345;
$fa-var-arrow-from-top: \f345;
$fa-var-gas-pump: \f52f;
$fa-var-signal-bars-slash: \f694;
$fa-var-signal-alt-slash: \f694;
$fa-var-monkey: \f6fb;
$fa-var-rectangle-pro: \e235;
$fa-var-pro: \e235;
$fa-var-house-night: \e010;
$fa-var-hot-tub-person: \f593;
$fa-var-hot-tub: \f593;
$fa-var-blanket: \f498;
$fa-var-map-location: \f59f;
$fa-var-map-marked: \f59f;
$fa-var-house-flood-water: \e50e;
$fa-var-comments-question-check: \e14f;
$fa-var-tree: \f1bb;
$fa-var-arrows-cross: \e0a2;
$fa-var-backpack: \f5d4;
$fa-var-square-small: \e27e;
$fa-var-folder-arrow-up: \e054;
$fa-var-folder-upload: \e054;
$fa-var-bridge-lock: \e4cc;
$fa-var-crosshairs-simple: \e59f;
$fa-var-sack-dollar: \f81d;
$fa-var-pen-to-square: \f044;
$fa-var-edit: \f044;
$fa-var-square-sliders: \f3f0;
$fa-var-sliders-h-square: \f3f0;
$fa-var-car-side: \f5e4;
$fa-var-message-middle-top: \e1e2;
$fa-var-comment-middle-top-alt: \e1e2;
$fa-var-lightbulb-on: \f672;
$fa-var-knife: \f2e4;
$fa-var-utensil-knife: \f2e4;
$fa-var-share-nodes: \f1e0;
$fa-var-share-alt: \f1e0;
$fa-var-wave-sine: \f899;
$fa-var-heart-circle-minus: \e4ff;
$fa-var-circle-w: \e12c;
$fa-var-circle-calendar: \e102;
$fa-var-calendar-circle: \e102;
$fa-var-hourglass-half: \f252;
$fa-var-hourglass-2: \f252;
$fa-var-microscope: \f610;
$fa-var-sunset: \f767;
$fa-var-sink: \e06d;
$fa-var-calendar-exclamation: \f334;
$fa-var-truck-container-empty: \e2b5;
$fa-var-hand-heart: \f4bc;
$fa-var-bag-shopping: \f290;
$fa-var-shopping-bag: \f290;
$fa-var-arrow-down-z-a: \f881;
$fa-var-sort-alpha-desc: \f881;
$fa-var-sort-alpha-down-alt: \f881;
$fa-var-mitten: \f7b5;
$fa-var-reply-clock: \e239;
$fa-var-reply-time: \e239;
$fa-var-person-rays: \e54d;
$fa-var-right: \f356;
$fa-var-arrow-alt-right: \f356;
$fa-var-circle-f: \e10e;
$fa-var-users: \f0c0;
$fa-var-face-pleading: \e386;
$fa-var-eye-slash: \f070;
$fa-var-flask-vial: \e4f3;
$fa-var-police-box: \e021;
$fa-var-cucumber: \e401;
$fa-var-head-side-brain: \f808;
$fa-var-hand: \f256;
$fa-var-hand-paper: \f256;
$fa-var-person-biking-mountain: \f84b;
$fa-var-biking-mountain: \f84b;
$fa-var-utensils-slash: \e464;
$fa-var-print-magnifying-glass: \f81a;
$fa-var-print-search: \f81a;
$fa-var-folder-bookmark: \e186;
$fa-var-om: \f679;
$fa-var-pi: \f67e;
$fa-var-flask-round-potion: \f6e1;
$fa-var-flask-potion: \f6e1;
$fa-var-face-shush: \e38c;
$fa-var-worm: \e599;
$fa-var-house-circle-xmark: \e50b;
$fa-var-plug: \f1e6;
$fa-var-calendar-circle-exclamation: \e46e;
$fa-var-square-i: \e272;
$fa-var-chevron-up: \f077;
$fa-var-face-saluting: \e484;
$fa-var-gauge-simple-low: \f62c;
$fa-var-tachometer-slow: \f62c;
$fa-var-face-persevering: \e385;
$fa-var-circle-camera: \e103;
$fa-var-camera-circle: \e103;
$fa-var-hand-spock: \f259;
$fa-var-spider-web: \f719;
$fa-var-circle-microphone: \e116;
$fa-var-microphone-circle: \e116;
$fa-var-book-arrow-up: \e0ba;
$fa-var-popsicle: \e43e;
$fa-var-command: \e142;
$fa-var-blinds: \f8fb;
$fa-var-stopwatch: \f2f2;
$fa-var-saxophone: \f8dc;
$fa-var-square-2: \e257;
$fa-var-field-hockey-stick-ball: \f44c;
$fa-var-field-hockey: \f44c;
$fa-var-arrow-up-square-triangle: \f88b;
$fa-var-sort-shapes-up-alt: \f88b;
$fa-var-face-scream: \e38b;
$fa-var-square-m: \e276;
$fa-var-camera-web: \f832;
$fa-var-webcam: \f832;
$fa-var-comment-arrow-down: \e143;
$fa-var-lightbulb-cfl: \e5a6;
$fa-var-window-frame-open: \e050;
$fa-var-face-kiss: \f596;
$fa-var-kiss: \f596;
$fa-var-bridge-circle-xmark: \e4cb;
$fa-var-period: \2e;
$fa-var-face-grin-tongue: \f589;
$fa-var-grin-tongue: \f589;
$fa-var-up-to-dotted-line: \e457;
$fa-var-thought-bubble: \e32e;
$fa-var-raygun: \e025;
$fa-var-flute: \f8b9;
$fa-var-acorn: \f6ae;
$fa-var-video-arrow-up-right: \e2c9;
$fa-var-grate-droplet: \e194;
$fa-var-seal-exclamation: \e242;
$fa-var-chess-bishop: \f43a;
$fa-var-message-sms: \e1e5;
$fa-var-coffee-beans: \e13f;
$fa-var-hat-witch: \f6e7;
$fa-var-face-grin-wink: \f58c;
$fa-var-grin-wink: \f58c;
$fa-var-clock-three-thirty: \e357;
$fa-var-ear-deaf: \f2a4;
$fa-var-deaf: \f2a4;
$fa-var-deafness: \f2a4;
$fa-var-hard-of-hearing: \f2a4;
$fa-var-alarm-clock: \f34e;
$fa-var-eclipse: \f749;
$fa-var-face-relieved: \e389;
$fa-var-road-circle-check: \e564;
$fa-var-dice-five: \f523;
$fa-var-octagon-minus: \f308;
$fa-var-minus-octagon: \f308;
$fa-var-square-rss: \f143;
$fa-var-rss-square: \f143;
$fa-var-face-zany: \e3a4;
$fa-var-tricycle: \e5c3;
$fa-var-land-mine-on: \e51b;
$fa-var-square-arrow-up-left: \e263;
$fa-var-i-cursor: \f246;
$fa-var-salt-shaker: \e446;
$fa-var-stamp: \f5bf;
$fa-var-file-plus: \f319;
$fa-var-draw-square: \f5ef;
$fa-var-toilet-paper-under-slash: \e2a1;
$fa-var-toilet-paper-reverse-slash: \e2a1;
$fa-var-stairs: \e289;
$fa-var-drone-front: \f860;
$fa-var-drone-alt: \f860;
$fa-var-glass-empty: \e191;
$fa-var-dial-high: \e15c;
$fa-var-user-helmet-safety: \f82c;
$fa-var-user-construction: \f82c;
$fa-var-user-hard-hat: \f82c;
$fa-var-i: \49;
$fa-var-hryvnia-sign: \f6f2;
$fa-var-hryvnia: \f6f2;
$fa-var-arrow-down-left-and-arrow-up-right-to-center: \e092;
$fa-var-pills: \f484;
$fa-var-face-grin-wide: \f581;
$fa-var-grin-alt: \f581;
$fa-var-tooth: \f5c9;
$fa-var-basketball-hoop: \f435;
$fa-var-objects-align-bottom: \e3bb;
$fa-var-v: \56;
$fa-var-sparkles: \f890;
$fa-var-squid: \e450;
$fa-var-leafy-green: \e41d;
$fa-var-circle-arrow-up-right: \e0fc;
$fa-var-calendars: \e0d7;
$fa-var-bangladeshi-taka-sign: \e2e6;
$fa-var-bicycle: \f206;
$fa-var-hammer-war: \f6e4;
$fa-var-circle-d: \e104;
$fa-var-spider-black-widow: \f718;
$fa-var-staff-snake: \e579;
$fa-var-rod-asclepius: \e579;
$fa-var-rod-snake: \e579;
$fa-var-staff-aesculapius: \e579;
$fa-var-pear: \e20c;
$fa-var-head-side-cough-slash: \e062;
$fa-var-triangle: \f2ec;
$fa-var-apartment: \e468;
$fa-var-truck-medical: \f0f9;
$fa-var-ambulance: \f0f9;
$fa-var-pepper: \e432;
$fa-var-piano: \f8d4;
$fa-var-gun-squirt: \e19d;
$fa-var-wheat-awn-circle-exclamation: \e598;
$fa-var-snowman: \f7d0;
$fa-var-user-alien: \e04a;
$fa-var-shield-check: \f2f7;
$fa-var-mortar-pestle: \f5a7;
$fa-var-road-barrier: \e562;
$fa-var-chart-candlestick: \e0e2;
$fa-var-briefcase-blank: \e0c8;
$fa-var-school: \f549;
$fa-var-igloo: \f7ae;
$fa-var-bracket-round: \28;
$fa-var-parenthesis: \28;
$fa-var-joint: \f595;
$fa-var-horse-saddle: \f8c3;
$fa-var-mug-marshmallows: \f7b7;
$fa-var-filters: \e17e;
$fa-var-bell-on: \f8fa;
$fa-var-angle-right: \f105;
$fa-var-dial-med: \e15f;
$fa-var-horse: \f6f0;
$fa-var-q: \51;
$fa-var-monitor-waveform: \f611;
$fa-var-monitor-heart-rate: \f611;
$fa-var-link-simple: \e1cd;
$fa-var-whistle: \f460;
$fa-var-g: \47;
$fa-var-wine-glass-crack: \f4bb;
$fa-var-fragile: \f4bb;
$fa-var-slot-machine: \e3ce;
$fa-var-notes-medical: \f481;
$fa-var-car-wash: \f5e6;
$fa-var-escalator: \e171;
$fa-var-comment-image: \e148;
$fa-var-temperature-half: \f2c9;
$fa-var-temperature-2: \f2c9;
$fa-var-thermometer-2: \f2c9;
$fa-var-thermometer-half: \f2c9;
$fa-var-dong-sign: \e169;
$fa-var-donut: \e406;
$fa-var-doughnut: \e406;
$fa-var-capsules: \f46b;
$fa-var-poo-storm: \f75a;
$fa-var-poo-bolt: \f75a;
$fa-var-tally-1: \e294;
$fa-var-face-frown-open: \f57a;
$fa-var-frown-open: \f57a;
$fa-var-square-dashed: \e269;
$fa-var-square-j: \e273;
$fa-var-hand-point-up: \f0a6;
$fa-var-money-bill: \f0d6;
$fa-var-arrow-up-big-small: \f88e;
$fa-var-sort-size-up: \f88e;
$fa-var-barcode-read: \f464;
$fa-var-baguette: \e3d8;
$fa-var-bowl-soft-serve: \e46b;
$fa-var-face-holding-back-tears: \e482;
$fa-var-square-up: \f353;
$fa-var-arrow-alt-square-up: \f353;
$fa-var-train-subway-tunnel: \e2a3;
$fa-var-subway-tunnel: \e2a3;
$fa-var-square-exclamation: \f321;
$fa-var-exclamation-square: \f321;
$fa-var-semicolon: \3b;
$fa-var-bookmark: \f02e;
$fa-var-fan-table: \e004;
$fa-var-align-justify: \f039;
$fa-var-battery-low: \e0b1;
$fa-var-battery-1: \e0b1;
$fa-var-credit-card-front: \f38a;
$fa-var-brain-arrow-curved-right: \f677;
$fa-var-mind-share: \f677;
$fa-var-umbrella-beach: \f5ca;
$fa-var-helmet-un: \e503;
$fa-var-location-smile: \f60d;
$fa-var-map-marker-smile: \f60d;
$fa-var-arrow-left-to-line: \f33e;
$fa-var-arrow-to-left: \f33e;
$fa-var-bullseye: \f140;
$fa-var-sushi: \e48a;
$fa-var-nigiri: \e48a;
$fa-var-message-captions: \e1de;
$fa-var-comment-alt-captions: \e1de;
$fa-var-trash-list: \e2b1;
$fa-var-bacon: \f7e5;
$fa-var-option: \e318;
$fa-var-hand-point-down: \f0a7;
$fa-var-arrow-up-from-bracket: \e09a;
$fa-var-trash-plus: \e2b2;
$fa-var-objects-align-top: \e3c0;
$fa-var-folder: \f07b;
$fa-var-folder-blank: \f07b;
$fa-var-face-anxious-sweat: \e36a;
$fa-var-credit-card-blank: \f389;
$fa-var-file-waveform: \f478;
$fa-var-file-medical-alt: \f478;
$fa-var-microchip-ai: \e1ec;
$fa-var-mug: \f874;
$fa-var-plane-up-slash: \e22e;
$fa-var-radiation: \f7b9;
$fa-var-pen-circle: \e20e;
$fa-var-chart-simple: \e473;
$fa-var-crutches: \f7f8;
$fa-var-circle-parking: \f615;
$fa-var-parking-circle: \f615;
$fa-var-mars-stroke: \f229;
$fa-var-leaf-oak: \f6f7;
$fa-var-square-bolt: \e265;
$fa-var-vial: \f492;
$fa-var-gauge: \f624;
$fa-var-dashboard: \f624;
$fa-var-gauge-med: \f624;
$fa-var-tachometer-alt-average: \f624;
$fa-var-wand-magic-sparkles: \e2ca;
$fa-var-magic-wand-sparkles: \e2ca;
$fa-var-lambda: \f66e;
$fa-var-e: \45;
$fa-var-pizza: \f817;
$fa-var-bowl-chopsticks-noodles: \e2ea;
$fa-var-h3: \f315;
$fa-var-pen-clip: \f305;
$fa-var-pen-alt: \f305;
$fa-var-bridge-circle-exclamation: \e4ca;
$fa-var-badge-percent: \f646;
$fa-var-user: \f007;
$fa-var-sensor: \e028;
$fa-var-comma: \2c;
$fa-var-school-circle-check: \e56b;
$fa-var-toilet-paper-under: \e2a0;
$fa-var-toilet-paper-reverse: \e2a0;
$fa-var-light-emergency: \e41f;
$fa-var-arrow-down-to-arc: \e4ae;
$fa-var-dumpster: \f793;
$fa-var-van-shuttle: \f5b6;
$fa-var-shuttle-van: \f5b6;
$fa-var-building-user: \e4da;
$fa-var-light-switch: \e017;
$fa-var-square-caret-left: \f191;
$fa-var-caret-square-left: \f191;
$fa-var-highlighter: \f591;
$fa-var-wave-pulse: \f5f8;
$fa-var-heart-rate: \f5f8;
$fa-var-key: \f084;
$fa-var-hat-santa: \f7a7;
$fa-var-tamale: \e451;
$fa-var-box-check: \f467;
$fa-var-bullhorn: \f0a1;
$fa-var-steak: \f824;
$fa-var-location-crosshairs-slash: \f603;
$fa-var-location-slash: \f603;
$fa-var-person-dolly: \f4d0;
$fa-var-globe: \f0ac;
$fa-var-synagogue: \f69b;
$fa-var-file-chart-column: \f659;
$fa-var-file-chart-line: \f659;
$fa-var-person-half-dress: \e548;
$fa-var-folder-image: \e18a;
$fa-var-calendar-pen: \f333;
$fa-var-calendar-edit: \f333;
$fa-var-road-bridge: \e563;
$fa-var-face-smile-tear: \e393;
$fa-var-message-plus: \f4a8;
$fa-var-comment-alt-plus: \f4a8;
$fa-var-location-arrow: \f124;
$fa-var-c: \43;
$fa-var-tablet-button: \f10a;
$fa-var-rectangle-history-circle-user: \e4a4;
$fa-var-building-lock: \e4d6;
$fa-var-chart-line-up: \e0e5;
$fa-var-mailbox: \f813;
$fa-var-truck-bolt: \e3d0;
$fa-var-pizza-slice: \f818;
$fa-var-money-bill-wave: \f53a;
$fa-var-chart-area: \f1fe;
$fa-var-area-chart: \f1fe;
$fa-var-house-flag: \e50d;
$fa-var-person-circle-minus: \e540;
$fa-var-scalpel: \f61d;
$fa-var-ban: \f05e;
$fa-var-cancel: \f05e;
$fa-var-bell-exclamation: \f848;
$fa-var-circle-bookmark: \e100;
$fa-var-bookmark-circle: \e100;
$fa-var-egg-fried: \f7fc;
$fa-var-face-weary: \e3a1;
$fa-var-uniform-martial-arts: \e3d1;
$fa-var-camera-rotate: \e0d8;
$fa-var-sun-dust: \f764;
$fa-var-comment-text: \e14d;
$fa-var-spray-can-sparkles: \f5d0;
$fa-var-air-freshener: \f5d0;
$fa-var-signal-bars: \f690;
$fa-var-signal-alt: \f690;
$fa-var-signal-alt-4: \f690;
$fa-var-signal-bars-strong: \f690;
$fa-var-diamond-exclamation: \e405;
$fa-var-star: \f005;
$fa-var-dial-min: \e161;
$fa-var-repeat: \f363;
$fa-var-cross: \f654;
$fa-var-page-caret-down: \e429;
$fa-var-file-caret-down: \e429;
$fa-var-box: \f466;
$fa-var-venus-mars: \f228;
$fa-var-clock-seven-thirty: \e351;
$fa-var-arrow-pointer: \f245;
$fa-var-mouse-pointer: \f245;
$fa-var-clock-four-thirty: \e34b;
$fa-var-signal-bars-good: \f693;
$fa-var-signal-alt-3: \f693;
$fa-var-cactus: \f8a7;
$fa-var-maximize: \f31e;
$fa-var-expand-arrows-alt: \f31e;
$fa-var-charging-station: \f5e7;
$fa-var-shapes: \f61f;
$fa-var-triangle-circle-square: \f61f;
$fa-var-plane-tail: \e22c;
$fa-var-gauge-simple-max: \f62b;
$fa-var-tachometer-fastest: \f62b;
$fa-var-circle-u: \e127;
$fa-var-shield-slash: \e24b;
$fa-var-square-phone-hangup: \e27a;
$fa-var-phone-square-down: \e27a;
$fa-var-arrow-up-left: \e09d;
$fa-var-transporter-1: \e043;
$fa-var-peanuts: \e431;
$fa-var-shuffle: \f074;
$fa-var-random: \f074;
$fa-var-person-running: \f70c;
$fa-var-running: \f70c;
$fa-var-mobile-retro: \e527;
$fa-var-grip-lines-vertical: \f7a5;
$fa-var-arrow-up-from-square: \e09c;
$fa-var-file-dashed-line: \f877;
$fa-var-page-break: \f877;
$fa-var-bracket-curly-right: \7d;
$fa-var-spider: \f717;
$fa-var-clock-three: \e356;
$fa-var-hands-bound: \e4f9;
$fa-var-scalpel-line-dashed: \f61e;
$fa-var-scalpel-path: \f61e;
$fa-var-file-invoice-dollar: \f571;
$fa-var-pipe-smoking: \e3c4;
$fa-var-face-astonished: \e36b;
$fa-var-window: \f40e;
$fa-var-plane-circle-exclamation: \e556;
$fa-var-ear: \f5f0;
$fa-var-file-lock: \e3a6;
$fa-var-diagram-venn: \e15a;
$fa-var-x-ray: \f497;
$fa-var-goal-net: \e3ab;
$fa-var-coffin-cross: \e051;
$fa-var-spell-check: \f891;
$fa-var-location-xmark: \f60e;
$fa-var-map-marker-times: \f60e;
$fa-var-map-marker-xmark: \f60e;
$fa-var-lasso: \f8c8;
$fa-var-slash: \f715;
$fa-var-person-to-portal: \e022;
$fa-var-portal-enter: \e022;
$fa-var-calendar-star: \f736;
$fa-var-computer-mouse: \f8cc;
$fa-var-mouse: \f8cc;
$fa-var-arrow-right-to-bracket: \f090;
$fa-var-sign-in: \f090;
$fa-var-pegasus: \f703;
$fa-var-files-medical: \f7fd;
$fa-var-nfc-lock: \e1f8;
$fa-var-person-ski-lift: \f7c8;
$fa-var-ski-lift: \f7c8;
$fa-var-square-6: \e25b;
$fa-var-shop-slash: \e070;
$fa-var-store-alt-slash: \e070;
$fa-var-wind-turbine: \f89b;
$fa-var-sliders-simple: \e253;
$fa-var-badge-sheriff: \f8a2;
$fa-var-server: \f233;
$fa-var-virus-covid-slash: \e4a9;
$fa-var-intersection: \f668;
$fa-var-shop-lock: \e4a5;
$fa-var-family: \e300;
$fa-var-hourglass-start: \f251;
$fa-var-hourglass-1: \f251;
$fa-var-user-hair-buns: \e3d3;
$fa-var-blender-phone: \f6b6;
$fa-var-hourglass-clock: \e41b;
$fa-var-person-seat-reclined: \e21f;
$fa-var-paper-plane-top: \e20a;
$fa-var-paper-plane-alt: \e20a;
$fa-var-send: \e20a;
$fa-var-message-arrow-up: \e1dc;
$fa-var-comment-alt-arrow-up: \e1dc;
$fa-var-lightbulb-exclamation: \f671;
$fa-var-layer-minus: \f5fe;
$fa-var-layer-group-minus: \f5fe;
$fa-var-circle-e: \e109;
$fa-var-building-wheat: \e4db;
$fa-var-gauge-max: \f626;
$fa-var-tachometer-alt-fastest: \f626;
$fa-var-person-breastfeeding: \e53a;
$fa-var-apostrophe: \27;
$fa-var-fire-hydrant: \e17f;
$fa-var-right-to-bracket: \f2f6;
$fa-var-sign-in-alt: \f2f6;
$fa-var-video-plus: \f4e1;
$fa-var-square-right: \f352;
$fa-var-arrow-alt-square-right: \f352;
$fa-var-comment-smile: \f4b4;
$fa-var-venus: \f221;
$fa-var-passport: \f5ab;
$fa-var-inbox-in: \f310;
$fa-var-inbox-arrow-down: \f310;
$fa-var-heart-pulse: \f21e;
$fa-var-heartbeat: \f21e;
$fa-var-circle-8: \e0f5;
$fa-var-clouds-moon: \f745;
$fa-var-clock-ten-thirty: \e355;
$fa-var-people-carry-box: \f4ce;
$fa-var-people-carry: \f4ce;
$fa-var-folder-user: \e18e;
$fa-var-trash-can-xmark: \e2ae;
$fa-var-temperature-high: \f769;
$fa-var-microchip: \f2db;
$fa-var-left-long-to-line: \e41e;
$fa-var-crown: \f521;
$fa-var-weight-hanging: \f5cd;
$fa-var-xmarks-lines: \e59a;
$fa-var-file-prescription: \f572;
$fa-var-calendar-range: \e0d6;
$fa-var-flower-daffodil: \f800;
$fa-var-hand-back-point-up: \e1a2;
$fa-var-weight-scale: \f496;
$fa-var-weight: \f496;
$fa-var-star-exclamation: \f2f3;
$fa-var-books: \f5db;
$fa-var-user-group: \f500;
$fa-var-user-friends: \f500;
$fa-var-arrow-up-a-z: \f15e;
$fa-var-sort-alpha-up: \f15e;
$fa-var-layer-plus: \f5ff;
$fa-var-layer-group-plus: \f5ff;
$fa-var-play-pause: \e22f;
$fa-var-block-question: \e3dd;
$fa-var-snooze: \f880;
$fa-var-zzz: \f880;
$fa-var-scanner-image: \f8f3;
$fa-var-tv-retro: \f401;
$fa-var-square-t: \e280;
$fa-var-farm: \f864;
$fa-var-barn-silo: \f864;
$fa-var-chess-knight: \f441;
$fa-var-bars-sort: \e0ae;
$fa-var-pallet-boxes: \f483;
$fa-var-palette-boxes: \f483;
$fa-var-pallet-alt: \f483;
$fa-var-face-laugh-squint: \f59b;
$fa-var-laugh-squint: \f59b;
$fa-var-code-simple: \e13d;
$fa-var-bolt-slash: \e0b8;
$fa-var-panel-fire: \e42f;
$fa-var-binary-circle-check: \e33c;
$fa-var-comment-minus: \f4b1;
$fa-var-burrito: \f7ed;
$fa-var-violin: \f8ed;
$fa-var-objects-column: \e3c1;
$fa-var-square-chevron-down: \f329;
$fa-var-chevron-square-down: \f329;
$fa-var-comment-plus: \f4b2;
$fa-var-triangle-instrument: \f8e2;
$fa-var-triangle-music: \f8e2;
$fa-var-wheelchair: \f193;
$fa-var-user-pilot-tie: \e2c1;
$fa-var-piano-keyboard: \f8d5;
$fa-var-bed-empty: \f8f9;
$fa-var-circle-arrow-up: \f0aa;
$fa-var-arrow-circle-up: \f0aa;
$fa-var-toggle-on: \f205;
$fa-var-rectangle-vertical: \f2fb;
$fa-var-rectangle-portrait: \f2fb;
$fa-var-person-walking: \f554;
$fa-var-walking: \f554;
$fa-var-l: \4c;
$fa-var-signal-stream: \f8dd;
$fa-var-down-to-bracket: \e4e7;
$fa-var-circle-z: \e130;
$fa-var-stars: \f762;
$fa-var-fire: \f06d;
$fa-var-bed-pulse: \f487;
$fa-var-procedures: \f487;
$fa-var-house-day: \e00e;
$fa-var-shuttle-space: \f197;
$fa-var-space-shuttle: \f197;
$fa-var-shirt-long-sleeve: \e3c7;
$fa-var-chart-pie-simple: \f64e;
$fa-var-chart-pie-alt: \f64e;
$fa-var-face-laugh: \f599;
$fa-var-laugh: \f599;
$fa-var-folder-open: \f07c;
$fa-var-album-collection-circle-user: \e48f;
$fa-var-candy: \e3e7;
$fa-var-bowl-hot: \f823;
$fa-var-soup: \f823;
$fa-var-flatbread: \e40b;
$fa-var-heart-circle-plus: \e500;
$fa-var-code-fork: \e13b;
$fa-var-city: \f64f;
$fa-var-signal-bars-weak: \f691;
$fa-var-signal-alt-1: \f691;
$fa-var-microphone-lines: \f3c9;
$fa-var-microphone-alt: \f3c9;
$fa-var-clock-twelve: \e358;
$fa-var-pepper-hot: \f816;
$fa-var-citrus-slice: \e2f5;
$fa-var-sheep: \f711;
$fa-var-unlock: \f09c;
$fa-var-colon-sign: \e140;
$fa-var-headset: \f590;
$fa-var-badger-honey: \f6b4;
$fa-var-h4: \f86a;
$fa-var-store-slash: \e071;
$fa-var-road-circle-xmark: \e566;
$fa-var-signal-slash: \f695;
$fa-var-user-minus: \f503;
$fa-var-mars-stroke-up: \f22a;
$fa-var-mars-stroke-v: \f22a;
$fa-var-champagne-glasses: \f79f;
$fa-var-glass-cheers: \f79f;
$fa-var-taco: \f826;
$fa-var-hexagon-plus: \f300;
$fa-var-plus-hexagon: \f300;
$fa-var-clipboard: \f328;
$fa-var-house-circle-exclamation: \e50a;
$fa-var-file-arrow-up: \f574;
$fa-var-file-upload: \f574;
$fa-var-wifi: \f1eb;
$fa-var-wifi-3: \f1eb;
$fa-var-wifi-strong: \f1eb;
$fa-var-messages: \f4b6;
$fa-var-comments-alt: \f4b6;
$fa-var-bath: \f2cd;
$fa-var-bathtub: \f2cd;
$fa-var-umbrella-simple: \e2bc;
$fa-var-umbrella-alt: \e2bc;
$fa-var-rectangle-history-circle-plus: \e4a3;
$fa-var-underline: \f0cd;
$fa-var-prescription-bottle-pill: \e5c0;
$fa-var-user-pen: \f4ff;
$fa-var-user-edit: \f4ff;
$fa-var-binary-slash: \e33e;
$fa-var-square-o: \e278;
$fa-var-signature: \f5b7;
$fa-var-stroopwafel: \f551;
$fa-var-bold: \f032;
$fa-var-anchor-lock: \e4ad;
$fa-var-building-ngo: \e4d7;
$fa-var-transporter-3: \e045;
$fa-var-engine-warning: \f5f2;
$fa-var-engine-exclamation: \f5f2;
$fa-var-circle-down-right: \e108;
$fa-var-square-k: \e274;
$fa-var-manat-sign: \e1d5;
$fa-var-money-check-pen: \f872;
$fa-var-money-check-edit: \f872;
$fa-var-not-equal: \f53e;
$fa-var-border-top-left: \f853;
$fa-var-border-style: \f853;
$fa-var-map-location-dot: \f5a0;
$fa-var-map-marked-alt: \f5a0;
$fa-var-tilde: \7e;
$fa-var-jedi: \f669;
$fa-var-square-poll-vertical: \f681;
$fa-var-poll: \f681;
$fa-var-arrow-down-square-triangle: \f889;
$fa-var-sort-shapes-down-alt: \f889;
$fa-var-mug-hot: \f7b6;
$fa-var-dog-leashed: \f6d4;
$fa-var-car-battery: \f5df;
$fa-var-battery-car: \f5df;
$fa-var-face-downcast-sweat: \e371;
$fa-var-mailbox-flag-up: \e5bb;
$fa-var-memo-circle-info: \e49a;
$fa-var-gift: \f06b;
$fa-var-dice-two: \f528;
$fa-var-volume: \f6a8;
$fa-var-volume-medium: \f6a8;
$fa-var-transporter-5: \e2a6;
$fa-var-gauge-circle-bolt: \e496;
$fa-var-coin-front: \e3fc;
$fa-var-file-slash: \e3a7;
$fa-var-message-arrow-up-right: \e1dd;
$fa-var-treasure-chest: \f723;
$fa-var-chess-queen: \f445;
$fa-var-paintbrush-fine: \f5a9;
$fa-var-paint-brush-alt: \f5a9;
$fa-var-paint-brush-fine: \f5a9;
$fa-var-paintbrush-alt: \f5a9;
$fa-var-glasses: \f530;
$fa-var-hood-cloak: \f6ef;
$fa-var-square-quote: \e329;
$fa-var-up-left: \e2bd;
$fa-var-bring-front: \f857;
$fa-var-chess-board: \f43c;
$fa-var-burger-cheese: \f7f1;
$fa-var-cheeseburger: \f7f1;
$fa-var-building-circle-check: \e4d2;
$fa-var-repeat-1: \f365;
$fa-var-arrow-down-to-line: \f33d;
$fa-var-arrow-to-bottom: \f33d;
$fa-var-grid-5: \e199;
$fa-var-right-long-to-line: \e444;
$fa-var-person-chalkboard: \e53d;
$fa-var-mars-stroke-right: \f22b;
$fa-var-mars-stroke-h: \f22b;
$fa-var-hand-back-fist: \f255;
$fa-var-hand-rock: \f255;
$fa-var-tally: \f69c;
$fa-var-tally-5: \f69c;
$fa-var-square-caret-up: \f151;
$fa-var-caret-square-up: \f151;
$fa-var-cloud-showers-water: \e4e4;
$fa-var-chart-bar: \f080;
$fa-var-bar-chart: \f080;
$fa-var-hands-bubbles: \e05e;
$fa-var-hands-wash: \e05e;
$fa-var-less-than-equal: \f537;
$fa-var-train: \f238;
$fa-var-up-from-dotted-line: \e456;
$fa-var-eye-low-vision: \f2a8;
$fa-var-low-vision: \f2a8;
$fa-var-traffic-light-go: \f638;
$fa-var-face-exhaling: \e480;
$fa-var-sensor-fire: \e02a;
$fa-var-user-unlock: \e058;
$fa-var-hexagon-divide: \e1ad;
$fa-var-00: \e467;
$fa-var-crow: \f520;
$fa-var-cassette-betamax: \f8a4;
$fa-var-betamax: \f8a4;
$fa-var-sailboat: \e445;
$fa-var-window-restore: \f2d2;
$fa-var-nfc-magnifying-glass: \e1f9;
$fa-var-file-binary: \e175;
$fa-var-circle-v: \e12a;
$fa-var-square-plus: \f0fe;
$fa-var-plus-square: \f0fe;
$fa-var-bowl-scoops: \e3df;
$fa-var-mistletoe: \f7b4;
$fa-var-custard: \e403;
$fa-var-lacrosse-stick: \e3b5;
$fa-var-hockey-mask: \f6ee;
$fa-var-sunrise: \f766;
$fa-var-panel-ews: \e42e;
$fa-var-torii-gate: \f6a1;
$fa-var-cloud-exclamation: \e491;
$fa-var-message-lines: \f4a6;
$fa-var-comment-alt-lines: \f4a6;
$fa-var-frog: \f52e;
$fa-var-bucket: \e4cf;
$fa-var-floppy-disk-pen: \e182;
$fa-var-image: \f03e;
$fa-var-window-frame: \e04f;
$fa-var-microphone: \f130;
$fa-var-cow: \f6c8;
$fa-var-square-ring: \e44f;
$fa-var-down-from-line: \f349;
$fa-var-arrow-alt-from-top: \f349;
$fa-var-caret-up: \f0d8;
$fa-var-shield-xmark: \e24c;
$fa-var-shield-times: \e24c;
$fa-var-screwdriver: \f54a;
$fa-var-circle-sort-down: \e031;
$fa-var-sort-circle-down: \e031;
$fa-var-folder-closed: \e185;
$fa-var-house-tsunami: \e515;
$fa-var-square-nfi: \e576;
$fa-var-forklift: \f47a;
$fa-var-arrow-up-from-ground-water: \e4b5;
$fa-var-bracket-square-right: \5d;
$fa-var-martini-glass: \f57b;
$fa-var-glass-martini-alt: \f57b;
$fa-var-rotate-left: \f2ea;
$fa-var-rotate-back: \f2ea;
$fa-var-rotate-backward: \f2ea;
$fa-var-undo-alt: \f2ea;
$fa-var-table-columns: \f0db;
$fa-var-columns: \f0db;
$fa-var-square-a: \e25f;
$fa-var-tick: \e32f;
$fa-var-lemon: \f094;
$fa-var-head-side-mask: \e063;
$fa-var-handshake: \f2b5;
$fa-var-gem: \f3a5;
$fa-var-dolly: \f472;
$fa-var-dolly-box: \f472;
$fa-var-smoking: \f48d;
$fa-var-minimize: \f78c;
$fa-var-compress-arrows-alt: \f78c;
$fa-var-refrigerator: \e026;
$fa-var-monument: \f5a6;
$fa-var-octagon-xmark: \f2f0;
$fa-var-times-octagon: \f2f0;
$fa-var-xmark-octagon: \f2f0;
$fa-var-align-slash: \f846;
$fa-var-snowplow: \f7d2;
$fa-var-angles-right: \f101;
$fa-var-angle-double-right: \f101;
$fa-var-truck-ramp-couch: \f4dd;
$fa-var-truck-couch: \f4dd;
$fa-var-cannabis: \f55f;
$fa-var-circle-play: \f144;
$fa-var-play-circle: \f144;
$fa-var-arrow-up-right-and-arrow-down-left-from-center: \e0a0;
$fa-var-tablets: \f490;
$fa-var-360-degrees: \e2dc;
$fa-var-ethernet: \f796;
$fa-var-euro-sign: \f153;
$fa-var-eur: \f153;
$fa-var-euro: \f153;
$fa-var-chair: \f6c0;
$fa-var-circle-check: \f058;
$fa-var-check-circle: \f058;
$fa-var-square-dashed-circle-plus: \e5c2;
$fa-var-money-simple-from-bracket: \e313;
$fa-var-bat: \f6b5;
$fa-var-circle-stop: \f28d;
$fa-var-stop-circle: \f28d;
$fa-var-head-side-headphones: \f8c2;
$fa-var-phone-rotary: \f8d3;
$fa-var-compass-drafting: \f568;
$fa-var-drafting-compass: \f568;
$fa-var-plate-wheat: \e55a;
$fa-var-calendar-circle-minus: \e46f;
$fa-var-chopsticks: \e3f7;
$fa-var-car-wrench: \f5e3;
$fa-var-car-mechanic: \f5e3;
$fa-var-icicles: \f7ad;
$fa-var-person-shelter: \e54f;
$fa-var-neuter: \f22c;
$fa-var-id-badge: \f2c1;
$fa-var-kazoo: \f8c7;
$fa-var-marker: \f5a1;
$fa-var-face-laugh-beam: \f59a;
$fa-var-laugh-beam: \f59a;
$fa-var-square-arrow-down-left: \e261;
$fa-var-battery-bolt: \f376;
$fa-var-tree-large: \f7dd;
$fa-var-helicopter-symbol: \e502;
$fa-var-aperture: \e2df;
$fa-var-universal-access: \f29a;
$fa-var-file-magnifying-glass: \f865;
$fa-var-file-search: \f865;
$fa-var-up-right: \e2be;
$fa-var-circle-chevron-up: \f139;
$fa-var-chevron-circle-up: \f139;
$fa-var-user-police: \e333;
$fa-var-lari-sign: \e1c8;
$fa-var-volcano: \f770;
$fa-var-teddy-bear: \e3cf;
$fa-var-stocking: \f7d5;
$fa-var-person-walking-dashed-line-arrow-right: \e553;
$fa-var-image-slash: \e1b7;
$fa-var-mask-snorkel: \e3b7;
$fa-var-smoke: \f760;
$fa-var-sterling-sign: \f154;
$fa-var-gbp: \f154;
$fa-var-pound-sign: \f154;
$fa-var-battery-exclamation: \e0b0;
$fa-var-viruses: \e076;
$fa-var-square-person-confined: \e577;
$fa-var-user-tie: \f508;
$fa-var-arrow-down-long: \f175;
$fa-var-long-arrow-down: \f175;
$fa-var-tent-arrow-down-to-line: \e57e;
$fa-var-certificate: \f0a3;
$fa-var-crystal-ball: \e362;
$fa-var-reply-all: \f122;
$fa-var-mail-reply-all: \f122;
$fa-var-suitcase: \f0f2;
$fa-var-person-skating: \f7c5;
$fa-var-skating: \f7c5;
$fa-var-star-shooting: \e036;
$fa-var-binary-lock: \e33d;
$fa-var-filter-circle-dollar: \f662;
$fa-var-funnel-dollar: \f662;
$fa-var-camera-retro: \f083;
$fa-var-circle-arrow-down: \f0ab;
$fa-var-arrow-circle-down: \f0ab;
$fa-var-comment-pen: \f4ae;
$fa-var-comment-edit: \f4ae;
$fa-var-file-import: \f56f;
$fa-var-arrow-right-to-file: \f56f;
$fa-var-banjo: \f8a3;
$fa-var-square-arrow-up-right: \f14c;
$fa-var-external-link-square: \f14c;
$fa-var-light-emergency-on: \e420;
$fa-var-kerning: \f86f;
$fa-var-box-open: \f49e;
$fa-var-square-f: \e270;
$fa-var-scroll: \f70e;
$fa-var-spa: \f5bb;
$fa-var-arrow-left-from-line: \f344;
$fa-var-arrow-from-right: \f344;
$fa-var-strawberry: \e32b;
$fa-var-location-pin-lock: \e51f;
$fa-var-pause: \f04c;
$fa-var-clock-eight-thirty: \e346;
$fa-var-plane-engines: \f3de;
$fa-var-plane-alt: \f3de;
$fa-var-hill-avalanche: \e507;
$fa-var-temperature-empty: \f2cb;
$fa-var-temperature-0: \f2cb;
$fa-var-thermometer-0: \f2cb;
$fa-var-thermometer-empty: \f2cb;
$fa-var-bomb: \f1e2;
$fa-var-gauge-low: \f627;
$fa-var-tachometer-alt-slow: \f627;
$fa-var-registered: \f25d;
$fa-var-trash-can-plus: \e2ac;
$fa-var-address-card: \f2bb;
$fa-var-contact-card: \f2bb;
$fa-var-vcard: \f2bb;
$fa-var-scale-unbalanced-flip: \f516;
$fa-var-balance-scale-right: \f516;
$fa-var-globe-snow: \f7a3;
$fa-var-subscript: \f12c;
$fa-var-diamond-turn-right: \f5eb;
$fa-var-directions: \f5eb;
$fa-var-integral: \f667;
$fa-var-burst: \e4dc;
$fa-var-house-laptop: \e066;
$fa-var-laptop-house: \e066;
$fa-var-face-tired: \f5c8;
$fa-var-tired: \f5c8;
$fa-var-money-bills: \e1f3;
$fa-var-blinds-raised: \f8fd;
$fa-var-smog: \f75f;
$fa-var-ufo-beam: \e048;
$fa-var-circle-caret-up: \f331;
$fa-var-caret-circle-up: \f331;
$fa-var-user-vneck-hair-long: \e463;
$fa-var-square-a-lock: \e44d;
$fa-var-crutch: \f7f7;
$fa-var-gas-pump-slash: \f5f4;
$fa-var-cloud-arrow-up: \f0ee;
$fa-var-cloud-upload: \f0ee;
$fa-var-cloud-upload-alt: \f0ee;
$fa-var-palette: \f53f;
$fa-var-transporter-4: \e2a5;
$fa-var-objects-align-right: \e3bf;
$fa-var-arrows-turn-right: \e4c0;
$fa-var-vest: \e085;
$fa-var-pig: \f706;
$fa-var-inbox-full: \e1ba;
$fa-var-circle-envelope: \e10c;
$fa-var-envelope-circle: \e10c;
$fa-var-triangle-person-digging: \f85d;
$fa-var-construction: \f85d;
$fa-var-ferry: \e4ea;
$fa-var-bullseye-arrow: \f648;
$fa-var-arrows-down-to-people: \e4b9;
$fa-var-seedling: \f4d8;
$fa-var-sprout: \f4d8;
$fa-var-clock-seven: \e350;
$fa-var-left-right: \f337;
$fa-var-arrows-alt-h: \f337;
$fa-var-boxes-packing: \e4c7;
$fa-var-circle-arrow-left: \f0a8;
$fa-var-arrow-circle-left: \f0a8;
$fa-var-flashlight: \f8b8;
$fa-var-group-arrows-rotate: \e4f6;
$fa-var-bowl-food: \e4c6;
$fa-var-square-9: \e25e;
$fa-var-candy-cane: \f786;
$fa-var-arrow-down-wide-short: \f160;
$fa-var-sort-amount-asc: \f160;
$fa-var-sort-amount-down: \f160;
$fa-var-square-dollar: \f2e9;
$fa-var-dollar-square: \f2e9;
$fa-var-usd-square: \f2e9;
$fa-var-phone-arrow-right: \e5be;
$fa-var-hand-holding-seedling: \f4bf;
$fa-var-message-check: \f4a2;
$fa-var-comment-alt-check: \f4a2;
$fa-var-cloud-bolt: \f76c;
$fa-var-thunderstorm: \f76c;
$fa-var-text-slash: \f87d;
$fa-var-remove-format: \f87d;
$fa-var-watch: \f2e1;
$fa-var-circle-down-left: \e107;
$fa-var-text: \f893;
$fa-var-projector: \f8d6;
$fa-var-face-smile-wink: \f4da;
$fa-var-smile-wink: \f4da;
$fa-var-tombstone-blank: \f721;
$fa-var-tombstone-alt: \f721;
$fa-var-chess-king-piece: \f440;
$fa-var-chess-king-alt: \f440;
$fa-var-circle-6: \e0f3;
$fa-var-left: \f355;
$fa-var-arrow-alt-left: \f355;
$fa-var-file-word: \f1c2;
$fa-var-file-powerpoint: \f1c4;
$fa-var-square-down: \f350;
$fa-var-arrow-alt-square-down: \f350;
$fa-var-objects-align-center-vertical: \e3bd;
$fa-var-arrows-left-right: \f07e;
$fa-var-arrows-h: \f07e;
$fa-var-house-lock: \e510;
$fa-var-cloud-arrow-down: \f0ed;
$fa-var-cloud-download: \f0ed;
$fa-var-cloud-download-alt: \f0ed;
$fa-var-wreath: \f7e2;
$fa-var-children: \e4e1;
$fa-var-meter-droplet: \e1ea;
$fa-var-chalkboard: \f51b;
$fa-var-blackboard: \f51b;
$fa-var-user-large-slash: \f4fa;
$fa-var-user-alt-slash: \f4fa;
$fa-var-signal-strong: \f68f;
$fa-var-signal-4: \f68f;
$fa-var-lollipop: \e424;
$fa-var-lollypop: \e424;
$fa-var-list-tree: \e1d2;
$fa-var-envelope-open: \f2b6;
$fa-var-draw-circle: \f5ed;
$fa-var-cat-space: \e001;
$fa-var-handshake-simple-slash: \e05f;
$fa-var-handshake-alt-slash: \e05f;
$fa-var-rabbit-running: \f709;
$fa-var-rabbit-fast: \f709;
$fa-var-memo-pad: \e1da;
$fa-var-mattress-pillow: \e525;
$fa-var-alarm-plus: \f844;
$fa-var-alicorn: \f6b0;
$fa-var-comment-question: \e14b;
$fa-var-gingerbread-man: \f79d;
$fa-var-guarani-sign: \e19a;
$fa-var-burger-fries: \e0cd;
$fa-var-mug-tea: \f875;
$fa-var-border-top: \f855;
$fa-var-arrows-rotate: \f021;
$fa-var-refresh: \f021;
$fa-var-sync: \f021;
$fa-var-circle-book-open: \e0ff;
$fa-var-book-circle: \e0ff;
$fa-var-arrows-to-dotted-line: \e0a6;
$fa-var-fire-extinguisher: \f134;
$fa-var-garage-open: \e00b;
$fa-var-shelves-empty: \e246;
$fa-var-cruzeiro-sign: \e152;
$fa-var-watch-apple: \e2cb;
$fa-var-watch-calculator: \f8f0;
$fa-var-list-dropdown: \e1cf;
$fa-var-cabinet-filing: \f64b;
$fa-var-burger-soda: \f858;
$fa-var-square-arrow-up: \f33c;
$fa-var-arrow-square-up: \f33c;
$fa-var-greater-than-equal: \f532;
$fa-var-pallet-box: \e208;
$fa-var-face-confounded: \e36c;
$fa-var-shield-halved: \f3ed;
$fa-var-shield-alt: \f3ed;
$fa-var-truck-plow: \f7de;
$fa-var-book-atlas: \f558;
$fa-var-atlas: \f558;
$fa-var-virus: \e074;
$fa-var-comment-middle-top: \e14a;
$fa-var-envelope-circle-check: \e4e8;
$fa-var-layer-group: \f5fd;
$fa-var-restroom-simple: \e23a;
$fa-var-arrows-to-dot: \e4be;
$fa-var-border-outer: \f851;
$fa-var-hashtag-lock: \e415;
$fa-var-clock-two-thirty: \e35b;
$fa-var-archway: \f557;
$fa-var-heart-circle-check: \e4fd;
$fa-var-house-chimney-crack: \f6f1;
$fa-var-house-damage: \f6f1;
$fa-var-file-zipper: \f1c6;
$fa-var-file-archive: \f1c6;
$fa-var-heart-half: \e1ab;
$fa-var-comment-check: \f4ac;
$fa-var-square: \f0c8;
$fa-var-memo: \e1d8;
$fa-var-martini-glass-empty: \f000;
$fa-var-glass-martini: \f000;
$fa-var-couch: \f4b8;
$fa-var-cedi-sign: \e0df;
$fa-var-italic: \f033;
$fa-var-glass-citrus: \f869;
$fa-var-calendar-lines-pen: \e472;
$fa-var-church: \f51d;
$fa-var-person-snowmobiling: \f7d1;
$fa-var-snowmobile: \f7d1;
$fa-var-face-hushed: \e37b;
$fa-var-comments-dollar: \f653;
$fa-var-pickaxe: \e5bf;
$fa-var-link-simple-slash: \e1ce;
$fa-var-democrat: \f747;
$fa-var-face-confused: \e36d;
$fa-var-pinball: \e229;
$fa-var-z: \5a;
$fa-var-person-skiing: \f7c9;
$fa-var-skiing: \f7c9;
$fa-var-deer: \f78e;
$fa-var-input-pipe: \e1be;
$fa-var-road-lock: \e567;
$fa-var-a: \41;
$fa-var-bookmark-slash: \e0c2;
$fa-var-temperature-arrow-down: \e03f;
$fa-var-temperature-down: \e03f;
$fa-var-mace: \f6f8;
$fa-var-feather-pointed: \f56b;
$fa-var-feather-alt: \f56b;
$fa-var-sausage: \f820;
$fa-var-trash-can-clock: \e2aa;
$fa-var-p: \50;
$fa-var-snowflake: \f2dc;
$fa-var-stomach: \f623;
$fa-var-newspaper: \f1ea;
$fa-var-rectangle-ad: \f641;
$fa-var-ad: \f641;
$fa-var-guitar-electric: \f8be;
$fa-var-arrow-turn-down-right: \e3d6;
$fa-var-moon-cloud: \f754;
$fa-var-bread-slice-butter: \e3e1;
$fa-var-circle-arrow-right: \f0a9;
$fa-var-arrow-circle-right: \f0a9;
$fa-var-user-group-crown: \f6a5;
$fa-var-users-crown: \f6a5;
$fa-var-circle-i: \e111;
$fa-var-toilet-paper-check: \e5b2;
$fa-var-filter-circle-xmark: \e17b;
$fa-var-locust: \e520;
$fa-var-sort: \f0dc;
$fa-var-unsorted: \f0dc;
$fa-var-list-ol: \f0cb;
$fa-var-list-1-2: \f0cb;
$fa-var-list-numeric: \f0cb;
$fa-var-chart-waterfall: \e0eb;
$fa-var-face-party: \e383;
$fa-var-kidneys: \f5fb;
$fa-var-wifi-exclamation: \e2cf;
$fa-var-chart-network: \f78a;
$fa-var-person-dress-burst: \e544;
$fa-var-dice-d4: \f6d0;
$fa-var-money-check-dollar: \f53d;
$fa-var-money-check-alt: \f53d;
$fa-var-vector-square: \f5cb;
$fa-var-bread-slice: \f7ec;
$fa-var-language: \f1ab;
$fa-var-wheat-awn-slash: \e338;
$fa-var-face-kiss-wink-heart: \f598;
$fa-var-kiss-wink-heart: \f598;
$fa-var-dagger: \f6cb;
$fa-var-podium: \f680;
$fa-var-memo-circle-check: \e1d9;
$fa-var-route-highway: \f61a;
$fa-var-down-to-line: \f34a;
$fa-var-arrow-alt-to-bottom: \f34a;
$fa-var-filter: \f0b0;
$fa-var-square-g: \e271;
$fa-var-circle-phone: \e11b;
$fa-var-phone-circle: \e11b;
$fa-var-clipboard-prescription: \f5e8;
$fa-var-user-nurse-hair: \e45d;
$fa-var-question: \3f;
$fa-var-file-signature: \f573;
$fa-var-toggle-large-on: \e5b1;
$fa-var-up-down-left-right: \f0b2;
$fa-var-arrows-alt: \f0b2;
$fa-var-dryer-heat: \f862;
$fa-var-dryer-alt: \f862;
$fa-var-house-chimney-user: \e065;
$fa-var-hand-holding-heart: \f4be;
$fa-var-arrow-up-small-big: \f88f;
$fa-var-sort-size-up-alt: \f88f;
$fa-var-train-track: \e453;
$fa-var-puzzle-piece: \f12e;
$fa-var-money-check: \f53c;
$fa-var-star-half-stroke: \f5c0;
$fa-var-star-half-alt: \f5c0;
$fa-var-file-exclamation: \f31a;
$fa-var-code: \f121;
$fa-var-whiskey-glass: \f7a0;
$fa-var-glass-whiskey: \f7a0;
$fa-var-moon-stars: \f755;
$fa-var-building-circle-exclamation: \e4d3;
$fa-var-clothes-hanger: \e136;
$fa-var-mobile-notch: \e1ee;
$fa-var-mobile-iphone: \e1ee;
$fa-var-magnifying-glass-chart: \e522;
$fa-var-arrow-up-right-from-square: \f08e;
$fa-var-external-link: \f08e;
$fa-var-cubes-stacked: \e4e6;
$fa-var-images-user: \e1b9;
$fa-var-won-sign: \f159;
$fa-var-krw: \f159;
$fa-var-won: \f159;
$fa-var-image-polaroid-user: \e1b6;
$fa-var-virus-covid: \e4a8;
$fa-var-square-ellipsis: \e26e;
$fa-var-pie: \f705;
$fa-var-chess-knight-piece: \f442;
$fa-var-chess-knight-alt: \f442;
$fa-var-austral-sign: \e0a9;
$fa-var-cloud-plus: \e35e;
$fa-var-f: \46;
$fa-var-leaf: \f06c;
$fa-var-bed-bunk: \f8f8;
$fa-var-road: \f018;
$fa-var-taxi: \f1ba;
$fa-var-cab: \f1ba;
$fa-var-person-circle-plus: \e541;
$fa-var-chart-pie: \f200;
$fa-var-pie-chart: \f200;
$fa-var-bolt-lightning: \e0b7;
$fa-var-clock-eight: \e345;
$fa-var-sack-xmark: \e56a;
$fa-var-file-excel: \f1c3;
$fa-var-file-contract: \f56c;
$fa-var-fish-fins: \e4f2;
$fa-var-circle-q: \e11e;
$fa-var-building-flag: \e4d5;
$fa-var-face-grin-beam: \f582;
$fa-var-grin-beam: \f582;
$fa-var-object-ungroup: \f248;
$fa-var-face-disguise: \e370;
$fa-var-circle-arrow-down-right: \e0fa;
$fa-var-alien-8bit: \f8f6;
$fa-var-alien-monster: \f8f6;
$fa-var-hand-point-ribbon: \e1a6;
$fa-var-poop: \f619;
$fa-var-object-exclude: \e49c;
$fa-var-telescope: \e03e;
$fa-var-location-pin: \f041;
$fa-var-map-marker: \f041;
$fa-var-square-list: \e489;
$fa-var-kaaba: \f66b;
$fa-var-toilet-paper: \f71e;
$fa-var-helmet-safety: \f807;
$fa-var-hard-hat: \f807;
$fa-var-hat-hard: \f807;
$fa-var-comment-code: \e147;
$fa-var-sim-cards: \e251;
$fa-var-starship: \e039;
$fa-var-eject: \f052;
$fa-var-circle-right: \f35a;
$fa-var-arrow-alt-circle-right: \f35a;
$fa-var-plane-circle-check: \e555;
$fa-var-seal: \e241;
$fa-var-user-cowboy: \f8ea;
$fa-var-hexagon-vertical-nft: \e505;
$fa-var-face-rolling-eyes: \f5a5;
$fa-var-meh-rolling-eyes: \f5a5;
$fa-var-bread-loaf: \f7eb;
$fa-var-rings-wedding: \f81b;
$fa-var-object-group: \f247;
$fa-var-french-fries: \f803;
$fa-var-chart-line: \f201;
$fa-var-line-chart: \f201;
$fa-var-calendar-arrow-down: \e0d0;
$fa-var-calendar-download: \e0d0;
$fa-var-send-back: \f87e;
$fa-var-mask-ventilator: \e524;
$fa-var-signature-lock: \e3ca;
$fa-var-arrow-right: \f061;
$fa-var-signs-post: \f277;
$fa-var-map-signs: \f277;
$fa-var-octagon-plus: \f301;
$fa-var-plus-octagon: \f301;
$fa-var-cash-register: \f788;
$fa-var-person-circle-question: \e542;
$fa-var-melon-slice: \e311;
$fa-var-space-station-moon: \e033;
$fa-var-message-smile: \f4aa;
$fa-var-comment-alt-smile: \f4aa;
$fa-var-cup-straw: \e363;
$fa-var-left-from-line: \f348;
$fa-var-arrow-alt-from-right: \f348;
$fa-var-h: \48;
$fa-var-basket-shopping-simple: \e0af;
$fa-var-shopping-basket-alt: \e0af;
$fa-var-hands-holding-heart: \f4c3;
$fa-var-hands-heart: \f4c3;
$fa-var-clock-nine: \e34c;
$fa-var-tarp: \e57b;
$fa-var-face-sleepy: \e38e;
$fa-var-hand-horns: \e1a9;
$fa-var-screwdriver-wrench: \f7d9;
$fa-var-tools: \f7d9;
$fa-var-arrows-to-eye: \e4bf;
$fa-var-circle-three-quarters: \e125;
$fa-var-trophy-star: \f2eb;
$fa-var-trophy-alt: \f2eb;
$fa-var-plug-circle-bolt: \e55b;
$fa-var-face-thermometer: \e39a;
$fa-var-shirt-running: \e3c8;
$fa-var-book-circle-arrow-up: \e0bd;
$fa-var-face-nauseated: \e381;
$fa-var-heart: \f004;
$fa-var-file-chart-pie: \f65a;
$fa-var-mars-and-venus: \f224;
$fa-var-house-user: \e1b0;
$fa-var-home-user: \e1b0;
$fa-var-circle-arrow-down-left: \e0f9;
$fa-var-dumpster-fire: \f794;
$fa-var-hexagon-minus: \f307;
$fa-var-minus-hexagon: \f307;
$fa-var-left-to-line: \f34b;
$fa-var-arrow-alt-to-left: \f34b;
$fa-var-house-crack: \e3b1;
$fa-var-paw-simple: \f701;
$fa-var-paw-alt: \f701;
$fa-var-arrow-left-long-to-line: \e3d4;
$fa-var-brackets-round: \e0c5;
$fa-var-parentheses: \e0c5;
$fa-var-martini-glass-citrus: \f561;
$fa-var-cocktail: \f561;
$fa-var-user-shakespeare: \e2c2;
$fa-var-arrow-right-to-arc: \e4b2;
$fa-var-face-surprise: \f5c2;
$fa-var-surprise: \f5c2;
$fa-var-bottle-water: \e4c5;
$fa-var-circle-pause: \f28b;
$fa-var-pause-circle: \f28b;
$fa-var-gauge-circle-plus: \e498;
$fa-var-folders: \f660;
$fa-var-angel: \f779;
$fa-var-value-absolute: \f6a6;
$fa-var-rabbit: \f708;
$fa-var-toilet-paper-slash: \e072;
$fa-var-apple-whole: \f5d1;
$fa-var-apple-alt: \f5d1;
$fa-var-kitchen-set: \e51a;
$fa-var-diamond-half: \e5b7;
$fa-var-lock-keyhole: \f30d;
$fa-var-lock-alt: \f30d;
$fa-var-r: \52;
$fa-var-temperature-quarter: \f2ca;
$fa-var-temperature-1: \f2ca;
$fa-var-thermometer-1: \f2ca;
$fa-var-thermometer-quarter: \f2ca;
$fa-var-square-info: \f30f;
$fa-var-info-square: \f30f;
$fa-var-wifi-slash: \f6ac;
$fa-var-toilet-paper-xmark: \e5b3;
$fa-var-hands-holding-dollar: \f4c5;
$fa-var-hands-usd: \f4c5;
$fa-var-cube: \f1b2;
$fa-var-arrow-down-triangle-square: \f888;
$fa-var-sort-shapes-down: \f888;
$fa-var-bitcoin-sign: \e0b4;
$fa-var-shutters: \e449;
$fa-var-shield-dog: \e573;
$fa-var-solar-panel: \f5ba;
$fa-var-lock-open: \f3c1;
$fa-var-table-tree: \e293;
$fa-var-house-chimney-heart: \e1b2;
$fa-var-tally-3: \e296;
$fa-var-elevator: \e16d;
$fa-var-money-bill-transfer: \e528;
$fa-var-money-bill-trend-up: \e529;
$fa-var-house-flood-water-circle-arrow-right: \e50f;
$fa-var-square-poll-horizontal: \f682;
$fa-var-poll-h: \f682;
$fa-var-circle: \f111;
$fa-var-cart-circle-exclamation: \e3f2;
$fa-var-sword: \f71c;
$fa-var-backward-fast: \f049;
$fa-var-fast-backward: \f049;
$fa-var-recycle: \f1b8;
$fa-var-user-astronaut: \f4fb;
$fa-var-interrobang: \e5ba;
$fa-var-plane-slash: \e069;
$fa-var-circle-dashed: \e105;
$fa-var-trademark: \f25c;
$fa-var-basketball: \f434;
$fa-var-basketball-ball: \f434;
$fa-var-fork-knife: \f2e6;
$fa-var-utensils-alt: \f2e6;
$fa-var-satellite-dish: \f7c0;
$fa-var-badge-check: \f336;
$fa-var-circle-up: \f35b;
$fa-var-arrow-alt-circle-up: \f35b;
$fa-var-slider: \e252;
$fa-var-mobile-screen-button: \f3cd;
$fa-var-mobile-alt: \f3cd;
$fa-var-clock-one-thirty: \e34f;
$fa-var-inbox-out: \f311;
$fa-var-inbox-arrow-up: \f311;
$fa-var-cloud-slash: \e137;
$fa-var-volume-high: \f028;
$fa-var-volume-up: \f028;
$fa-var-users-rays: \e593;
$fa-var-wallet: \f555;
$fa-var-octagon-check: \e426;
$fa-var-flatbread-stuffed: \e40c;
$fa-var-clipboard-check: \f46c;
$fa-var-cart-circle-plus: \e3f3;
$fa-var-truck-clock: \f48c;
$fa-var-shipping-timed: \f48c;
$fa-var-pool-8-ball: \e3c5;
$fa-var-file-audio: \f1c7;
$fa-var-turn-down-left: \e331;
$fa-var-lock-hashtag: \e423;
$fa-var-chart-radar: \e0e7;
$fa-var-staff: \f71b;
$fa-var-burger: \f805;
$fa-var-hamburger: \f805;
$fa-var-utility-pole: \e2c3;
$fa-var-transporter-6: \e2a7;
$fa-var-wrench: \f0ad;
$fa-var-bugs: \e4d0;
$fa-var-vector-polygon: \e2c7;
$fa-var-diagram-nested: \e157;
$fa-var-rupee-sign: \f156;
$fa-var-rupee: \f156;
$fa-var-file-image: \f1c5;
$fa-var-circle-question: \f059;
$fa-var-question-circle: \f059;
$fa-var-image-user: \e1b8;
$fa-var-buoy: \e5b5;
$fa-var-plane-departure: \f5b0;
$fa-var-handshake-slash: \e060;
$fa-var-book-bookmark: \e0bb;
$fa-var-border-center-h: \f89c;
$fa-var-can-food: \e3e6;
$fa-var-typewriter: \f8e7;
$fa-var-arrow-right-from-arc: \e4b1;
$fa-var-circle-k: \e113;
$fa-var-face-hand-over-mouth: \e378;
$fa-var-popcorn: \f819;
$fa-var-house-water: \f74f;
$fa-var-house-flood: \f74f;
$fa-var-object-subtract: \e49e;
$fa-var-code-branch: \f126;
$fa-var-warehouse-full: \f495;
$fa-var-warehouse-alt: \f495;
$fa-var-hat-cowboy: \f8c0;
$fa-var-bridge: \e4c8;
$fa-var-phone-flip: \f879;
$fa-var-phone-alt: \f879;
$fa-var-arrow-down-from-dotted-line: \e090;
$fa-var-square-quarters: \e44e;
$fa-var-truck-front: \e2b7;
$fa-var-cat: \f6be;
$fa-var-trash-xmark: \e2b4;
$fa-var-circle-caret-left: \f32e;
$fa-var-caret-circle-left: \f32e;
$fa-var-files: \e178;
$fa-var-anchor-circle-exclamation: \e4ab;
$fa-var-face-clouds: \e47d;
$fa-var-user-crown: \f6a4;
$fa-var-truck-field: \e58d;
$fa-var-route: \f4d7;
$fa-var-cart-circle-check: \e3f1;
$fa-var-clipboard-question: \e4e3;
$fa-var-panorama: \e209;
$fa-var-comment-medical: \f7f5;
$fa-var-teeth-open: \f62f;
$fa-var-user-tie-hair-long: \e460;
$fa-var-file-circle-minus: \e4ed;
$fa-var-head-side-medical: \f809;
$fa-var-tags: \f02c;
$fa-var-wine-glass: \f4e3;
$fa-var-forward-fast: \f050;
$fa-var-fast-forward: \f050;
$fa-var-face-meh-blank: \f5a4;
$fa-var-meh-blank: \f5a4;
$fa-var-user-robot: \e04b;
$fa-var-square-parking: \f540;
$fa-var-parking: \f540;
$fa-var-card-diamond: \e3ea;
$fa-var-face-zipper: \e3a5;
$fa-var-face-raised-eyebrow: \e388;
$fa-var-house-signal: \e012;
$fa-var-square-chevron-up: \f32c;
$fa-var-chevron-square-up: \f32c;
$fa-var-bars-progress: \f828;
$fa-var-tasks-alt: \f828;
$fa-var-faucet-drip: \e006;
$fa-var-arrows-to-line: \e0a7;
$fa-var-dolphin: \e168;
$fa-var-arrow-up-right: \e09f;
$fa-var-circle-r: \e120;
$fa-var-cart-flatbed: \f474;
$fa-var-dolly-flatbed: \f474;
$fa-var-ban-smoking: \f54d;
$fa-var-smoking-ban: \f54d;
$fa-var-circle-sort-up: \e032;
$fa-var-sort-circle-up: \e032;
$fa-var-terminal: \f120;
$fa-var-mobile-button: \f10b;
$fa-var-house-medical-flag: \e514;
$fa-var-basket-shopping: \f291;
$fa-var-shopping-basket: \f291;
$fa-var-tape: \f4db;
$fa-var-chestnut: \e3f6;
$fa-var-bus-simple: \f55e;
$fa-var-bus-alt: \f55e;
$fa-var-eye: \f06e;
$fa-var-face-sad-cry: \f5b3;
$fa-var-sad-cry: \f5b3;
$fa-var-heat: \e00c;
$fa-var-ticket-airline: \e29a;
$fa-var-boot-heeled: \e33f;
$fa-var-arrows-minimize: \e0a5;
$fa-var-compress-arrows: \e0a5;
$fa-var-audio-description: \f29e;
$fa-var-person-military-to-person: \e54c;
$fa-var-file-shield: \e4f0;
$fa-var-hexagon: \f312;
$fa-var-manhole: \e1d6;
$fa-var-user-slash: \f506;
$fa-var-pen: \f304;
$fa-var-tower-observation: \e586;
$fa-var-floppy-disks: \e183;
$fa-var-toilet-paper-blank-under: \e29f;
$fa-var-toilet-paper-reverse-alt: \e29f;
$fa-var-file-code: \f1c9;
$fa-var-signal: \f012;
$fa-var-signal-5: \f012;
$fa-var-signal-perfect: \f012;
$fa-var-pump: \e442;
$fa-var-bus: \f207;
$fa-var-heart-circle-xmark: \e501;
$fa-var-arrow-up-left-from-circle: \e09e;
$fa-var-house-chimney: \e3af;
$fa-var-home-lg: \e3af;
$fa-var-window-maximize: \f2d0;
$fa-var-dryer: \f861;
$fa-var-face-frown: \f119;
$fa-var-frown: \f119;
$fa-var-chess-bishop-piece: \f43b;
$fa-var-chess-bishop-alt: \f43b;
$fa-var-shirt-tank-top: \e3c9;
$fa-var-diploma: \f5ea;
$fa-var-scroll-ribbon: \f5ea;
$fa-var-screencast: \e23e;
$fa-var-walker: \f831;
$fa-var-prescription: \f5b1;
$fa-var-shop: \f54f;
$fa-var-store-alt: \f54f;
$fa-var-floppy-disk: \f0c7;
$fa-var-save: \f0c7;
$fa-var-vihara: \f6a7;
$fa-var-face-kiss-closed-eyes: \e37d;
$fa-var-scale-unbalanced: \f515;
$fa-var-balance-scale-left: \f515;
$fa-var-file-user: \f65c;
$fa-var-user-police-tie: \e334;
$fa-var-face-tongue-money: \e39d;
$fa-var-tennis-ball: \f45e;
$fa-var-square-l: \e275;
$fa-var-sort-up: \f0de;
$fa-var-sort-asc: \f0de;
$fa-var-calendar-arrow-up: \e0d1;
$fa-var-calendar-upload: \e0d1;
$fa-var-comment-dots: \f4ad;
$fa-var-commenting: \f4ad;
$fa-var-plant-wilt: \e5aa;
$fa-var-scarf: \f7c1;
$fa-var-album-circle-plus: \e48c;
$fa-var-user-nurse-hair-long: \e45e;
$fa-var-diamond: \f219;
$fa-var-square-left: \f351;
$fa-var-arrow-alt-square-left: \f351;
$fa-var-face-grin-squint: \f585;
$fa-var-grin-squint: \f585;
$fa-var-circle-ellipsis-vertical: \e10b;
$fa-var-hand-holding-dollar: \f4c0;
$fa-var-hand-holding-usd: \f4c0;
$fa-var-grid-dividers: \e3ad;
$fa-var-bacterium: \e05a;
$fa-var-hand-pointer: \f25a;
$fa-var-drum-steelpan: \f56a;
$fa-var-hand-scissors: \f257;
$fa-var-hands-praying: \f684;
$fa-var-praying-hands: \f684;
$fa-var-face-pensive: \e384;
$fa-var-user-music: \f8eb;
$fa-var-arrow-rotate-right: \f01e;
$fa-var-arrow-right-rotate: \f01e;
$fa-var-arrow-rotate-forward: \f01e;
$fa-var-redo: \f01e;
$fa-var-messages-dollar: \f652;
$fa-var-comments-alt-dollar: \f652;
$fa-var-sensor-on: \e02b;
$fa-var-balloon: \e2e3;
$fa-var-biohazard: \f780;
$fa-var-chess-queen-piece: \f446;
$fa-var-chess-queen-alt: \f446;
$fa-var-location-crosshairs: \f601;
$fa-var-location: \f601;
$fa-var-mars-double: \f227;
$fa-var-house-person-leave: \e00f;
$fa-var-house-leave: \e00f;
$fa-var-house-person-depart: \e00f;
$fa-var-ruler-triangle: \f61c;
$fa-var-card-club: \e3e9;
$fa-var-child-dress: \e59c;
$fa-var-users-between-lines: \e591;
$fa-var-lungs-virus: \e067;
$fa-var-spinner-third: \f3f4;
$fa-var-face-grin-tears: \f588;
$fa-var-grin-tears: \f588;
$fa-var-phone: \f095;
$fa-var-computer-mouse-scrollwheel: \f8cd;
$fa-var-mouse-alt: \f8cd;
$fa-var-calendar-xmark: \f273;
$fa-var-calendar-times: \f273;
$fa-var-child-reaching: \e59d;
$fa-var-table-layout: \e290;
$fa-var-narwhal: \f6fe;
$fa-var-ramp-loading: \f4d4;
$fa-var-calendar-circle-plus: \e470;
$fa-var-toothbrush: \f635;
$fa-var-border-inner: \f84e;
$fa-var-paw-claws: \f702;
$fa-var-kiwi-fruit: \e30c;
$fa-var-traffic-light-slow: \f639;
$fa-var-rectangle-code: \e322;
$fa-var-head-side-virus: \e064;
$fa-var-keyboard-brightness: \e1c0;
$fa-var-books-medical: \f7e8;
$fa-var-lightbulb-slash: \f673;
$fa-var-house-blank: \e487;
$fa-var-home-blank: \e487;
$fa-var-square-5: \e25a;
$fa-var-square-heart: \f4c8;
$fa-var-heart-square: \f4c8;
$fa-var-puzzle: \e443;
$fa-var-user-gear: \f4fe;
$fa-var-user-cog: \f4fe;
$fa-var-pipe-circle-check: \e436;
$fa-var-arrow-up-1-9: \f163;
$fa-var-sort-numeric-up: \f163;
$fa-var-octagon-exclamation: \e204;
$fa-var-dial-low: \e15d;
$fa-var-door-closed: \f52a;
$fa-var-laptop-mobile: \f87a;
$fa-var-phone-laptop: \f87a;
$fa-var-conveyor-belt-boxes: \f46f;
$fa-var-conveyor-belt-alt: \f46f;
$fa-var-shield-virus: \e06c;
$fa-var-starfighter-twin-ion-engine-advanced: \e28e;
$fa-var-starfighter-alt-advanced: \e28e;
$fa-var-dice-six: \f526;
$fa-var-starfighter-twin-ion-engine: \e038;
$fa-var-starfighter-alt: \e038;
$fa-var-rocket-launch: \e027;
$fa-var-mosquito-net: \e52c;
$fa-var-vent-damper: \e465;
$fa-var-bridge-water: \e4ce;
$fa-var-ban-bug: \f7f9;
$fa-var-debug: \f7f9;
$fa-var-person-booth: \f756;
$fa-var-text-width: \f035;
$fa-var-garage-car: \e00a;
$fa-var-square-kanban: \e488;
$fa-var-hat-wizard: \f6e8;
$fa-var-pen-fancy: \f5ac;
$fa-var-coffee-pot: \e002;
$fa-var-mouse-field: \e5a8;
$fa-var-person-digging: \f85e;
$fa-var-digging: \f85e;
$fa-var-shower-down: \e24d;
$fa-var-shower-alt: \e24d;
$fa-var-box-circle-check: \e0c4;
$fa-var-brightness: \e0c9;
$fa-var-car-side-bolt: \e344;
$fa-var-ornament: \f7b8;
$fa-var-phone-arrow-down-left: \e223;
$fa-var-phone-arrow-down: \e223;
$fa-var-phone-incoming: \e223;
$fa-var-cloud-word: \e138;
$fa-var-hand-fingers-crossed: \e1a3;
$fa-var-trash: \f1f8;
$fa-var-gauge-simple: \f629;
$fa-var-gauge-simple-med: \f629;
$fa-var-tachometer-average: \f629;
$fa-var-arrow-down-small-big: \f88d;
$fa-var-sort-size-down-alt: \f88d;
$fa-var-book-medical: \f7e6;
$fa-var-face-melting: \e483;
$fa-var-poo: \f2fe;
$fa-var-pen-clip-slash: \e20f;
$fa-var-pen-alt-slash: \e20f;
$fa-var-quote-right: \f10e;
$fa-var-quote-right-alt: \f10e;
$fa-var-scroll-old: \f70f;
$fa-var-guitars: \f8bf;
$fa-var-phone-xmark: \e227;
$fa-var-hose: \e419;
$fa-var-clock-six: \e352;
$fa-var-shirt: \f553;
$fa-var-t-shirt: \f553;
$fa-var-tshirt: \f553;
$fa-var-square-r: \e27c;
$fa-var-cubes: \f1b3;
$fa-var-envelope-open-dollar: \f657;
$fa-var-divide: \f529;
$fa-var-sun-cloud: \f763;
$fa-var-lamp-floor: \e015;
$fa-var-square-7: \e25c;
$fa-var-tenge-sign: \f7d7;
$fa-var-tenge: \f7d7;
$fa-var-headphones: \f025;
$fa-var-hands-holding: \f4c2;
$fa-var-campfire: \f6ba;
$fa-var-circle-ampersand: \e0f8;
$fa-var-snowflakes: \f7cf;
$fa-var-hands-clapping: \e1a8;
$fa-var-republican: \f75e;
$fa-var-leaf-maple: \f6f6;
$fa-var-arrow-left: \f060;
$fa-var-person-circle-xmark: \e543;
$fa-var-ruler: \f545;
$fa-var-cup-straw-swoosh: \e364;
$fa-var-temperature-sun: \f76a;
$fa-var-temperature-hot: \f76a;
$fa-var-align-left: \f036;
$fa-var-dice-d6: \f6d1;
$fa-var-restroom: \f7bd;
$fa-var-high-definition: \e1ae;
$fa-var-rectangle-hd: \e1ae;
$fa-var-j: \4a;
$fa-var-galaxy: \e008;
$fa-var-users-viewfinder: \e595;
$fa-var-file-video: \f1c8;
$fa-var-cherries: \e0ec;
$fa-var-up-right-from-square: \f35d;
$fa-var-external-link-alt: \f35d;
$fa-var-circle-sort: \e030;
$fa-var-sort-circle: \e030;
$fa-var-table-cells: \f00a;
$fa-var-th: \f00a;
$fa-var-file-pdf: \f1c1;
$fa-var-siren: \e02d;
$fa-var-arrow-up-to-dotted-line: \e0a1;
$fa-var-image-landscape: \e1b5;
$fa-var-landscape: \e1b5;
$fa-var-tank-water: \e452;
$fa-var-curling-stone: \f44a;
$fa-var-curling: \f44a;
$fa-var-gamepad-modern: \e5a2;
$fa-var-gamepad-alt: \e5a2;
$fa-var-messages-question: \e1e7;
$fa-var-book-bible: \f647;
$fa-var-bible: \f647;
$fa-var-o: \4f;
$fa-var-suitcase-medical: \f0fa;
$fa-var-medkit: \f0fa;
$fa-var-briefcase-arrow-right: \e2f2;
$fa-var-expand-wide: \f320;
$fa-var-clock-eleven-thirty: \e348;
$fa-var-rv: \f7be;
$fa-var-user-secret: \f21b;
$fa-var-otter: \f700;
$fa-var-dreidel: \f792;
$fa-var-person-dress: \f182;
$fa-var-female: \f182;
$fa-var-comment-dollar: \f651;
$fa-var-business-time: \f64a;
$fa-var-briefcase-clock: \f64a;
$fa-var-flower-tulip: \f801;
$fa-var-people-pants-simple: \e21a;
$fa-var-cloud-drizzle: \f738;
$fa-var-table-cells-large: \f009;
$fa-var-th-large: \f009;
$fa-var-book-tanakh: \f827;
$fa-var-tanakh: \f827;
$fa-var-solar-system: \e02f;
$fa-var-seal-question: \e243;
$fa-var-phone-volume: \f2a0;
$fa-var-volume-control-phone: \f2a0;
$fa-var-disc-drive: \f8b5;
$fa-var-hat-cowboy-side: \f8c1;
$fa-var-table-rows: \e292;
$fa-var-rows: \e292;
$fa-var-location-exclamation: \f608;
$fa-var-map-marker-exclamation: \f608;
$fa-var-face-fearful: \e375;
$fa-var-clipboard-user: \f7f3;
$fa-var-bus-school: \f5dd;
$fa-var-film-slash: \e179;
$fa-var-square-arrow-down-right: \e262;
$fa-var-book-sparkles: \f6b8;
$fa-var-book-spells: \f6b8;
$fa-var-washing-machine: \f898;
$fa-var-washer: \f898;
$fa-var-child: \f1ae;
$fa-var-lira-sign: \f195;
$fa-var-user-visor: \e04c;
$fa-var-file-plus-minus: \e177;
$fa-var-chess-clock-flip: \f43e;
$fa-var-chess-clock-alt: \f43e;
$fa-var-satellite: \f7bf;
$fa-var-plane-lock: \e558;
$fa-var-steering-wheel: \f622;
$fa-var-tag: \f02b;
$fa-var-stretcher: \f825;
$fa-var-book-section: \e0c1;
$fa-var-book-law: \e0c1;
$fa-var-inboxes: \e1bb;
$fa-var-coffee-bean: \e13e;
$fa-var-brackets-curly: \f7ea;
$fa-var-ellipsis-stroke-vertical: \f39c;
$fa-var-ellipsis-v-alt: \f39c;
$fa-var-comment: \f075;
$fa-var-square-1: \e256;
$fa-var-cake-candles: \f1fd;
$fa-var-birthday-cake: \f1fd;
$fa-var-cake: \f1fd;
$fa-var-head-side: \f6e9;
$fa-var-envelope: \f0e0;
$fa-var-dolly-empty: \f473;
$fa-var-face-tissue: \e39c;
$fa-var-angles-up: \f102;
$fa-var-angle-double-up: \f102;
$fa-var-paperclip: \f0c6;
$fa-var-chart-line-down: \f64d;
$fa-var-arrow-right-to-city: \e4b3;
$fa-var-lock-a: \e422;
$fa-var-ribbon: \f4d6;
$fa-var-lungs: \f604;
$fa-var-person-pinball: \e21d;
$fa-var-arrow-up-9-1: \f887;
$fa-var-sort-numeric-up-alt: \f887;
$fa-var-apple-core: \e08f;
$fa-var-circle-y: \e12f;
$fa-var-h6: \e413;
$fa-var-litecoin-sign: \e1d3;
$fa-var-circle-small: \e122;
$fa-var-border-none: \f850;
$fa-var-arrow-turn-down-left: \e2e1;
$fa-var-circle-nodes: \e4e2;
$fa-var-parachute-box: \f4cd;
$fa-var-message-medical: \f7f4;
$fa-var-comment-alt-medical: \f7f4;
$fa-var-rugby-ball: \e3c6;
$fa-var-comment-music: \f8b0;
$fa-var-indent: \f03c;
$fa-var-tree-deciduous: \f400;
$fa-var-tree-alt: \f400;
$fa-var-puzzle-piece-simple: \e231;
$fa-var-puzzle-piece-alt: \e231;
$fa-var-truck-field-un: \e58e;
$fa-var-nfc-trash: \e1fd;
$fa-var-hourglass: \f254;
$fa-var-hourglass-empty: \f254;
$fa-var-mountain: \f6fc;
$fa-var-file-xmark: \f317;
$fa-var-file-times: \f317;
$fa-var-house-heart: \f4c9;
$fa-var-home-heart: \f4c9;
$fa-var-house-chimney-blank: \e3b0;
$fa-var-meter-bolt: \e1e9;
$fa-var-user-doctor: \f0f0;
$fa-var-user-md: \f0f0;
$fa-var-slash-back: \5c;
$fa-var-circle-info: \f05a;
$fa-var-info-circle: \f05a;
$fa-var-fishing-rod: \e3a8;
$fa-var-hammer-crash: \e414;
$fa-var-message-heart: \e5c9;
$fa-var-cloud-meatball: \f73b;
$fa-var-camera-polaroid: \f8aa;
$fa-var-camera: \f030;
$fa-var-camera-alt: \f030;
$fa-var-square-virus: \e578;
$fa-var-cart-arrow-up: \e3ee;
$fa-var-meteor: \f753;
$fa-var-car-on: \e4dd;
$fa-var-sleigh: \f7cc;
$fa-var-arrow-down-1-9: \f162;
$fa-var-sort-numeric-asc: \f162;
$fa-var-sort-numeric-down: \f162;
$fa-var-buoy-mooring: \e5b6;
$fa-var-square-4: \e259;
$fa-var-hand-holding-droplet: \f4c1;
$fa-var-hand-holding-water: \f4c1;
$fa-var-tricycle-adult: \e5c4;
$fa-var-waveform: \f8f1;
$fa-var-water: \f773;
$fa-var-star-sharp-half-stroke: \e28d;
$fa-var-star-sharp-half-alt: \e28d;
$fa-var-nfc-signal: \e1fb;
$fa-var-plane-prop: \e22b;
$fa-var-calendar-check: \f274;
$fa-var-clock-desk: \e134;
$fa-var-calendar-clock: \e0d2;
$fa-var-calendar-time: \e0d2;
$fa-var-braille: \f2a1;
$fa-var-prescription-bottle-medical: \f486;
$fa-var-prescription-bottle-alt: \f486;
$fa-var-plate-utensils: \e43b;
$fa-var-family-pants: \e302;
$fa-var-hose-reel: \e41a;
$fa-var-house-window: \e3b3;
$fa-var-landmark: \f66f;
$fa-var-truck: \f0d1;
$fa-var-crosshairs: \f05b;
$fa-var-cloud-rainbow: \f73e;
$fa-var-person-cane: \e53c;
$fa-var-alien: \f8f5;
$fa-var-tent: \e57d;
$fa-var-vest-patches: \e086;
$fa-var-people-dress-simple: \e218;
$fa-var-check-double: \f560;
$fa-var-arrow-down-a-z: \f15d;
$fa-var-sort-alpha-asc: \f15d;
$fa-var-sort-alpha-down: \f15d;
$fa-var-bowling-ball-pin: \e0c3;
$fa-var-bell-school-slash: \f5d6;
$fa-var-plus-large: \e59e;
$fa-var-money-bill-wheat: \e52a;
$fa-var-camera-viewfinder: \e0da;
$fa-var-screenshot: \e0da;
$fa-var-message-music: \f8af;
$fa-var-comment-alt-music: \f8af;
$fa-var-car-building: \f859;
$fa-var-border-bottom-right: \f854;
$fa-var-border-style-alt: \f854;
$fa-var-octagon: \f306;
$fa-var-comment-arrow-up-right: \e145;
$fa-var-octagon-divide: \e203;
$fa-var-cookie: \f563;
$fa-var-arrow-rotate-left: \f0e2;
$fa-var-arrow-left-rotate: \f0e2;
$fa-var-arrow-rotate-back: \f0e2;
$fa-var-arrow-rotate-backward: \f0e2;
$fa-var-undo: \f0e2;
$fa-var-tv-music: \f8e6;
$fa-var-hard-drive: \f0a0;
$fa-var-hdd: \f0a0;
$fa-var-reel: \e238;
$fa-var-face-grin-squint-tears: \f586;
$fa-var-grin-squint-tears: \f586;
$fa-var-dumbbell: \f44b;
$fa-var-rectangle-list: \f022;
$fa-var-list-alt: \f022;
$fa-var-tarp-droplet: \e57c;
$fa-var-alarm-exclamation: \f843;
$fa-var-house-medical-circle-check: \e511;
$fa-var-traffic-cone: \f636;
$fa-var-grate: \e193;
$fa-var-arrow-down-right: \e093;
$fa-var-person-skiing-nordic: \f7ca;
$fa-var-skiing-nordic: \f7ca;
$fa-var-calendar-plus: \f271;
$fa-var-person-from-portal: \e023;
$fa-var-portal-exit: \e023;
$fa-var-plane-arrival: \f5af;
$fa-var-cowbell-circle-plus: \f8b4;
$fa-var-cowbell-more: \f8b4;
$fa-var-circle-left: \f359;
$fa-var-arrow-alt-circle-left: \f359;
$fa-var-distribute-spacing-vertical: \e366;
$fa-var-signal-bars-fair: \f692;
$fa-var-signal-alt-2: \f692;
$fa-var-sportsball: \e44b;
$fa-var-game-console-handheld-crank: \e5b9;
$fa-var-train-subway: \f239;
$fa-var-subway: \f239;
$fa-var-chart-gantt: \e0e4;
$fa-var-face-smile-upside-down: \e395;
$fa-var-ball-pile: \f77e;
$fa-var-badge-dollar: \f645;
$fa-var-money-bills-simple: \e1f4;
$fa-var-money-bills-alt: \e1f4;
$fa-var-list-timeline: \e1d1;
$fa-var-indian-rupee-sign: \e1bc;
$fa-var-indian-rupee: \e1bc;
$fa-var-inr: \e1bc;
$fa-var-crop-simple: \f565;
$fa-var-crop-alt: \f565;
$fa-var-money-bill-1: \f3d1;
$fa-var-money-bill-alt: \f3d1;
$fa-var-left-long: \f30a;
$fa-var-long-arrow-alt-left: \f30a;
$fa-var-keyboard-down: \e1c2;
$fa-var-circle-up-right: \e129;
$fa-var-cloud-bolt-moon: \f76d;
$fa-var-thunderstorm-moon: \f76d;
$fa-var-dna: \f471;
$fa-var-virus-slash: \e075;
$fa-var-bracket-round-right: \29;
$fa-var-circle-5: \e0f2;
$fa-var-minus: \f068;
$fa-var-subtract: \f068;
$fa-var-fire-flame: \f6df;
$fa-var-flame: \f6df;
$fa-var-right-to-line: \f34c;
$fa-var-arrow-alt-to-right: \f34c;
$fa-var-gif: \e190;
$fa-var-chess: \f439;
$fa-var-trash-slash: \e2b3;
$fa-var-arrow-left-long: \f177;
$fa-var-long-arrow-left: \f177;
$fa-var-plug-circle-check: \e55c;
$fa-var-font-case: \f866;
$fa-var-street-view: \f21d;
$fa-var-arrow-down-left: \e091;
$fa-var-franc-sign: \e18f;
$fa-var-flask-round-poison: \f6e0;
$fa-var-flask-poison: \f6e0;
$fa-var-volume-off: \f026;
$fa-var-book-circle-arrow-right: \e0bc;
$fa-var-chart-user: \f6a3;
$fa-var-user-chart: \f6a3;
$fa-var-hands-asl-interpreting: \f2a3;
$fa-var-american-sign-language-interpreting: \f2a3;
$fa-var-asl-interpreting: \f2a3;
$fa-var-hands-american-sign-language-interpreting: \f2a3;
$fa-var-presentation-screen: \f685;
$fa-var-presentation: \f685;
$fa-var-circle-bolt: \e0fe;
$fa-var-face-smile-halo: \e38f;
$fa-var-cart-circle-arrow-down: \e3ef;
$fa-var-house-person-return: \e011;
$fa-var-house-person-arrive: \e011;
$fa-var-house-return: \e011;
$fa-var-message-xmark: \f4ab;
$fa-var-comment-alt-times: \f4ab;
$fa-var-message-times: \f4ab;
$fa-var-file-certificate: \f5f3;
$fa-var-file-award: \f5f3;
$fa-var-user-doctor-hair-long: \e459;
$fa-var-camera-security: \f8fe;
$fa-var-camera-home: \f8fe;
$fa-var-gear: \f013;
$fa-var-cog: \f013;
$fa-var-droplet-slash: \f5c7;
$fa-var-tint-slash: \f5c7;
$fa-var-book-heart: \f499;
$fa-var-mosque: \f678;
$fa-var-duck: \f6d8;
$fa-var-mosquito: \e52b;
$fa-var-star-of-david: \f69a;
$fa-var-flag-swallowtail: \f74c;
$fa-var-flag-alt: \f74c;
$fa-var-person-military-rifle: \e54b;
$fa-var-car-garage: \f5e2;
$fa-var-cart-shopping: \f07a;
$fa-var-shopping-cart: \f07a;
$fa-var-book-font: \e0bf;
$fa-var-shield-plus: \e24a;
$fa-var-vials: \f493;
$fa-var-eye-dropper-full: \e172;
$fa-var-distribute-spacing-horizontal: \e365;
$fa-var-tablet-rugged: \f48f;
$fa-var-temperature-snow: \f768;
$fa-var-temperature-frigid: \f768;
$fa-var-moped: \e3b9;
$fa-var-face-smile-plus: \f5b9;
$fa-var-smile-plus: \f5b9;
$fa-var-radio-tuner: \f8d8;
$fa-var-radio-alt: \f8d8;
$fa-var-face-swear: \e399;
$fa-var-water-arrow-down: \f774;
$fa-var-water-lower: \f774;
$fa-var-scanner-touchscreen: \f48a;
$fa-var-circle-7: \e0f4;
$fa-var-plug-circle-plus: \e55f;
$fa-var-person-ski-jumping: \f7c7;
$fa-var-ski-jump: \f7c7;
$fa-var-place-of-worship: \f67f;
$fa-var-water-arrow-up: \f775;
$fa-var-water-rise: \f775;
$fa-var-waveform-lines: \f8f2;
$fa-var-waveform-path: \f8f2;
$fa-var-split: \e254;
$fa-var-film-canister: \f8b7;
$fa-var-film-cannister: \f8b7;
$fa-var-folder-xmark: \f65f;
$fa-var-folder-times: \f65f;
$fa-var-toilet-paper-blank: \f71f;
$fa-var-toilet-paper-alt: \f71f;
$fa-var-tablet-screen: \f3fc;
$fa-var-tablet-android-alt: \f3fc;
$fa-var-hexagon-vertical-nft-slanted: \e506;
$fa-var-folder-music: \e18d;
$fa-var-display-medical: \e166;
$fa-var-desktop-medical: \e166;
$fa-var-share-all: \f367;
$fa-var-peapod: \e31c;
$fa-var-chess-clock: \f43d;
$fa-var-axe: \f6b2;
$fa-var-square-d: \e268;
$fa-var-grip-vertical: \f58e;
$fa-var-mobile-signal-out: \e1f0;
$fa-var-arrow-turn-up: \f148;
$fa-var-level-up: \f148;
$fa-var-u: \55;
$fa-var-arrow-up-from-dotted-line: \e09b;
$fa-var-square-root-variable: \f698;
$fa-var-square-root-alt: \f698;
$fa-var-light-switch-on: \e019;
$fa-var-arrow-down-arrow-up: \f883;
$fa-var-sort-alt: \f883;
$fa-var-raindrops: \f75c;
$fa-var-dash: \e404;
$fa-var-minus-large: \e404;
$fa-var-clock: \f017;
$fa-var-clock-four: \f017;
$fa-var-input-numeric: \e1bd;
$fa-var-truck-tow: \e2b8;
$fa-var-backward-step: \f048;
$fa-var-step-backward: \f048;
$fa-var-pallet: \f482;
$fa-var-car-bolt: \e341;
$fa-var-arrows-maximize: \f31d;
$fa-var-expand-arrows: \f31d;
$fa-var-faucet: \e005;
$fa-var-cloud-sleet: \f741;
$fa-var-lamp-street: \e1c5;
$fa-var-list-radio: \e1d0;
$fa-var-pen-nib-slash: \e4a1;
$fa-var-baseball-bat-ball: \f432;
$fa-var-square-up-left: \e282;
$fa-var-overline: \f876;
$fa-var-s: \53;
$fa-var-timeline: \e29c;
$fa-var-keyboard: \f11c;
$fa-var-arrows-from-dotted-line: \e0a3;
$fa-var-usb-drive: \f8e9;
$fa-var-ballot: \f732;
$fa-var-caret-down: \f0d7;
$fa-var-location-dot-slash: \f605;
$fa-var-map-marker-alt-slash: \f605;
$fa-var-cards: \e3ed;
$fa-var-house-chimney-medical: \f7f2;
$fa-var-clinic-medical: \f7f2;
$fa-var-boxing-glove: \f438;
$fa-var-glove-boxing: \f438;
$fa-var-temperature-three-quarters: \f2c8;
$fa-var-temperature-3: \f2c8;
$fa-var-thermometer-3: \f2c8;
$fa-var-thermometer-three-quarters: \f2c8;
$fa-var-bell-school: \f5d5;
$fa-var-mobile-screen: \f3cf;
$fa-var-mobile-android-alt: \f3cf;
$fa-var-plane-up: \e22d;
$fa-var-folder-heart: \e189;
$fa-var-circle-location-arrow: \f602;
$fa-var-location-circle: \f602;
$fa-var-face-head-bandage: \e37a;
$fa-var-sushi-roll: \e48b;
$fa-var-maki-roll: \e48b;
$fa-var-makizushi: \e48b;
$fa-var-car-bump: \f5e0;
$fa-var-piggy-bank: \f4d3;
$fa-var-racquet: \f45a;
$fa-var-car-mirrors: \e343;
$fa-var-industry-windows: \f3b3;
$fa-var-industry-alt: \f3b3;
$fa-var-bolt-auto: \e0b6;
$fa-var-battery-half: \f242;
$fa-var-battery-3: \f242;
$fa-var-flux-capacitor: \f8ba;
$fa-var-mountain-city: \e52e;
$fa-var-coins: \f51e;
$fa-var-honey-pot: \e418;
$fa-var-olive: \e316;
$fa-var-khanda: \f66d;
$fa-var-filter-list: \e17c;
$fa-var-outlet: \e01c;
$fa-var-sliders: \f1de;
$fa-var-sliders-h: \f1de;
$fa-var-cauldron: \f6bf;
$fa-var-people: \e216;
$fa-var-folder-tree: \f802;
$fa-var-network-wired: \f6ff;
$fa-var-croissant: \f7f6;
$fa-var-map-pin: \f276;
$fa-var-hamsa: \f665;
$fa-var-cent-sign: \e3f5;
$fa-var-swords-laser: \e03d;
$fa-var-flask: \f0c3;
$fa-var-person-pregnant: \e31e;
$fa-var-square-u: \e281;
$fa-var-wand-sparkles: \f72b;
$fa-var-router: \f8da;
$fa-var-ellipsis-vertical: \f142;
$fa-var-ellipsis-v: \f142;
$fa-var-sword-laser-alt: \e03c;
$fa-var-ticket: \f145;
$fa-var-power-off: \f011;
$fa-var-coin: \f85c;
$fa-var-laptop-slash: \e1c7;
$fa-var-right-long: \f30b;
$fa-var-long-arrow-alt-right: \f30b;
$fa-var-circle-b: \e0fd;
$fa-var-person-dress-simple: \e21c;
$fa-var-pipe-collar: \e437;
$fa-var-lights-holiday: \f7b2;
$fa-var-citrus: \e2f4;
$fa-var-flag-usa: \f74d;
$fa-var-laptop-file: \e51d;
$fa-var-tty: \f1e4;
$fa-var-teletype: \f1e4;
$fa-var-chart-tree-map: \e0ea;
$fa-var-diagram-next: \e476;
$fa-var-person-rifle: \e54e;
$fa-var-clock-five-thirty: \e34a;
$fa-var-pipe-valve: \e439;
$fa-var-arrow-up-from-arc: \e4b4;
$fa-var-face-spiral-eyes: \e485;
$fa-var-compress-wide: \f326;
$fa-var-circle-phone-hangup: \e11d;
$fa-var-phone-circle-down: \e11d;
$fa-var-house-medical-circle-exclamation: \e512;
$fa-var-badminton: \e33a;
$fa-var-closed-captioning: \f20a;
$fa-var-person-hiking: \f6ec;
$fa-var-hiking: \f6ec;
$fa-var-right-from-line: \f347;
$fa-var-arrow-alt-from-left: \f347;
$fa-var-venus-double: \f226;
$fa-var-images: \f302;
$fa-var-calculator: \f1ec;
$fa-var-shuttlecock: \f45b;
$fa-var-user-hair: \e45a;
$fa-var-eye-evil: \f6db;
$fa-var-people-pulling: \e535;
$fa-var-n: \4e;
$fa-var-garage: \e009;
$fa-var-cable-car: \f7da;
$fa-var-tram: \f7da;
$fa-var-shovel-snow: \f7c3;
$fa-var-cloud-rain: \f73d;
$fa-var-face-lying: \e37e;
$fa-var-sprinkler: \e035;
$fa-var-building-circle-xmark: \e4d4;
$fa-var-person-sledding: \f7cb;
$fa-var-sledding: \f7cb;
$fa-var-game-console-handheld: \f8bb;
$fa-var-ship: \f21a;
$fa-var-clock-six-thirty: \e353;
$fa-var-battery-slash: \f377;
$fa-var-tugrik-sign: \e2ba;
$fa-var-arrows-down-to-line: \e4b8;
$fa-var-download: \f019;
$fa-var-shelves: \f480;
$fa-var-inventory: \f480;
$fa-var-cloud-snow: \f742;
$fa-var-face-grin: \f580;
$fa-var-grin: \f580;
$fa-var-delete-left: \f55a;
$fa-var-backspace: \f55a;
$fa-var-oven: \e01d;
$fa-var-eye-dropper: \f1fb;
$fa-var-eye-dropper-empty: \f1fb;
$fa-var-eyedropper: \f1fb;
$fa-var-comment-captions: \e146;
$fa-var-comments-question: \e14e;
$fa-var-scribble: \e23f;
$fa-var-rotate-exclamation: \e23c;
$fa-var-file-circle-check: \e5a0;
$fa-var-glass: \f804;
$fa-var-loader: \e1d4;
$fa-var-forward: \f04e;
$fa-var-user-pilot: \e2c0;
$fa-var-mobile: \f3ce;
$fa-var-mobile-android: \f3ce;
$fa-var-mobile-phone: \f3ce;
$fa-var-code-pull-request-closed: \e3f9;
$fa-var-face-meh: \f11a;
$fa-var-meh: \f11a;
$fa-var-align-center: \f037;
$fa-var-book-skull: \f6b7;
$fa-var-book-dead: \f6b7;
$fa-var-id-card: \f2c2;
$fa-var-drivers-license: \f2c2;
$fa-var-face-dotted: \e47f;
$fa-var-face-worried: \e3a3;
$fa-var-outdent: \f03b;
$fa-var-dedent: \f03b;
$fa-var-heart-circle-exclamation: \e4fe;
$fa-var-house: \f015;
$fa-var-home: \f015;
$fa-var-home-alt: \f015;
$fa-var-home-lg-alt: \f015;
$fa-var-vector-circle: \e2c6;
$fa-var-car-circle-bolt: \e342;
$fa-var-calendar-week: \f784;
$fa-var-flying-disc: \e3a9;
$fa-var-laptop-medical: \f812;
$fa-var-square-down-right: \e26c;
$fa-var-b: \42;
$fa-var-seat-airline: \e244;
$fa-var-moon-over-sun: \f74a;
$fa-var-eclipse-alt: \f74a;
$fa-var-pipe: \7c;
$fa-var-file-medical: \f477;
$fa-var-potato: \e440;
$fa-var-dice-one: \f525;
$fa-var-circle-a: \e0f7;
$fa-var-helmet-battle: \f6eb;
$fa-var-butter: \e3e4;
$fa-var-blanket-fire: \e3da;
$fa-var-kiwi-bird: \f535;
$fa-var-castle: \e0de;
$fa-var-golf-club: \f451;
$fa-var-arrow-right-arrow-left: \f0ec;
$fa-var-exchange: \f0ec;
$fa-var-rotate-right: \f2f9;
$fa-var-redo-alt: \f2f9;
$fa-var-rotate-forward: \f2f9;
$fa-var-utensils: \f2e7;
$fa-var-cutlery: \f2e7;
$fa-var-arrow-up-wide-short: \f161;
$fa-var-sort-amount-up: \f161;
$fa-var-balloons: \e2e4;
$fa-var-mill-sign: \e1ed;
$fa-var-bowl-rice: \e2eb;
$fa-var-timeline-arrow: \e29d;
$fa-var-skull: \f54c;
$fa-var-game-board-simple: \f868;
$fa-var-game-board-alt: \f868;
$fa-var-circle-video: \e12b;
$fa-var-video-circle: \e12b;
$fa-var-chart-scatter-bubble: \e0e9;
$fa-var-house-turret: \e1b4;
$fa-var-banana: \e2e5;
$fa-var-hand-holding-skull: \e1a4;
$fa-var-people-dress: \e217;
$fa-var-loveseat: \f4cc;
$fa-var-couch-small: \f4cc;
$fa-var-tower-broadcast: \f519;
$fa-var-broadcast-tower: \f519;
$fa-var-truck-pickup: \f63c;
$fa-var-block-quote: \e0b5;
$fa-var-up-long: \f30c;
$fa-var-long-arrow-alt-up: \f30c;
$fa-var-stop: \f04d;
$fa-var-code-merge: \f387;
$fa-var-money-check-dollar-pen: \f873;
$fa-var-money-check-edit-alt: \f873;
$fa-var-up-from-line: \f346;
$fa-var-arrow-alt-from-bottom: \f346;
$fa-var-upload: \f093;
$fa-var-hurricane: \f751;
$fa-var-people-pants: \e219;
$fa-var-mound: \e52d;
$fa-var-windsock: \f777;
$fa-var-circle-half: \e110;
$fa-var-brake-warning: \e0c7;
$fa-var-toilet-portable: \e583;
$fa-var-compact-disc: \f51f;
$fa-var-file-arrow-down: \f56d;
$fa-var-file-download: \f56d;
$fa-var-saxophone-fire: \f8db;
$fa-var-sax-hot: \f8db;
$fa-var-camera-web-slash: \f833;
$fa-var-webcam-slash: \f833;
$fa-var-folder-medical: \e18c;
$fa-var-folder-gear: \e187;
$fa-var-folder-cog: \e187;
$fa-var-hand-wave: \e1a7;
$fa-var-arrow-up-arrow-down: \e099;
$fa-var-sort-up-down: \e099;
$fa-var-caravan: \f8ff;
$fa-var-shield-cat: \e572;
$fa-var-message-slash: \f4a9;
$fa-var-comment-alt-slash: \f4a9;
$fa-var-bolt: \f0e7;
$fa-var-zap: \f0e7;
$fa-var-trash-can-check: \e2a9;
$fa-var-glass-water: \e4f4;
$fa-var-oil-well: \e532;
$fa-var-person-simple: \e220;
$fa-var-vault: \e2c5;
$fa-var-mars: \f222;
$fa-var-toilet: \f7d8;
$fa-var-plane-circle-xmark: \e557;
$fa-var-yen-sign: \f157;
$fa-var-cny: \f157;
$fa-var-jpy: \f157;
$fa-var-rmb: \f157;
$fa-var-yen: \f157;
$fa-var-notes: \e202;
$fa-var-ruble-sign: \f158;
$fa-var-rouble: \f158;
$fa-var-rub: \f158;
$fa-var-ruble: \f158;
$fa-var-trash-undo: \f895;
$fa-var-trash-arrow-turn-left: \f895;
$fa-var-champagne-glass: \f79e;
$fa-var-glass-champagne: \f79e;
$fa-var-objects-align-center-horizontal: \e3bc;
$fa-var-sun: \f185;
$fa-var-trash-can-slash: \e2ad;
$fa-var-trash-alt-slash: \e2ad;
$fa-var-screen-users: \f63d;
$fa-var-users-class: \f63d;
$fa-var-guitar: \f7a6;
$fa-var-square-arrow-left: \f33a;
$fa-var-arrow-square-left: \f33a;
$fa-var-square-8: \e25d;
$fa-var-face-smile-hearts: \e390;
$fa-var-brackets-square: \f7e9;
$fa-var-brackets: \f7e9;
$fa-var-laptop-arrow-down: \e1c6;
$fa-var-hockey-stick-puck: \e3ae;
$fa-var-house-tree: \e1b3;
$fa-var-signal-fair: \f68d;
$fa-var-signal-2: \f68d;
$fa-var-face-laugh-wink: \f59c;
$fa-var-laugh-wink: \f59c;
$fa-var-circle-dollar: \f2e8;
$fa-var-dollar-circle: \f2e8;
$fa-var-usd-circle: \f2e8;
$fa-var-horse-head: \f7ab;
$fa-var-arrows-repeat: \f364;
$fa-var-repeat-alt: \f364;
$fa-var-bore-hole: \e4c3;
$fa-var-industry: \f275;
$fa-var-image-polaroid: \f8c4;
$fa-var-wave-triangle: \f89a;
$fa-var-circle-down: \f358;
$fa-var-arrow-alt-circle-down: \f358;
$fa-var-grill: \e5a3;
$fa-var-arrows-turn-to-dots: \e4c1;
$fa-var-chart-mixed: \f643;
$fa-var-analytics: \f643;
$fa-var-florin-sign: \e184;
$fa-var-arrow-down-short-wide: \f884;
$fa-var-sort-amount-desc: \f884;
$fa-var-sort-amount-down-alt: \f884;
$fa-var-less-than: \3c;
$fa-var-display-code: \e165;
$fa-var-desktop-code: \e165;
$fa-var-face-drooling: \e372;
$fa-var-oil-temperature: \f614;
$fa-var-oil-temp: \f614;
$fa-var-square-question: \f2fd;
$fa-var-question-square: \f2fd;
$fa-var-air-conditioner: \f8f4;
$fa-var-angle-down: \f107;
$fa-var-mountains: \f6fd;
$fa-var-omega: \f67a;
$fa-var-car-tunnel: \e4de;
$fa-var-person-dolly-empty: \f4d1;
$fa-var-pan-food: \e42b;
$fa-var-head-side-cough: \e061;
$fa-var-grip-lines: \f7a4;
$fa-var-thumbs-down: \f165;
$fa-var-user-lock: \f502;
$fa-var-arrow-right-long: \f178;
$fa-var-long-arrow-right: \f178;
$fa-var-tickets-airline: \e29b;
$fa-var-anchor-circle-xmark: \e4ac;
$fa-var-ellipsis: \f141;
$fa-var-ellipsis-h: \f141;
$fa-var-nfc-slash: \e1fc;
$fa-var-chess-pawn: \f443;
$fa-var-kit-medical: \f479;
$fa-var-first-aid: \f479;
$fa-var-grid-2-plus: \e197;
$fa-var-bells: \f77f;
$fa-var-person-through-window: \e5a9;
$fa-var-toolbox: \f552;
$fa-var-envelope-dot: \e16f;
$fa-var-envelope-badge: \e16f;
$fa-var-hands-holding-circle: \e4fb;
$fa-var-bug: \f188;
$fa-var-bowl-chopsticks: \e2e9;
$fa-var-credit-card: \f09d;
$fa-var-credit-card-alt: \f09d;
$fa-var-circle-s: \e121;
$fa-var-box-ballot: \f735;
$fa-var-car: \f1b9;
$fa-var-automobile: \f1b9;
$fa-var-hand-holding-hand: \e4f7;
$fa-var-user-tie-hair: \e45f;
$fa-var-podium-star: \f758;
$fa-var-user-hair-mullet: \e45c;
$fa-var-business-front: \e45c;
$fa-var-party-back: \e45c;
$fa-var-trian-balbot: \e45c;
$fa-var-microphone-stand: \f8cb;
$fa-var-book-open-reader: \f5da;
$fa-var-book-reader: \f5da;
$fa-var-family-dress: \e301;
$fa-var-circle-x: \e12e;
$fa-var-cabin: \e46d;
$fa-var-mountain-sun: \e52f;
$fa-var-chart-simple-horizontal: \e474;
$fa-var-arrows-left-right-to-line: \e4ba;
$fa-var-hand-back-point-left: \e19f;
$fa-var-message-dots: \f4a3;
$fa-var-comment-alt-dots: \f4a3;
$fa-var-messaging: \f4a3;
$fa-var-file-heart: \e176;
$fa-var-beer-mug: \e0b3;
$fa-var-beer-foam: \e0b3;
$fa-var-dice-d20: \f6cf;
$fa-var-drone: \f85f;
$fa-var-truck-droplet: \e58c;
$fa-var-file-circle-xmark: \e5a1;
$fa-var-temperature-arrow-up: \e040;
$fa-var-temperature-up: \e040;
$fa-var-medal: \f5a2;
$fa-var-bed: \f236;
$fa-var-book-copy: \e0be;
$fa-var-square-h: \f0fd;
$fa-var-h-square: \f0fd;
$fa-var-square-c: \e266;
$fa-var-clock-two: \e35a;
$fa-var-square-ellipsis-vertical: \e26f;
$fa-var-podcast: \f2ce;
$fa-var-bee: \e0b2;
$fa-var-temperature-full: \f2c7;
$fa-var-temperature-4: \f2c7;
$fa-var-thermometer-4: \f2c7;
$fa-var-thermometer-full: \f2c7;
$fa-var-bell: \f0f3;
$fa-var-candy-bar: \e3e8;
$fa-var-chocolate-bar: \e3e8;
$fa-var-xmark-large: \e59b;
$fa-var-pinata: \e3c3;
$fa-var-arrows-from-line: \e0a4;
$fa-var-superscript: \f12b;
$fa-var-bowl-spoon: \e3e0;
$fa-var-hexagon-check: \e416;
$fa-var-plug-circle-xmark: \e560;
$fa-var-star-of-life: \f621;
$fa-var-phone-slash: \f3dd;
$fa-var-traffic-light-stop: \f63a;
$fa-var-paint-roller: \f5aa;
$fa-var-accent-grave: \60;
$fa-var-handshake-angle: \f4c4;
$fa-var-hands-helping: \f4c4;
$fa-var-circle-0: \e0ed;
$fa-var-dial-med-low: \e160;
$fa-var-location-dot: \f3c5;
$fa-var-map-marker-alt: \f3c5;
$fa-var-crab: \e3ff;
$fa-var-box-open-full: \f49c;
$fa-var-box-full: \f49c;
$fa-var-file: \f15b;
$fa-var-greater-than: \3e;
$fa-var-quotes: \e234;
$fa-var-pretzel: \e441;
$fa-var-person-swimming: \f5c4;
$fa-var-swimmer: \f5c4;
$fa-var-arrow-down: \f063;
$fa-var-user-robot-xmarks: \e4a7;
$fa-var-message-quote: \e1e4;
$fa-var-comment-alt-quote: \e1e4;
$fa-var-candy-corn: \f6bd;
$fa-var-folder-magnifying-glass: \e18b;
$fa-var-folder-search: \e18b;
$fa-var-notebook: \e201;
$fa-var-droplet: \f043;
$fa-var-tint: \f043;
$fa-var-bullseye-pointer: \f649;
$fa-var-eraser: \f12d;
$fa-var-hexagon-image: \e504;
$fa-var-earth-americas: \f57d;
$fa-var-earth: \f57d;
$fa-var-earth-america: \f57d;
$fa-var-globe-americas: \f57d;
$fa-var-crate-apple: \f6b1;
$fa-var-apple-crate: \f6b1;
$fa-var-person-burst: \e53b;
$fa-var-game-board: \f867;
$fa-var-hat-chef: \f86b;
$fa-var-hand-back-point-right: \e1a1;
$fa-var-dove: \f4ba;
$fa-var-snowflake-droplets: \e5c1;
$fa-var-battery-empty: \f244;
$fa-var-battery-0: \f244;
$fa-var-grid-4: \e198;
$fa-var-socks: \f696;
$fa-var-face-sunglasses: \e398;
$fa-var-inbox: \f01c;
$fa-var-square-0: \e255;
$fa-var-section: \e447;
$fa-var-square-this-way-up: \f49f;
$fa-var-box-up: \f49f;
$fa-var-gauge-high: \f625;
$fa-var-tachometer-alt: \f625;
$fa-var-tachometer-alt-fast: \f625;
$fa-var-square-ampersand: \e260;
$fa-var-envelope-open-text: \f658;
$fa-var-lamp-desk: \e014;
$fa-var-hospital: \f0f8;
$fa-var-hospital-alt: \f0f8;
$fa-var-hospital-wide: \f0f8;
$fa-var-poll-people: \f759;
$fa-var-whiskey-glass-ice: \f7a1;
$fa-var-glass-whiskey-rocks: \f7a1;
$fa-var-wine-bottle: \f72f;
$fa-var-chess-rook: \f447;
$fa-var-user-bounty-hunter: \e2bf;
$fa-var-bars-staggered: \f550;
$fa-var-reorder: \f550;
$fa-var-stream: \f550;
$fa-var-diagram-sankey: \e158;
$fa-var-cloud-hail-mixed: \f73a;
$fa-var-circle-up-left: \e128;
$fa-var-dharmachakra: \f655;
$fa-var-objects-align-left: \e3be;
$fa-var-oil-can-drip: \e205;
$fa-var-face-smiling-hands: \e396;
$fa-var-broccoli: \e3e2;
$fa-var-route-interstate: \f61b;
$fa-var-ear-muffs: \f795;
$fa-var-hotdog: \f80f;
$fa-var-transporter-empty: \e046;
$fa-var-person-walking-with-cane: \f29d;
$fa-var-blind: \f29d;
$fa-var-angle-90: \e08d;
$fa-var-rectangle-terminal: \e236;
$fa-var-kite: \f6f4;
$fa-var-drum: \f569;
$fa-var-scrubber: \f2f8;
$fa-var-ice-cream: \f810;
$fa-var-heart-circle-bolt: \e4fc;
$fa-var-fish-bones: \e304;
$fa-var-deer-rudolph: \f78f;
$fa-var-fax: \f1ac;
$fa-var-paragraph: \f1dd;
$fa-var-head-side-heart: \e1aa;
$fa-var-square-e: \e26d;
$fa-var-meter-fire: \e1eb;
$fa-var-cloud-hail: \f739;
$fa-var-check-to-slot: \f772;
$fa-var-vote-yea: \f772;
$fa-var-money-from-bracket: \e312;
$fa-var-star-half: \f089;
$fa-var-car-bus: \f85a;
$fa-var-speaker: \f8df;
$fa-var-timer: \e29e;
$fa-var-boxes-stacked: \f468;
$fa-var-boxes: \f468;
$fa-var-boxes-alt: \f468;
$fa-var-grill-hot: \e5a5;
$fa-var-ballot-check: \f733;
$fa-var-link: \f0c1;
$fa-var-chain: \f0c1;
$fa-var-ear-listen: \f2a2;
$fa-var-assistive-listening-systems: \f2a2;
$fa-var-file-minus: \f318;
$fa-var-tree-city: \e587;
$fa-var-play: \f04b;
$fa-var-font: \f031;
$fa-var-cup-togo: \f6c5;
$fa-var-coffee-togo: \f6c5;
$fa-var-square-down-left: \e26b;
$fa-var-burger-lettuce: \e3e3;
$fa-var-rupiah-sign: \e23d;
$fa-var-magnifying-glass: \f002;
$fa-var-search: \f002;
$fa-var-table-tennis-paddle-ball: \f45d;
$fa-var-ping-pong-paddle-ball: \f45d;
$fa-var-table-tennis: \f45d;
$fa-var-person-dots-from-line: \f470;
$fa-var-diagnoses: \f470;
$fa-var-chevrons-down: \f322;
$fa-var-chevron-double-down: \f322;
$fa-var-trash-can-arrow-up: \f82a;
$fa-var-trash-restore-alt: \f82a;
$fa-var-signal-good: \f68e;
$fa-var-signal-3: \f68e;
$fa-var-location-question: \f60b;
$fa-var-map-marker-question: \f60b;
$fa-var-floppy-disk-circle-xmark: \e181;
$fa-var-floppy-disk-times: \e181;
$fa-var-save-circle-xmark: \e181;
$fa-var-save-times: \e181;
$fa-var-naira-sign: \e1f6;
$fa-var-peach: \e20b;
$fa-var-taxi-bus: \e298;
$fa-var-bracket-curly: \7b;
$fa-var-bracket-curly-left: \7b;
$fa-var-lobster: \e421;
$fa-var-cart-flatbed-empty: \f476;
$fa-var-dolly-flatbed-empty: \f476;
$fa-var-colon: \3a;
$fa-var-cart-arrow-down: \f218;
$fa-var-wand: \f72a;
$fa-var-walkie-talkie: \f8ef;
$fa-var-file-pen: \f31c;
$fa-var-file-edit: \f31c;
$fa-var-receipt: \f543;
$fa-var-table-picnic: \e32d;
$fa-var-square-pen: \f14b;
$fa-var-pen-square: \f14b;
$fa-var-pencil-square: \f14b;
$fa-var-circle-microphone-lines: \e117;
$fa-var-microphone-circle-alt: \e117;
$fa-var-display-slash: \e2fa;
$fa-var-desktop-slash: \e2fa;
$fa-var-suitcase-rolling: \f5c1;
$fa-var-person-circle-exclamation: \e53f;
$fa-var-transporter-2: \e044;
$fa-var-hands-holding-diamond: \f47c;
$fa-var-hand-receiving: \f47c;
$fa-var-money-bill-simple-wave: \e1f2;
$fa-var-chevron-down: \f078;
$fa-var-battery-full: \f240;
$fa-var-battery: \f240;
$fa-var-battery-5: \f240;
$fa-var-bell-plus: \f849;
$fa-var-book-arrow-right: \e0b9;
$fa-var-hospitals: \f80e;
$fa-var-club: \f327;
$fa-var-skull-crossbones: \f714;
$fa-var-droplet-degree: \f748;
$fa-var-dewpoint: \f748;
$fa-var-code-compare: \e13a;
$fa-var-list-ul: \f0ca;
$fa-var-list-dots: \f0ca;
$fa-var-hand-holding-magic: \f6e5;
$fa-var-watermelon-slice: \e337;
$fa-var-circle-ellipsis: \e10a;
$fa-var-school-lock: \e56f;
$fa-var-tower-cell: \e585;
$fa-var-sd-cards: \e240;
$fa-var-down-long: \f309;
$fa-var-long-arrow-alt-down: \f309;
$fa-var-envelopes: \e170;
$fa-var-phone-office: \f67d;
$fa-var-ranking-star: \e561;
$fa-var-chess-king: \f43f;
$fa-var-nfc-pen: \e1fa;
$fa-var-person-harassing: \e549;
$fa-var-hat-winter: \f7a8;
$fa-var-brazilian-real-sign: \e46c;
$fa-var-landmark-dome: \f752;
$fa-var-landmark-alt: \f752;
$fa-var-bone-break: \f5d8;
$fa-var-arrow-up: \f062;
$fa-var-down-from-dotted-line: \e407;
$fa-var-tv: \f26c;
$fa-var-television: \f26c;
$fa-var-tv-alt: \f26c;
$fa-var-border-left: \f84f;
$fa-var-circle-divide: \e106;
$fa-var-shrimp: \e448;
$fa-var-list-check: \f0ae;
$fa-var-tasks: \f0ae;
$fa-var-diagram-subtask: \e479;
$fa-var-jug-detergent: \e519;
$fa-var-circle-user: \f2bd;
$fa-var-user-circle: \f2bd;
$fa-var-square-y: \e287;
$fa-var-user-doctor-hair: \e458;
$fa-var-planet-ringed: \e020;
$fa-var-mushroom: \e425;
$fa-var-user-shield: \f505;
$fa-var-megaphone: \f675;
$fa-var-circle-exclamation-check: \e10d;
$fa-var-wind: \f72e;
$fa-var-box-dollar: \f4a0;
$fa-var-box-usd: \f4a0;
$fa-var-car-burst: \f5e1;
$fa-var-car-crash: \f5e1;
$fa-var-y: \59;
$fa-var-user-headset: \f82d;
$fa-var-arrows-retweet: \f361;
$fa-var-retweet-alt: \f361;
$fa-var-person-snowboarding: \f7ce;
$fa-var-snowboarding: \f7ce;
$fa-var-square-chevron-right: \f32b;
$fa-var-chevron-square-right: \f32b;
$fa-var-lacrosse-stick-ball: \e3b6;
$fa-var-truck-fast: \f48b;
$fa-var-shipping-fast: \f48b;
$fa-var-user-magnifying-glass: \e5c5;
$fa-var-star-sharp: \e28b;
$fa-var-comment-heart: \e5c8;
$fa-var-circle-1: \e0ee;
$fa-var-circle-star: \e123;
$fa-var-star-circle: \e123;
$fa-var-fish: \f578;
$fa-var-cloud-fog: \f74e;
$fa-var-fog: \f74e;
$fa-var-waffle: \e466;
$fa-var-music-note: \f8cf;
$fa-var-music-alt: \f8cf;
$fa-var-hexagon-exclamation: \e417;
$fa-var-cart-shopping-fast: \e0dc;
$fa-var-object-union: \e49f;
$fa-var-user-graduate: \f501;
$fa-var-starfighter: \e037;
$fa-var-circle-half-stroke: \f042;
$fa-var-adjust: \f042;
$fa-var-arrow-right-long-to-line: \e3d5;
$fa-var-square-arrow-down: \f339;
$fa-var-arrow-square-down: \f339;
$fa-var-diamond-half-stroke: \e5b8;
$fa-var-clapperboard: \e131;
$fa-var-square-chevron-left: \f32a;
$fa-var-chevron-square-left: \f32a;
$fa-var-phone-intercom: \e434;
$fa-var-link-horizontal: \e1cb;
$fa-var-chain-horizontal: \e1cb;
$fa-var-mango: \e30f;
$fa-var-music-note-slash: \f8d0;
$fa-var-music-alt-slash: \f8d0;
$fa-var-circle-radiation: \f7ba;
$fa-var-radiation-alt: \f7ba;
$fa-var-face-tongue-sweat: \e39e;
$fa-var-globe-stand: \f5f6;
$fa-var-baseball: \f433;
$fa-var-baseball-ball: \f433;
$fa-var-circle-p: \e11a;
$fa-var-award-simple: \e0ab;
$fa-var-jet-fighter-up: \e518;
$fa-var-diagram-project: \f542;
$fa-var-project-diagram: \f542;
$fa-var-pedestal: \e20d;
$fa-var-chart-pyramid: \e0e6;
$fa-var-sidebar: \e24e;
$fa-var-snowman-head: \f79b;
$fa-var-frosty-head: \f79b;
$fa-var-copy: \f0c5;
$fa-var-burger-glass: \e0ce;
$fa-var-volume-xmark: \f6a9;
$fa-var-volume-mute: \f6a9;
$fa-var-volume-times: \f6a9;
$fa-var-hand-sparkles: \e05d;
$fa-var-bars-filter: \e0ad;
$fa-var-paintbrush-pencil: \e206;
$fa-var-party-bell: \e31a;
$fa-var-user-vneck-hair: \e462;
$fa-var-jack-o-lantern: \f30e;
$fa-var-grip: \f58d;
$fa-var-grip-horizontal: \f58d;
$fa-var-share-from-square: \f14d;
$fa-var-share-square: \f14d;
$fa-var-keynote: \f66c;
$fa-var-child-combatant: \e4e0;
$fa-var-child-rifle: \e4e0;
$fa-var-gun: \e19b;
$fa-var-square-phone: \f098;
$fa-var-phone-square: \f098;
$fa-var-plus: \2b;
$fa-var-add: \2b;
$fa-var-expand: \f065;
$fa-var-computer: \e4e5;
$fa-var-fort: \e486;
$fa-var-cloud-check: \e35c;
$fa-var-xmark: \f00d;
$fa-var-close: \f00d;
$fa-var-multiply: \f00d;
$fa-var-remove: \f00d;
$fa-var-times: \f00d;
$fa-var-face-smirking: \e397;
$fa-var-arrows-up-down-left-right: \f047;
$fa-var-arrows: \f047;
$fa-var-chalkboard-user: \f51c;
$fa-var-chalkboard-teacher: \f51c;
$fa-var-rhombus: \e23b;
$fa-var-claw-marks: \f6c2;
$fa-var-peso-sign: \e222;
$fa-var-face-smile-tongue: \e394;
$fa-var-cart-circle-xmark: \e3f4;
$fa-var-building-shield: \e4d8;
$fa-var-circle-phone-flip: \e11c;
$fa-var-phone-circle-alt: \e11c;
$fa-var-baby: \f77c;
$fa-var-users-line: \e592;
$fa-var-quote-left: \f10d;
$fa-var-quote-left-alt: \f10d;
$fa-var-tractor: \f722;
$fa-var-key-skeleton: \f6f3;
$fa-var-trash-arrow-up: \f829;
$fa-var-trash-restore: \f829;
$fa-var-arrow-down-up-lock: \e4b0;
$fa-var-arrow-down-to-bracket: \e094;
$fa-var-lines-leaning: \e51e;
$fa-var-square-q: \e27b;
$fa-var-ruler-combined: \f546;
$fa-var-symbols: \f86e;
$fa-var-icons-alt: \f86e;
$fa-var-copyright: \f1f9;
$fa-var-highlighter-line: \e1af;
$fa-var-bracket-square: \5b;
$fa-var-bracket: \5b;
$fa-var-bracket-left: \5b;
$fa-var-island-tropical: \f811;
$fa-var-island-tree-palm: \f811;
$fa-var-arrow-right-from-line: \f343;
$fa-var-arrow-from-left: \f343;
$fa-var-h2: \f314;
$fa-var-equals: \3d;
$fa-var-cake-slice: \e3e5;
$fa-var-shortcake: \e3e5;
$fa-var-peanut: \e430;
$fa-var-wrench-simple: \e2d1;
$fa-var-blender: \f517;
$fa-var-teeth: \f62e;
$fa-var-tally-2: \e295;
$fa-var-shekel-sign: \f20b;
$fa-var-ils: \f20b;
$fa-var-shekel: \f20b;
$fa-var-sheqel: \f20b;
$fa-var-sheqel-sign: \f20b;
$fa-var-cars: \f85b;
$fa-var-axe-battle: \f6b3;
$fa-var-user-hair-long: \e45b;
$fa-var-map: \f279;
$fa-var-file-circle-info: \e493;
$fa-var-face-disappointed: \e36f;
$fa-var-lasso-sparkles: \e1c9;
$fa-var-clock-eleven: \e347;
$fa-var-rocket: \f135;
$fa-var-siren-on: \e02e;
$fa-var-clock-ten: \e354;
$fa-var-candle-holder: \f6bc;
$fa-var-video-arrow-down-left: \e2c8;
$fa-var-photo-film: \f87c;
$fa-var-photo-video: \f87c;
$fa-var-floppy-disk-circle-arrow-right: \e180;
$fa-var-save-circle-arrow-right: \e180;
$fa-var-folder-minus: \f65d;
$fa-var-planet-moon: \e01f;
$fa-var-face-eyes-xmarks: \e374;
$fa-var-chart-scatter: \f7ee;
$fa-var-display-arrow-down: \e164;
$fa-var-store: \f54e;
$fa-var-arrow-trend-up: \e098;
$fa-var-plug-circle-minus: \e55e;
$fa-var-olive-branch: \e317;
$fa-var-angle: \e08c;
$fa-var-vacuum-robot: \e04e;
$fa-var-sign-hanging: \f4d9;
$fa-var-sign: \f4d9;
$fa-var-square-divide: \e26a;
$fa-var-signal-stream-slash: \e250;
$fa-var-bezier-curve: \f55b;
$fa-var-eye-dropper-half: \e173;
$fa-var-store-lock: \e4a6;
$fa-var-bell-slash: \f1f6;
$fa-var-cloud-bolt-sun: \f76e;
$fa-var-thunderstorm-sun: \f76e;
$fa-var-camera-slash: \e0d9;
$fa-var-comment-quote: \e14c;
$fa-var-tablet: \f3fb;
$fa-var-tablet-android: \f3fb;
$fa-var-school-flag: \e56e;
$fa-var-message-code: \e1df;
$fa-var-glass-half: \e192;
$fa-var-glass-half-empty: \e192;
$fa-var-glass-half-full: \e192;
$fa-var-fill: \f575;
$fa-var-message-minus: \f4a7;
$fa-var-comment-alt-minus: \f4a7;
$fa-var-angle-up: \f106;
$fa-var-drumstick-bite: \f6d7;
$fa-var-link-horizontal-slash: \e1cc;
$fa-var-chain-horizontal-slash: \e1cc;
$fa-var-holly-berry: \f7aa;
$fa-var-nose: \e5bd;
$fa-var-chevron-left: \f053;
$fa-var-bacteria: \e059;
$fa-var-clouds: \f744;
$fa-var-money-bill-simple: \e1f1;
$fa-var-hand-lizard: \f258;
$fa-var-table-pivot: \e291;
$fa-var-filter-slash: \e17d;
$fa-var-trash-can-undo: \f896;
$fa-var-trash-can-arrow-turn-left: \f896;
$fa-var-trash-undo-alt: \f896;
$fa-var-notdef: \e1fe;
$fa-var-disease: \f7fa;
$fa-var-person-to-door: \e433;
$fa-var-turntable: \f8e4;
$fa-var-briefcase-medical: \f469;
$fa-var-genderless: \f22d;
$fa-var-chevron-right: \f054;
$fa-var-signal-weak: \f68c;
$fa-var-signal-1: \f68c;
$fa-var-clock-five: \e349;
$fa-var-retweet: \f079;
$fa-var-car-rear: \f5de;
$fa-var-car-alt: \f5de;
$fa-var-pump-soap: \e06b;
$fa-var-computer-classic: \f8b1;
$fa-var-frame: \e495;
$fa-var-video-slash: \f4e2;
$fa-var-battery-quarter: \f243;
$fa-var-battery-2: \f243;
$fa-var-ellipsis-stroke: \f39b;
$fa-var-ellipsis-h-alt: \f39b;
$fa-var-radio: \f8d7;
$fa-var-baby-carriage: \f77d;
$fa-var-carriage-baby: \f77d;
$fa-var-face-expressionless: \e373;
$fa-var-down-to-dotted-line: \e408;
$fa-var-cloud-music: \f8ae;
$fa-var-traffic-light: \f637;
$fa-var-cloud-minus: \e35d;
$fa-var-thermometer: \f491;
$fa-var-shield-minus: \e249;
$fa-var-vr-cardboard: \f729;
$fa-var-car-tilt: \f5e5;
$fa-var-gauge-circle-minus: \e497;
$fa-var-brightness-low: \e0ca;
$fa-var-hand-middle-finger: \f806;
$fa-var-percent: \25;
$fa-var-percentage: \25;
$fa-var-truck-moving: \f4df;
$fa-var-glass-water-droplet: \e4f5;
$fa-var-conveyor-belt: \f46e;
$fa-var-location-check: \f606;
$fa-var-map-marker-check: \f606;
$fa-var-coin-vertical: \e3fd;
$fa-var-display: \e163;
$fa-var-person-sign: \f757;
$fa-var-face-smile: \f118;
$fa-var-smile: \f118;
$fa-var-phone-hangup: \e225;
$fa-var-signature-slash: \e3cb;
$fa-var-thumbtack: \f08d;
$fa-var-thumb-tack: \f08d;
$fa-var-wheat-slash: \e339;
$fa-var-trophy: \f091;
$fa-var-clouds-sun: \f746;
$fa-var-person-praying: \f683;
$fa-var-pray: \f683;
$fa-var-hammer: \f6e3;
$fa-var-face-vomit: \e3a0;
$fa-var-speakers: \f8e0;
$fa-var-tty-answer: \e2b9;
$fa-var-teletype-answer: \e2b9;
$fa-var-mug-tea-saucer: \e1f5;
$fa-var-diagram-lean-canvas: \e156;
$fa-var-alt: \e08a;
$fa-var-dial: \e15b;
$fa-var-dial-med-high: \e15b;
$fa-var-hand-peace: \f25b;
$fa-var-circle-trash: \e126;
$fa-var-trash-circle: \e126;
$fa-var-rotate: \f2f1;
$fa-var-sync-alt: \f2f1;
$fa-var-circle-quarters: \e3f8;
$fa-var-spinner: \f110;
$fa-var-tower-control: \e2a2;
$fa-var-arrow-up-triangle-square: \f88a;
$fa-var-sort-shapes-up: \f88a;
$fa-var-whale: \f72c;
$fa-var-robot: \f544;
$fa-var-peace: \f67c;
$fa-var-party-horn: \e31b;
$fa-var-gears: \f085;
$fa-var-cogs: \f085;
$fa-var-sun-bright: \e28f;
$fa-var-sun-alt: \e28f;
$fa-var-warehouse: \f494;
$fa-var-lock-keyhole-open: \f3c2;
$fa-var-lock-open-alt: \f3c2;
$fa-var-square-fragile: \f49b;
$fa-var-box-fragile: \f49b;
$fa-var-square-wine-glass-crack: \f49b;
$fa-var-arrow-up-right-dots: \e4b7;
$fa-var-square-n: \e277;
$fa-var-splotch: \f5bc;
$fa-var-face-grin-hearts: \f584;
$fa-var-grin-hearts: \f584;
$fa-var-meter: \e1e8;
$fa-var-mandolin: \f6f9;
$fa-var-dice-four: \f524;
$fa-var-sim-card: \f7c4;
$fa-var-transgender: \f225;
$fa-var-transgender-alt: \f225;
$fa-var-mercury: \f223;
$fa-var-up-from-bracket: \e590;
$fa-var-knife-kitchen: \f6f5;
$fa-var-border-right: \f852;
$fa-var-arrow-turn-down: \f149;
$fa-var-level-down: \f149;
$fa-var-spade: \f2f4;
$fa-var-card-spade: \e3ec;
$fa-var-line-columns: \f870;
$fa-var-arrow-right-to-line: \f340;
$fa-var-arrow-to-right: \f340;
$fa-var-person-falling-burst: \e547;
$fa-var-flag-pennant: \f456;
$fa-var-pennant: \f456;
$fa-var-conveyor-belt-empty: \e150;
$fa-var-award: \f559;
$fa-var-ticket-simple: \f3ff;
$fa-var-ticket-alt: \f3ff;
$fa-var-building: \f1ad;
$fa-var-angles-left: \f100;
$fa-var-angle-double-left: \f100;
$fa-var-camcorder: \f8a8;
$fa-var-video-handheld: \f8a8;
$fa-var-pancakes: \e42d;
$fa-var-album-circle-user: \e48d;
$fa-var-qrcode: \f029;
$fa-var-dice-d10: \f6cd;
$fa-var-fireplace: \f79a;
$fa-var-browser: \f37e;
$fa-var-pen-paintbrush: \f618;
$fa-var-pencil-paintbrush: \f618;
$fa-var-fish-cooked: \f7fe;
$fa-var-chair-office: \f6c1;
$fa-var-nesting-dolls: \e3ba;
$fa-var-clock-rotate-left: \f1da;
$fa-var-history: \f1da;
$fa-var-trumpet: \f8e3;
$fa-var-face-grin-beam-sweat: \f583;
$fa-var-grin-beam-sweat: \f583;
$fa-var-fire-smoke: \f74b;
$fa-var-phone-missed: \e226;
$fa-var-file-export: \f56e;
$fa-var-arrow-right-from-file: \f56e;
$fa-var-shield: \f132;
$fa-var-shield-blank: \f132;
$fa-var-arrow-up-short-wide: \f885;
$fa-var-sort-amount-up-alt: \f885;
$fa-var-arrows-repeat-1: \f366;
$fa-var-repeat-1-alt: \f366;
$fa-var-gun-slash: \e19c;
$fa-var-avocado: \e0aa;
$fa-var-binary: \e33b;
$fa-var-glasses-round: \f5f5;
$fa-var-glasses-alt: \f5f5;
$fa-var-phone-plus: \f4d2;
$fa-var-ditto: \22;
$fa-var-person-seat: \e21e;
$fa-var-house-medical: \e3b2;
$fa-var-golf-ball-tee: \f450;
$fa-var-golf-ball: \f450;
$fa-var-circle-chevron-left: \f137;
$fa-var-chevron-circle-left: \f137;
$fa-var-house-chimney-window: \e00d;
$fa-var-scythe: \f710;
$fa-var-pen-nib: \f5ad;
$fa-var-ban-parking: \f616;
$fa-var-parking-circle-slash: \f616;
$fa-var-tent-arrow-turn-left: \e580;
$fa-var-face-diagonal-mouth: \e47e;
$fa-var-diagram-cells: \e475;
$fa-var-cricket-bat-ball: \f449;
$fa-var-cricket: \f449;
$fa-var-tents: \e582;
$fa-var-wand-magic: \f0d0;
$fa-var-magic: \f0d0;
$fa-var-dog: \f6d3;
$fa-var-pen-line: \e212;
$fa-var-atom-simple: \f5d3;
$fa-var-atom-alt: \f5d3;
$fa-var-ampersand: \26;
$fa-var-carrot: \f787;
$fa-var-arrow-up-from-line: \f342;
$fa-var-arrow-from-bottom: \f342;
$fa-var-moon: \f186;
$fa-var-pen-slash: \e213;
$fa-var-wine-glass-empty: \f5ce;
$fa-var-wine-glass-alt: \f5ce;
$fa-var-square-star: \e27f;
$fa-var-cheese: \f7ef;
$fa-var-send-backward: \f87f;
$fa-var-yin-yang: \f6ad;
$fa-var-music: \f001;
$fa-var-compass-slash: \f5e9;
$fa-var-clock-one: \e34e;
$fa-var-file-music: \f8b6;
$fa-var-code-commit: \f386;
$fa-var-temperature-low: \f76b;
$fa-var-person-biking: \f84a;
$fa-var-biking: \f84a;
$fa-var-skeleton: \f620;
$fa-var-circle-g: \e10f;
$fa-var-circle-arrow-up-left: \e0fb;
$fa-var-coin-blank: \e3fb;
$fa-var-broom: \f51a;
$fa-var-vacuum: \e04d;
$fa-var-shield-heart: \e574;
$fa-var-card-heart: \e3eb;
$fa-var-lightbulb-cfl-on: \e5a7;
$fa-var-melon: \e310;
$fa-var-gopuram: \f664;
$fa-var-earth-oceania: \e47b;
$fa-var-globe-oceania: \e47b;
$fa-var-container-storage: \f4b7;
$fa-var-face-pouting: \e387;
$fa-var-square-xmark: \f2d3;
$fa-var-times-square: \f2d3;
$fa-var-xmark-square: \f2d3;
$fa-var-face-explode: \e2fe;
$fa-var-exploding-head: \e2fe;
$fa-var-hashtag: \23;
$fa-var-up-right-and-down-left-from-center: \f424;
$fa-var-expand-alt: \f424;
$fa-var-oil-can: \f613;
$fa-var-t: \54;
$fa-var-transformer-bolt: \e2a4;
$fa-var-hippo: \f6ed;
$fa-var-chart-column: \e0e3;
$fa-var-cassette-vhs: \f8ec;
$fa-var-vhs: \f8ec;
$fa-var-infinity: \f534;
$fa-var-vial-circle-check: \e596;
$fa-var-chimney: \f78b;
$fa-var-object-intersect: \e49d;
$fa-var-person-arrow-down-to-line: \e538;
$fa-var-voicemail: \f897;
$fa-var-block-brick: \e3db;
$fa-var-wall-brick: \e3db;
$fa-var-fan: \f863;
$fa-var-bags-shopping: \f847;
$fa-var-paragraph-left: \f878;
$fa-var-paragraph-rtl: \f878;
$fa-var-person-walking-luggage: \e554;
$fa-var-caravan-simple: \e000;
$fa-var-caravan-alt: \e000;
$fa-var-turtle: \f726;
$fa-var-up-down: \f338;
$fa-var-arrows-alt-v: \f338;
$fa-var-cloud-moon-rain: \f73c;
$fa-var-booth-curtain: \f734;
$fa-var-calendar: \f133;
$fa-var-box-heart: \f49d;
$fa-var-trailer: \e041;
$fa-var-user-doctor-message: \f82e;
$fa-var-user-md-chat: \f82e;
$fa-var-bahai: \f666;
$fa-var-haykal: \f666;
$fa-var-amp-guitar: \f8a1;
$fa-var-sd-card: \f7c2;
$fa-var-volume-slash: \f2e2;
$fa-var-border-bottom: \f84d;
$fa-var-wifi-weak: \f6aa;
$fa-var-wifi-1: \f6aa;
$fa-var-dragon: \f6d5;
$fa-var-shoe-prints: \f54b;
$fa-var-circle-plus: \f055;
$fa-var-plus-circle: \f055;
$fa-var-face-grin-tongue-wink: \f58b;
$fa-var-grin-tongue-wink: \f58b;
$fa-var-hand-holding: \f4bd;
$fa-var-plug-circle-exclamation: \e55d;
$fa-var-link-slash: \f127;
$fa-var-chain-broken: \f127;
$fa-var-chain-slash: \f127;
$fa-var-unlink: \f127;
$fa-var-clone: \f24d;
$fa-var-person-walking-arrow-loop-left: \e551;
$fa-var-arrow-up-z-a: \f882;
$fa-var-sort-alpha-up-alt: \f882;
$fa-var-fire-flame-curved: \f7e4;
$fa-var-fire-alt: \f7e4;
$fa-var-tornado: \f76f;
$fa-var-file-circle-plus: \e494;
$fa-var-delete-right: \e154;
$fa-var-book-quran: \f687;
$fa-var-quran: \f687;
$fa-var-circle-quarter: \e11f;
$fa-var-anchor: \f13d;
$fa-var-border-all: \f84c;
$fa-var-function: \f661;
$fa-var-face-angry: \f556;
$fa-var-angry: \f556;
$fa-var-people-simple: \e21b;
$fa-var-cookie-bite: \f564;
$fa-var-arrow-trend-down: \e097;
$fa-var-rss: \f09e;
$fa-var-feed: \f09e;
$fa-var-face-monocle: \e380;
$fa-var-draw-polygon: \f5ee;
$fa-var-scale-balanced: \f24e;
$fa-var-balance-scale: \f24e;
$fa-var-calendar-lines: \e0d5;
$fa-var-calendar-note: \e0d5;
$fa-var-arrow-down-big-small: \f88c;
$fa-var-sort-size-down: \f88c;
$fa-var-gauge-simple-high: \f62a;
$fa-var-tachometer: \f62a;
$fa-var-tachometer-fast: \f62a;
$fa-var-do-not-enter: \f5ec;
$fa-var-shower: \f2cc;
$fa-var-dice-d8: \f6d2;
$fa-var-desktop: \f390;
$fa-var-desktop-alt: \f390;
$fa-var-m: \4d;
$fa-var-grip-dots-vertical: \e411;
$fa-var-face-viewfinder: \e2ff;
$fa-var-soft-serve: \e400;
$fa-var-creemee: \e400;
$fa-var-h5: \e412;
$fa-var-hand-back-point-down: \e19e;
$fa-var-table-list: \f00b;
$fa-var-th-list: \f00b;
$fa-var-comment-sms: \f7cd;
$fa-var-sms: \f7cd;
$fa-var-rectangle: \f2fa;
$fa-var-rectangle-landscape: \f2fa;
$fa-var-clipboard-list-check: \f737;
$fa-var-turkey: \f725;
$fa-var-book: \f02d;
$fa-var-user-plus: \f234;
$fa-var-ice-skate: \f7ac;
$fa-var-check: \f00c;
$fa-var-battery-three-quarters: \f241;
$fa-var-battery-4: \f241;
$fa-var-tomato: \e330;
$fa-var-sword-laser: \e03b;
$fa-var-house-circle-check: \e509;
$fa-var-buildings: \e0cc;
$fa-var-angle-left: \f104;
$fa-var-cart-flatbed-boxes: \f475;
$fa-var-dolly-flatbed-alt: \f475;
$fa-var-diagram-successor: \e47a;
$fa-var-truck-arrow-right: \e58b;
$fa-var-square-w: \e285;
$fa-var-arrows-split-up-and-left: \e4bc;
$fa-var-lamp: \f4ca;
$fa-var-airplay: \e089;
$fa-var-hand-fist: \f6de;
$fa-var-fist-raised: \f6de;
$fa-var-shield-quartered: \e575;
$fa-var-slash-forward: \2f;
$fa-var-location-pen: \f607;
$fa-var-map-marker-edit: \f607;
$fa-var-cloud-moon: \f6c3;
$fa-var-pot-food: \e43f;
$fa-var-briefcase: \f0b1;
$fa-var-person-falling: \e546;
$fa-var-image-portrait: \f3e0;
$fa-var-portrait: \f3e0;
$fa-var-user-tag: \f507;
$fa-var-rug: \e569;
$fa-var-print-slash: \f686;
$fa-var-earth-europe: \f7a2;
$fa-var-globe-europe: \f7a2;
$fa-var-cart-flatbed-suitcase: \f59d;
$fa-var-luggage-cart: \f59d;
$fa-var-hand-back-point-ribbon: \e1a0;
$fa-var-rectangle-xmark: \f410;
$fa-var-rectangle-times: \f410;
$fa-var-times-rectangle: \f410;
$fa-var-window-close: \f410;
$fa-var-tire-rugged: \f634;
$fa-var-lightbulb-dollar: \f670;
$fa-var-cowbell: \f8b3;
$fa-var-baht-sign: \e0ac;
$fa-var-corner: \e3fe;
$fa-var-chevrons-right: \f324;
$fa-var-chevron-double-right: \f324;
$fa-var-book-open: \f518;
$fa-var-book-journal-whills: \f66a;
$fa-var-journal-whills: \f66a;
$fa-var-inhaler: \f5f9;
$fa-var-handcuffs: \e4f8;
$fa-var-snake: \f716;
$fa-var-triangle-exclamation: \f071;
$fa-var-exclamation-triangle: \f071;
$fa-var-warning: \f071;
$fa-var-note-medical: \e200;
$fa-var-database: \f1c0;
$fa-var-down-left: \e16a;
$fa-var-share: \f064;
$fa-var-arrow-turn-right: \f064;
$fa-var-mail-forward: \f064;
$fa-var-face-thinking: \e39b;
$fa-var-turn-down-right: \e455;
$fa-var-bottle-droplet: \e4c4;
$fa-var-mask-face: \e1d7;
$fa-var-hill-rockslide: \e508;
$fa-var-scanner-keyboard: \f489;
$fa-var-circle-o: \e119;
$fa-var-grid-horizontal: \e307;
$fa-var-message-dollar: \f650;
$fa-var-comment-alt-dollar: \f650;
$fa-var-right-left: \f362;
$fa-var-exchange-alt: \f362;
$fa-var-columns-3: \e361;
$fa-var-paper-plane: \f1d8;
$fa-var-road-circle-exclamation: \e565;
$fa-var-dungeon: \f6d9;
$fa-var-hand-holding-box: \f47b;
$fa-var-input-text: \e1bf;
$fa-var-window-flip: \f40f;
$fa-var-window-alt: \f40f;
$fa-var-align-right: \f038;
$fa-var-scanner-gun: \f488;
$fa-var-scanner: \f488;
$fa-var-tire: \f631;
$fa-var-engine: \e16e;
$fa-var-money-bill-1-wave: \f53b;
$fa-var-money-bill-wave-alt: \f53b;
$fa-var-life-ring: \f1cd;
$fa-var-hands: \f2a7;
$fa-var-sign-language: \f2a7;
$fa-var-signing: \f2a7;
$fa-var-circle-caret-right: \f330;
$fa-var-caret-circle-right: \f330;
$fa-var-wheat: \f72d;
$fa-var-file-spreadsheet: \f65b;
$fa-var-audio-description-slash: \e0a8;
$fa-var-calendar-day: \f783;
$fa-var-water-ladder: \f5c5;
$fa-var-ladder-water: \f5c5;
$fa-var-swimming-pool: \f5c5;
$fa-var-arrows-up-down: \f07d;
$fa-var-arrows-v: \f07d;
$fa-var-chess-pawn-piece: \f444;
$fa-var-chess-pawn-alt: \f444;
$fa-var-face-grimace: \f57f;
$fa-var-grimace: \f57f;
$fa-var-wheelchair-move: \e2ce;
$fa-var-wheelchair-alt: \e2ce;
$fa-var-turn-down: \f3be;
$fa-var-level-down-alt: \f3be;
$fa-var-square-s: \e27d;
$fa-var-rectangle-barcode: \f463;
$fa-var-barcode-alt: \f463;
$fa-var-person-walking-arrow-right: \e552;
$fa-var-square-envelope: \f199;
$fa-var-envelope-square: \f199;
$fa-var-dice: \f522;
$fa-var-unicorn: \f727;
$fa-var-bowling-ball: \f436;
$fa-var-pompebled: \e43d;
$fa-var-brain: \f5dc;
$fa-var-watch-smart: \e2cc;
$fa-var-book-user: \f7e7;
$fa-var-sensor-cloud: \e02c;
$fa-var-sensor-smoke: \e02c;
$fa-var-clapperboard-play: \e132;
$fa-var-bandage: \f462;
$fa-var-band-aid: \f462;
$fa-var-calendar-minus: \f272;
$fa-var-circle-xmark: \f057;
$fa-var-times-circle: \f057;
$fa-var-xmark-circle: \f057;
$fa-var-circle-4: \e0f1;
$fa-var-gifts: \f79c;
$fa-var-album-collection: \f8a0;
$fa-var-hotel: \f594;
$fa-var-earth-asia: \f57e;
$fa-var-globe-asia: \f57e;
$fa-var-id-card-clip: \f47f;
$fa-var-id-card-alt: \f47f;
$fa-var-magnifying-glass-plus: \f00e;
$fa-var-search-plus: \f00e;
$fa-var-thumbs-up: \f164;
$fa-var-cloud-showers: \f73f;
$fa-var-user-clock: \f4fd;
$fa-var-onion: \e427;
$fa-var-clock-twelve-thirty: \e359;
$fa-var-arrow-down-to-dotted-line: \e095;
$fa-var-hand-dots: \f461;
$fa-var-allergies: \f461;
$fa-var-file-invoice: \f570;
$fa-var-window-minimize: \f2d1;
$fa-var-rectangle-wide: \f2fc;
$fa-var-comment-arrow-up: \e144;
$fa-var-garlic: \e40e;
$fa-var-mug-saucer: \f0f4;
$fa-var-coffee: \f0f4;
$fa-var-brush: \f55d;
$fa-var-tree-decorated: \f7dc;
$fa-var-mask: \f6fa;
$fa-var-calendar-heart: \e0d3;
$fa-var-magnifying-glass-minus: \f010;
$fa-var-search-minus: \f010;
$fa-var-flower: \f7ff;
$fa-var-ruler-vertical: \f548;
$fa-var-user-large: \f406;
$fa-var-user-alt: \f406;
$fa-var-starship-freighter: \e03a;
$fa-var-train-tram: \e5b4;
$fa-var-bridge-suspension: \e4cd;
$fa-var-trash-check: \e2af;
$fa-var-user-nurse: \f82f;
$fa-var-boombox: \f8a5;
$fa-var-syringe: \f48e;
$fa-var-cloud-sun: \f6c4;
$fa-var-shield-exclamation: \e247;
$fa-var-stopwatch-20: \e06f;
$fa-var-square-full: \f45c;
$fa-var-grip-dots: \e410;
$fa-var-comment-exclamation: \f4af;
$fa-var-pen-swirl: \e214;
$fa-var-falafel: \e40a;
$fa-var-circle-2: \e0ef;
$fa-var-magnet: \f076;
$fa-var-jar: \e516;
$fa-var-gramophone: \f8bd;
$fa-var-dice-d12: \f6ce;
$fa-var-note-sticky: \f249;
$fa-var-sticky-note: \f249;
$fa-var-down: \f354;
$fa-var-arrow-alt-down: \f354;
$fa-var-hundred-points: \e41c;
$fa-var-100: \e41c;
$fa-var-paperclip-vertical: \e3c2;
$fa-var-wind-warning: \f776;
$fa-var-wind-circle-exclamation: \f776;
$fa-var-location-pin-slash: \f60c;
$fa-var-map-marker-slash: \f60c;
$fa-var-face-sad-sweat: \e38a;
$fa-var-bug-slash: \e490;
$fa-var-cupcake: \e402;
$fa-var-light-switch-off: \e018;
$fa-var-toggle-large-off: \e5b0;
$fa-var-pen-fancy-slash: \e210;
$fa-var-truck-container: \f4dc;
$fa-var-boot: \f782;
$fa-var-arrow-up-from-water-pump: \e4b6;
$fa-var-file-check: \f316;
$fa-var-bone: \f5d7;
$fa-var-cards-blank: \e4df;
$fa-var-circle-3: \e0f0;
$fa-var-bench-tree: \e2e7;
$fa-var-keyboard-brightness-low: \e1c1;
$fa-var-ski-boot-ski: \e3cd;
$fa-var-brain-circuit: \e0c6;
$fa-var-user-injured: \f728;
$fa-var-block-brick-fire: \e3dc;
$fa-var-firewall: \e3dc;
$fa-var-face-sad-tear: \f5b4;
$fa-var-sad-tear: \f5b4;
$fa-var-plane: \f072;
$fa-var-tent-arrows-down: \e581;
$fa-var-exclamation: \21;
$fa-var-arrows-spin: \e4bb;
$fa-var-face-smile-relaxed: \e392;
$fa-var-comment-xmark: \f4b5;
$fa-var-comment-times: \f4b5;
$fa-var-print: \f02f;
$fa-var-turkish-lira-sign: \e2bb;
$fa-var-try: \e2bb;
$fa-var-turkish-lira: \e2bb;
$fa-var-face-nose-steam: \e382;
$fa-var-circle-waveform-lines: \e12d;
$fa-var-waveform-circle: \e12d;
$fa-var-dollar-sign: \24;
$fa-var-dollar: \24;
$fa-var-usd: \24;
$fa-var-ferris-wheel: \e174;
$fa-var-computer-speaker: \f8b2;
$fa-var-skull-cow: \f8de;
$fa-var-x: \58;
$fa-var-magnifying-glass-dollar: \f688;
$fa-var-search-dollar: \f688;
$fa-var-users-gear: \f509;
$fa-var-users-cog: \f509;
$fa-var-person-military-pointing: \e54a;
$fa-var-building-columns: \f19c;
$fa-var-bank: \f19c;
$fa-var-institution: \f19c;
$fa-var-museum: \f19c;
$fa-var-university: \f19c;
$fa-var-circle-t: \e124;
$fa-var-sack: \f81c;
$fa-var-grid-2: \e196;
$fa-var-camera-cctv: \f8ac;
$fa-var-cctv: \f8ac;
$fa-var-umbrella: \f0e9;
$fa-var-trowel: \e589;
$fa-var-horizontal-rule: \f86c;
$fa-var-bed-front: \f8f7;
$fa-var-bed-alt: \f8f7;
$fa-var-d: \44;
$fa-var-stapler: \e5af;
$fa-var-masks-theater: \f630;
$fa-var-theater-masks: \f630;
$fa-var-kip-sign: \e1c4;
$fa-var-face-woozy: \e3a2;
$fa-var-cloud-question: \e492;
$fa-var-pineapple: \e31f;
$fa-var-hand-point-left: \f0a5;
$fa-var-gallery-thumbnails: \e3aa;
$fa-var-circle-j: \e112;
$fa-var-eyes: \e367;
$fa-var-handshake-simple: \f4c6;
$fa-var-handshake-alt: \f4c6;
$fa-var-page-caret-up: \e42a;
$fa-var-file-caret-up: \e42a;
$fa-var-jet-fighter: \f0fb;
$fa-var-fighter-jet: \f0fb;
$fa-var-comet: \e003;
$fa-var-square-share-nodes: \f1e1;
$fa-var-share-alt-square: \f1e1;
$fa-var-shield-keyhole: \e248;
$fa-var-barcode: \f02a;
$fa-var-plus-minus: \e43c;
$fa-var-square-sliders-vertical: \f3f2;
$fa-var-sliders-v-square: \f3f2;
$fa-var-video: \f03d;
$fa-var-video-camera: \f03d;
$fa-var-message-middle: \e1e1;
$fa-var-comment-middle-alt: \e1e1;
$fa-var-graduation-cap: \f19d;
$fa-var-mortar-board: \f19d;
$fa-var-hand-holding-medical: \e05c;
$fa-var-person-circle-check: \e53e;
$fa-var-square-z: \e288;
$fa-var-message-text: \e1e6;
$fa-var-comment-alt-text: \e1e6;
$fa-var-turn-up: \f3bf;
$fa-var-level-up-alt: \f3bf;

$fa-var-monero: \f3d0;
$fa-var-hooli: \f427;
$fa-var-yelp: \f1e9;
$fa-var-cc-visa: \f1f0;
$fa-var-lastfm: \f202;
$fa-var-shopware: \f5b5;
$fa-var-creative-commons-nc: \f4e8;
$fa-var-aws: \f375;
$fa-var-redhat: \f7bc;
$fa-var-yoast: \f2b1;
$fa-var-cloudflare: \e07d;
$fa-var-ups: \f7e0;
$fa-var-wpexplorer: \f2de;
$fa-var-dyalog: \f399;
$fa-var-bity: \f37a;
$fa-var-stackpath: \f842;
$fa-var-buysellads: \f20d;
$fa-var-first-order: \f2b0;
$fa-var-modx: \f285;
$fa-var-guilded: \e07e;
$fa-var-vnv: \f40b;
$fa-var-square-js: \f3b9;
$fa-var-js-square: \f3b9;
$fa-var-microsoft: \f3ca;
$fa-var-qq: \f1d6;
$fa-var-orcid: \f8d2;
$fa-var-java: \f4e4;
$fa-var-invision: \f7b0;
$fa-var-creative-commons-pd-alt: \f4ed;
$fa-var-centercode: \f380;
$fa-var-glide-g: \f2a6;
$fa-var-drupal: \f1a9;
$fa-var-hire-a-helper: \f3b0;
$fa-var-creative-commons-by: \f4e7;
$fa-var-unity: \e049;
$fa-var-whmcs: \f40d;
$fa-var-rocketchat: \f3e8;
$fa-var-vk: \f189;
$fa-var-untappd: \f405;
$fa-var-mailchimp: \f59e;
$fa-var-css3-alt: \f38b;
$fa-var-square-reddit: \f1a2;
$fa-var-reddit-square: \f1a2;
$fa-var-vimeo-v: \f27d;
$fa-var-contao: \f26d;
$fa-var-square-font-awesome: \e5ad;
$fa-var-deskpro: \f38f;
$fa-var-sistrix: \f3ee;
$fa-var-square-instagram: \e055;
$fa-var-instagram-square: \e055;
$fa-var-battle-net: \f835;
$fa-var-the-red-yeti: \f69d;
$fa-var-square-hacker-news: \f3af;
$fa-var-hacker-news-square: \f3af;
$fa-var-edge: \f282;
$fa-var-napster: \f3d2;
$fa-var-square-snapchat: \f2ad;
$fa-var-snapchat-square: \f2ad;
$fa-var-google-plus-g: \f0d5;
$fa-var-artstation: \f77a;
$fa-var-markdown: \f60f;
$fa-var-sourcetree: \f7d3;
$fa-var-google-plus: \f2b3;
$fa-var-diaspora: \f791;
$fa-var-foursquare: \f180;
$fa-var-stack-overflow: \f16c;
$fa-var-github-alt: \f113;
$fa-var-phoenix-squadron: \f511;
$fa-var-pagelines: \f18c;
$fa-var-algolia: \f36c;
$fa-var-red-river: \f3e3;
$fa-var-creative-commons-sa: \f4ef;
$fa-var-safari: \f267;
$fa-var-google: \f1a0;
$fa-var-square-font-awesome-stroke: \f35c;
$fa-var-font-awesome-alt: \f35c;
$fa-var-atlassian: \f77b;
$fa-var-linkedin-in: \f0e1;
$fa-var-digital-ocean: \f391;
$fa-var-nimblr: \f5a8;
$fa-var-chromecast: \f838;
$fa-var-evernote: \f839;
$fa-var-hacker-news: \f1d4;
$fa-var-creative-commons-sampling: \f4f0;
$fa-var-adversal: \f36a;
$fa-var-creative-commons: \f25e;
$fa-var-watchman-monitoring: \e087;
$fa-var-fonticons: \f280;
$fa-var-weixin: \f1d7;
$fa-var-shirtsinbulk: \f214;
$fa-var-codepen: \f1cb;
$fa-var-git-alt: \f841;
$fa-var-lyft: \f3c3;
$fa-var-rev: \f5b2;
$fa-var-windows: \f17a;
$fa-var-wizards-of-the-coast: \f730;
$fa-var-square-viadeo: \f2aa;
$fa-var-viadeo-square: \f2aa;
$fa-var-meetup: \f2e0;
$fa-var-centos: \f789;
$fa-var-adn: \f170;
$fa-var-cloudsmith: \f384;
$fa-var-pied-piper-alt: \f1a8;
$fa-var-square-dribbble: \f397;
$fa-var-dribbble-square: \f397;
$fa-var-codiepie: \f284;
$fa-var-node: \f419;
$fa-var-mix: \f3cb;
$fa-var-steam: \f1b6;
$fa-var-cc-apple-pay: \f416;
$fa-var-scribd: \f28a;
$fa-var-openid: \f19b;
$fa-var-instalod: \e081;
$fa-var-expeditedssl: \f23e;
$fa-var-sellcast: \f2da;
$fa-var-square-twitter: \f081;
$fa-var-twitter-square: \f081;
$fa-var-r-project: \f4f7;
$fa-var-delicious: \f1a5;
$fa-var-freebsd: \f3a4;
$fa-var-vuejs: \f41f;
$fa-var-accusoft: \f369;
$fa-var-ioxhost: \f208;
$fa-var-fonticons-fi: \f3a2;
$fa-var-app-store: \f36f;
$fa-var-cc-mastercard: \f1f1;
$fa-var-itunes-note: \f3b5;
$fa-var-golang: \e40f;
$fa-var-kickstarter: \f3bb;
$fa-var-grav: \f2d6;
$fa-var-weibo: \f18a;
$fa-var-uncharted: \e084;
$fa-var-firstdraft: \f3a1;
$fa-var-square-youtube: \f431;
$fa-var-youtube-square: \f431;
$fa-var-wikipedia-w: \f266;
$fa-var-wpressr: \f3e4;
$fa-var-rendact: \f3e4;
$fa-var-angellist: \f209;
$fa-var-galactic-republic: \f50c;
$fa-var-nfc-directional: \e530;
$fa-var-skype: \f17e;
$fa-var-joget: \f3b7;
$fa-var-fedora: \f798;
$fa-var-stripe-s: \f42a;
$fa-var-meta: \e49b;
$fa-var-laravel: \f3bd;
$fa-var-hotjar: \f3b1;
$fa-var-bluetooth-b: \f294;
$fa-var-sticker-mule: \f3f7;
$fa-var-creative-commons-zero: \f4f3;
$fa-var-hips: \f452;
$fa-var-behance: \f1b4;
$fa-var-reddit: \f1a1;
$fa-var-discord: \f392;
$fa-var-chrome: \f268;
$fa-var-app-store-ios: \f370;
$fa-var-cc-discover: \f1f2;
$fa-var-wpbeginner: \f297;
$fa-var-confluence: \f78d;
$fa-var-mdb: \f8ca;
$fa-var-dochub: \f394;
$fa-var-accessible-icon: \f368;
$fa-var-ebay: \f4f4;
$fa-var-amazon: \f270;
$fa-var-unsplash: \e07c;
$fa-var-yarn: \f7e3;
$fa-var-square-steam: \f1b7;
$fa-var-steam-square: \f1b7;
$fa-var-500px: \f26e;
$fa-var-square-vimeo: \f194;
$fa-var-vimeo-square: \f194;
$fa-var-asymmetrik: \f372;
$fa-var-font-awesome: \f2b4;
$fa-var-font-awesome-flag: \f2b4;
$fa-var-font-awesome-logo-full: \f2b4;
$fa-var-gratipay: \f184;
$fa-var-apple: \f179;
$fa-var-hive: \e07f;
$fa-var-gitkraken: \f3a6;
$fa-var-keybase: \f4f5;
$fa-var-apple-pay: \f415;
$fa-var-padlet: \e4a0;
$fa-var-amazon-pay: \f42c;
$fa-var-square-github: \f092;
$fa-var-github-square: \f092;
$fa-var-stumbleupon: \f1a4;
$fa-var-fedex: \f797;
$fa-var-phoenix-framework: \f3dc;
$fa-var-shopify: \e057;
$fa-var-neos: \f612;
$fa-var-hackerrank: \f5f7;
$fa-var-researchgate: \f4f8;
$fa-var-swift: \f8e1;
$fa-var-angular: \f420;
$fa-var-speakap: \f3f3;
$fa-var-angrycreative: \f36e;
$fa-var-y-combinator: \f23b;
$fa-var-empire: \f1d1;
$fa-var-envira: \f299;
$fa-var-square-gitlab: \e5ae;
$fa-var-gitlab-square: \e5ae;
$fa-var-studiovinari: \f3f8;
$fa-var-pied-piper: \f2ae;
$fa-var-wordpress: \f19a;
$fa-var-product-hunt: \f288;
$fa-var-firefox: \f269;
$fa-var-linode: \f2b8;
$fa-var-goodreads: \f3a8;
$fa-var-square-odnoklassniki: \f264;
$fa-var-odnoklassniki-square: \f264;
$fa-var-jsfiddle: \f1cc;
$fa-var-sith: \f512;
$fa-var-themeisle: \f2b2;
$fa-var-page4: \f3d7;
$fa-var-hashnode: \e499;
$fa-var-react: \f41b;
$fa-var-cc-paypal: \f1f4;
$fa-var-squarespace: \f5be;
$fa-var-cc-stripe: \f1f5;
$fa-var-creative-commons-share: \f4f2;
$fa-var-bitcoin: \f379;
$fa-var-keycdn: \f3ba;
$fa-var-opera: \f26a;
$fa-var-itch-io: \f83a;
$fa-var-umbraco: \f8e8;
$fa-var-galactic-senate: \f50d;
$fa-var-ubuntu: \f7df;
$fa-var-draft2digital: \f396;
$fa-var-stripe: \f429;
$fa-var-houzz: \f27c;
$fa-var-gg: \f260;
$fa-var-dhl: \f790;
$fa-var-square-pinterest: \f0d3;
$fa-var-pinterest-square: \f0d3;
$fa-var-xing: \f168;
$fa-var-blackberry: \f37b;
$fa-var-creative-commons-pd: \f4ec;
$fa-var-playstation: \f3df;
$fa-var-quinscape: \f459;
$fa-var-less: \f41d;
$fa-var-blogger-b: \f37d;
$fa-var-opencart: \f23d;
$fa-var-vine: \f1ca;
$fa-var-paypal: \f1ed;
$fa-var-gitlab: \f296;
$fa-var-typo3: \f42b;
$fa-var-reddit-alien: \f281;
$fa-var-yahoo: \f19e;
$fa-var-dailymotion: \e052;
$fa-var-affiliatetheme: \f36b;
$fa-var-pied-piper-pp: \f1a7;
$fa-var-bootstrap: \f836;
$fa-var-odnoklassniki: \f263;
$fa-var-nfc-symbol: \e531;
$fa-var-ethereum: \f42e;
$fa-var-speaker-deck: \f83c;
$fa-var-creative-commons-nc-eu: \f4e9;
$fa-var-patreon: \f3d9;
$fa-var-avianex: \f374;
$fa-var-ello: \f5f1;
$fa-var-gofore: \f3a7;
$fa-var-bimobject: \f378;
$fa-var-facebook-f: \f39e;
$fa-var-square-google-plus: \f0d4;
$fa-var-google-plus-square: \f0d4;
$fa-var-mandalorian: \f50f;
$fa-var-first-order-alt: \f50a;
$fa-var-osi: \f41a;
$fa-var-google-wallet: \f1ee;
$fa-var-d-and-d-beyond: \f6ca;
$fa-var-periscope: \f3da;
$fa-var-fulcrum: \f50b;
$fa-var-cloudscale: \f383;
$fa-var-forumbee: \f211;
$fa-var-mizuni: \f3cc;
$fa-var-schlix: \f3ea;
$fa-var-square-xing: \f169;
$fa-var-xing-square: \f169;
$fa-var-bandcamp: \f2d5;
$fa-var-wpforms: \f298;
$fa-var-cloudversify: \f385;
$fa-var-usps: \f7e1;
$fa-var-megaport: \f5a3;
$fa-var-magento: \f3c4;
$fa-var-spotify: \f1bc;
$fa-var-optin-monster: \f23c;
$fa-var-fly: \f417;
$fa-var-aviato: \f421;
$fa-var-itunes: \f3b4;
$fa-var-cuttlefish: \f38c;
$fa-var-blogger: \f37c;
$fa-var-flickr: \f16e;
$fa-var-viber: \f409;
$fa-var-soundcloud: \f1be;
$fa-var-digg: \f1a6;
$fa-var-tencent-weibo: \f1d5;
$fa-var-symfony: \f83d;
$fa-var-maxcdn: \f136;
$fa-var-etsy: \f2d7;
$fa-var-facebook-messenger: \f39f;
$fa-var-audible: \f373;
$fa-var-think-peaks: \f731;
$fa-var-bilibili: \e3d9;
$fa-var-erlang: \f39d;
$fa-var-cotton-bureau: \f89e;
$fa-var-dashcube: \f210;
$fa-var-42-group: \e080;
$fa-var-innosoft: \e080;
$fa-var-stack-exchange: \f18d;
$fa-var-elementor: \f430;
$fa-var-square-pied-piper: \e01e;
$fa-var-pied-piper-square: \e01e;
$fa-var-creative-commons-nd: \f4eb;
$fa-var-palfed: \f3d8;
$fa-var-superpowers: \f2dd;
$fa-var-resolving: \f3e7;
$fa-var-xbox: \f412;
$fa-var-searchengin: \f3eb;
$fa-var-tiktok: \e07b;
$fa-var-square-facebook: \f082;
$fa-var-facebook-square: \f082;
$fa-var-renren: \f18b;
$fa-var-linux: \f17c;
$fa-var-glide: \f2a5;
$fa-var-linkedin: \f08c;
$fa-var-hubspot: \f3b2;
$fa-var-deploydog: \f38e;
$fa-var-twitch: \f1e8;
$fa-var-ravelry: \f2d9;
$fa-var-mixer: \e056;
$fa-var-square-lastfm: \f203;
$fa-var-lastfm-square: \f203;
$fa-var-vimeo: \f40a;
$fa-var-mendeley: \f7b3;
$fa-var-uniregistry: \f404;
$fa-var-figma: \f799;
$fa-var-creative-commons-remix: \f4ee;
$fa-var-cc-amazon-pay: \f42d;
$fa-var-dropbox: \f16b;
$fa-var-instagram: \f16d;
$fa-var-cmplid: \e360;
$fa-var-facebook: \f09a;
$fa-var-gripfire: \f3ac;
$fa-var-jedi-order: \f50e;
$fa-var-uikit: \f403;
$fa-var-fort-awesome-alt: \f3a3;
$fa-var-phabricator: \f3db;
$fa-var-ussunnah: \f407;
$fa-var-earlybirds: \f39a;
$fa-var-trade-federation: \f513;
$fa-var-autoprefixer: \f41c;
$fa-var-whatsapp: \f232;
$fa-var-slideshare: \f1e7;
$fa-var-google-play: \f3ab;
$fa-var-viadeo: \f2a9;
$fa-var-line: \f3c0;
$fa-var-google-drive: \f3aa;
$fa-var-servicestack: \f3ec;
$fa-var-simplybuilt: \f215;
$fa-var-bitbucket: \f171;
$fa-var-imdb: \f2d8;
$fa-var-deezer: \e077;
$fa-var-raspberry-pi: \f7bb;
$fa-var-jira: \f7b1;
$fa-var-docker: \f395;
$fa-var-screenpal: \e570;
$fa-var-bluetooth: \f293;
$fa-var-gitter: \f426;
$fa-var-d-and-d: \f38d;
$fa-var-microblog: \e01a;
$fa-var-cc-diners-club: \f24c;
$fa-var-gg-circle: \f261;
$fa-var-pied-piper-hat: \f4e5;
$fa-var-kickstarter-k: \f3bc;
$fa-var-yandex: \f413;
$fa-var-readme: \f4d5;
$fa-var-html5: \f13b;
$fa-var-sellsy: \f213;
$fa-var-sass: \f41e;
$fa-var-wirsindhandwerk: \e2d0;
$fa-var-wsh: \e2d0;
$fa-var-buromobelexperte: \f37f;
$fa-var-salesforce: \f83b;
$fa-var-octopus-deploy: \e082;
$fa-var-medapps: \f3c6;
$fa-var-ns8: \f3d5;
$fa-var-pinterest-p: \f231;
$fa-var-apper: \f371;
$fa-var-fort-awesome: \f286;
$fa-var-waze: \f83f;
$fa-var-cc-jcb: \f24b;
$fa-var-snapchat: \f2ab;
$fa-var-snapchat-ghost: \f2ab;
$fa-var-fantasy-flight-games: \f6dc;
$fa-var-rust: \e07a;
$fa-var-wix: \f5cf;
$fa-var-square-behance: \f1b5;
$fa-var-behance-square: \f1b5;
$fa-var-supple: \f3f9;
$fa-var-rebel: \f1d0;
$fa-var-css3: \f13c;
$fa-var-staylinked: \f3f5;
$fa-var-kaggle: \f5fa;
$fa-var-space-awesome: \e5ac;
$fa-var-deviantart: \f1bd;
$fa-var-cpanel: \f388;
$fa-var-goodreads-g: \f3a9;
$fa-var-square-git: \f1d2;
$fa-var-git-square: \f1d2;
$fa-var-square-tumblr: \f174;
$fa-var-tumblr-square: \f174;
$fa-var-trello: \f181;
$fa-var-creative-commons-nc-jp: \f4ea;
$fa-var-get-pocket: \f265;
$fa-var-perbyte: \e083;
$fa-var-grunt: \f3ad;
$fa-var-weebly: \f5cc;
$fa-var-connectdevelop: \f20e;
$fa-var-leanpub: \f212;
$fa-var-black-tie: \f27e;
$fa-var-themeco: \f5c6;
$fa-var-python: \f3e2;
$fa-var-android: \f17b;
$fa-var-bots: \e340;
$fa-var-free-code-camp: \f2c5;
$fa-var-hornbill: \f592;
$fa-var-js: \f3b8;
$fa-var-ideal: \e013;
$fa-var-git: \f1d3;
$fa-var-dev: \f6cc;
$fa-var-sketch: \f7c6;
$fa-var-yandex-international: \f414;
$fa-var-cc-amex: \f1f3;
$fa-var-uber: \f402;
$fa-var-github: \f09b;
$fa-var-php: \f457;
$fa-var-alipay: \f642;
$fa-var-youtube: \f167;
$fa-var-skyatlas: \f216;
$fa-var-firefox-browser: \e007;
$fa-var-replyd: \f3e6;
$fa-var-suse: \f7d6;
$fa-var-jenkins: \f3b6;
$fa-var-twitter: \f099;
$fa-var-rockrms: \f3e9;
$fa-var-pinterest: \f0d2;
$fa-var-buffer: \f837;
$fa-var-npm: \f3d4;
$fa-var-yammer: \f840;
$fa-var-btc: \f15a;
$fa-var-dribbble: \f17d;
$fa-var-stumbleupon-circle: \f1a3;
$fa-var-internet-explorer: \f26b;
$fa-var-stubber: \e5c7;
$fa-var-telegram: \f2c6;
$fa-var-telegram-plane: \f2c6;
$fa-var-old-republic: \f510;
$fa-var-odysee: \e5c6;
$fa-var-square-whatsapp: \f40c;
$fa-var-whatsapp-square: \f40c;
$fa-var-node-js: \f3d3;
$fa-var-edge-legacy: \e078;
$fa-var-slack: \f198;
$fa-var-slack-hash: \f198;
$fa-var-medrt: \f3c8;
$fa-var-usb: \f287;
$fa-var-tumblr: \f173;
$fa-var-vaadin: \f408;
$fa-var-quora: \f2c4;
$fa-var-reacteurope: \f75d;
$fa-var-medium: \f23a;
$fa-var-medium-m: \f23a;
$fa-var-amilia: \f36d;
$fa-var-mixcloud: \f289;
$fa-var-flipboard: \f44d;
$fa-var-viacoin: \f237;
$fa-var-critical-role: \f6c9;
$fa-var-sitrox: \e44a;
$fa-var-discourse: \f393;
$fa-var-joomla: \f1aa;
$fa-var-mastodon: \f4f6;
$fa-var-airbnb: \f834;
$fa-var-wolf-pack-battalion: \f514;
$fa-var-buy-n-large: \f8a6;
$fa-var-gulp: \f3ae;
$fa-var-creative-commons-sampling-plus: \f4f1;
$fa-var-strava: \f428;
$fa-var-ember: \f423;
$fa-var-canadian-maple-leaf: \f785;
$fa-var-teamspeak: \f4f9;
$fa-var-pushed: \f3e1;
$fa-var-wordpress-simple: \f411;
$fa-var-nutritionix: \f3d6;
$fa-var-wodu: \e088;
$fa-var-google-pay: \e079;
$fa-var-intercom: \f7af;
$fa-var-zhihu: \f63f;
$fa-var-korvue: \f42f;
$fa-var-pix: \e43a;
$fa-var-steam-symbol: \f3f6;
