@import '../style/colors';
@import '../style/typography';

:local(.alert) {
  border: 2px solid;
  border-radius: 6px;
  font-size: scaled(15px);
  display: flex;
  align-items: center;
}

:local(.alertTitle) {
  @include fontMedium;
  display: block;
  font-size: scaled(16px);
  line-height: 1;
  text-align: left;
  margin-top: 3px;
}

:local(.alertMessage) {
  @include fontLight;
}

:local(.alertIcon) {
  border: 2px solid;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box !important;
  flex-shrink: 0;
  font-size: scaled(11px);
}

:local(.alertContent) {
  padding-left: scaled(16px);
  color: $color-grey-100;
  text-align: left;
  flex: 1;

  a {
    @include fontBold;

    color: $color-black-100;
    display: inline-block;
    text-decoration-line: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
    line-height: scaled(13px);

    &:hover {
      color: $color-black-70;
    }

    &:hover,
    &:focus {
      text-decoration-thickness: 2px;
    }
  }
}

:local(.info) {
  border-color: $color-black-100;

  :local(.alertIcon) {
    border-color: $color-black-100;
    box-shadow: 0 0 0 4px rgba($color-black-100, 0.25);
    color: $color-black-100;
  }
}

:local(.error) {
  border-color: $color-negative;

  :local(.alertIcon) {
    border-color: $color-negative;
    box-shadow: 0 0 0 4px rgba($color-negative, 0.4);
    color: $color-negative;
  }
}

:local(.warning) {
  border-color: $color-warning;

  :local(.alertIcon) {
    border-color: $color-warning;
    box-shadow: 0 0 0 4px rgba($color-warning, 0.4);
    color: $color-warning;
  }
}

:local(.success) {
  border-color: $color-positive;

  :local(.alertIcon) {
    border-color: $color-positive;
    box-shadow: 0 0 0 4px rgba($color-positive, 0.4);
    color: $color-positive;
  }
}

:local(.dense) {
  padding: scaled(0.35rem) scaled(1rem);
}

:local(.dense) :local(.alertIcon) {
  border-width: 1px;
  width: scaled(12px);
  height: scaled(12px);
  font-size: scaled(8px);
}

:local(.dense) :local(.alertTitle) {
  font-size: scaled(14px);
  margin-bottom: scaled(4px);
}

:local(.dense) :local(.alertContent) {
  line-height: 1.2;
}

:local(.expanded) {
  padding: scaled(1rem);
}

:local(.expanded) :local(.alertIcon) {
  border-width: 2px;
  width: scaled(18px);
  height: scaled(18px);
  font-size: scaled(11px);
}

:local(.expanded) :local(.alertTitle) {
  margin-bottom: scaled(10px);
}

:local(.expanded) :local(.alertContent) {
  line-height: 1.7;
}

:local(.default) {
  background: white;
}

:local(.inherit) {
  background-color: inherit;
}
