@import '../style/colors';

:local(.wrapper) {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  width: 22px;
  height: 22px;
  animation: smoothLoader;
}

:local(.circleWrapper) {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  :local(.circle) {
    display: block;
    margin: 0 auto;
    background-color: $color-grey-20;
    animation: sk-circleFadeDelay 1.2s ease-in-out infinite;
    animation-fill-mode: both;
    border-radius: 50%;
  }

  &.dark {
    :local(.circle) {
      background-color: $color-grey-100;
    }
  }

  &.circle2 {
    :local(.circle) {
      animation-delay: -1.1s;
    }
    transform: rotate(30deg);
  }
  &.circle3 {
    :local(.circle) {
      animation-delay: -1s;
    }
    transform: rotate(60deg);
  }
  &.circle4 {
    :local(.circle) {
      animation-delay: -0.9s;
    }
    transform: rotate(90deg);
  }
  &.circle5 {
    :local(.circle) {
      animation-delay: -0.8s;
    }
    transform: rotate(120deg);
  }
  &.circle6 {
    :local(.circle) {
      animation-delay: -0.7s;
    }
    transform: rotate(150deg);
  }
  &.circle7 {
    :local(.circle) {
      animation-delay: -0.6s;
    }
    transform: rotate(180deg);
  }
  &.circle8 {
    :local(.circle) {
      animation-delay: -0.5s;
    }
    transform: rotate(210deg);
  }
  &.circle9 {
    :local(.circle) {
      animation-delay: -0.4s;
    }
    transform: rotate(240deg);
  }
  &.circle10 {
    :local(.circle) {
      animation-delay: -0.3s;
    }
    transform: rotate(270deg);
  }
  &.circle11 {
    :local(.circle) {
      animation-delay: -0.2s;
    }
    transform: rotate(300deg);
  }
  &.circle12 {
    :local(.circle) {
      animation-delay: -0.1s;
    }
    transform: rotate(330deg);
  }
}

@keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

// From https://github.com/tobiasahlin/SpinKit/blob/master/css/spinners/10-fading-circle.css
@keyframes smoothLoader {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
