@import '../style/colors';
@import '../style/gecko-font/gecko-font';

:local(.muted) {
  opacity: 0.5;
}

:local(.light) {
  color: #fff;
}

:local(.default) {
  color: inherit;
}
