@import '../../../src/ui/style/colors';
@import '../../../src/ui/style/typography';
@import '../../../style/font/visuelt/include';
@import '../../../style/vendor/font-awesome-variables';

.blob {
  position: absolute;
  top: 0;
  left: -16px;
  height: 48px;
  display: flex;
  align-items: center;

  &::after {
    position: absolute;
    left: 12px;
    top: 12px;
    @include fontAwesome;
    content: '\f0e0';
    color: $color-black-100;
    font-size: 20px;
    font-weight: 900;
  }
}

.passwordBlob {
  position: absolute;
  top: 0;
  left: -16px;
  height: 48px;
  display: flex;
  align-items: center;

  &::after {
    position: absolute;
    left: 12px;
    top: 12px;
    @include fontAwesome;
    content: '\f084';
    color: $color-black-100;
    font-size: 20px;
    font-weight: 900;
  }
}

.formField {
  position: relative;
  margin-bottom: 1.5rem;
}

.emailInput,
.passwordInput {
  @include fontMedium;

  margin: 0;
  padding: 1rem 1rem 1rem 2.5rem;
  height: 48px;
  width: 100%;
  background: #ffffff;
  border: 2px solid $color-black-100;
  border-radius: 10px;
  color: $color-black-100;
  font-size: 1rem;
  appearance: none;

  &:focus {
    border-color: $color-geckoboard-blue;
    outline: none;
  }
}

.extraFields {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.rememberMe {
  position: relative;
  float: left;
  color: #16113a;
  font-size: 1rem;
  cursor: pointer;
  padding-left: 32px;

  label {
    padding: 1px 4px 0 0;
    cursor: pointer;
    display: inline-block;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input:focus ~ :global(.checkbox) {
    border-color: $color-geckoboard-blue;
  }

  input:checked ~ :global(.checkbox::after) {
    @include fontAwesome;
    content: '\f00c';
    position: absolute;
    top: 2px;
    left: 2px;
    color: $color-geckoboard-green;
    font-size: 12px;
    font-weight: 900;
  }

  :global(.checkbox) {
    position: absolute;
    width: 20px;
    height: 20px;
    border: 2px solid #16113a;
    left: 0;
    top: 0;
    border-radius: 4px;
  }
}

.forgotLink {
  font-size: 1rem;
  float: right;

  a {
    transition-property: color, border-bottom-color;
    transition-duration: 0.2s;

    text-decoration: underline;
    border-bottom: none;
    color: $color-black-100;

    &:focus,
    &:hover {
      color: darken($color-geckoboard-green, 10%);
      outline: none;
    }
  }
}

.ssoSection {
  position: relative;
  margin-top: 1.25rem;
  padding-top: 1.25rem;
  border-top: 1px solid #dfe4ee;

  &::after {
    content: 'or';
    background-color: #ffffff;
    position: absolute;
    top: -9px;
    left: 50%;
    padding: 0 16px;
    margin-left: -26px;
    font-size: 1rem;
  }

  a {
    display: block;
    border-radius: 6px;
    width: 100%;
    padding: 9px 16px;
    margin-bottom: 0px;
    font-size: 1rem;
    line-height: normal;
    text-align: center;
    text-decoration: none;
    color: #16113a;
    background-color: #dfe4ee;

    &:hover,
    &:focus,
    &:active {
      background: #bec8dc;
      outline: none;
    }
  }
}

.noticeMessage {
  margin-bottom: 1rem;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    @include fontAwesome;
    content: fa-content($fa-var-check-circle);
    font-size: 16px;
    margin-right: 0.5rem;
    color: $color-geckoboard-green;
  }
}

.errorMessage {
  padding-bottom: 2rem;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    @include fontAwesome;
    content: fa-content($fa-var-exclamation-triangle);
    font-size: 1rem;
    margin-right: 0.5rem;
    color: $color-negative;
  }

  a {
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      opacity: 0.75;
    }
  }
}
