@import '../../../src/ui/style/colors';
@import '../../../src/ui/style/typography';
@import '../../../style/font/visuelt/include';
@import '../../../style/vendor/font-awesome-variables';

.formField {
  position: relative;
  margin-bottom: 1.5rem;
}

.nameInput {
  @include fontMedium;

  margin: 0;
  padding: 1rem;
  height: 48px;
  width: 100%;
  background: #ffffff;
  border: 2px solid $color-black-100;
  border-radius: 10px;
  color: $color-black-100;
  font-size: 1rem;
  appearance: none;

  &:focus {
    border-color: $color-geckoboard-blue;
    outline: none;
  }
}

.termsLink {
  text-decoration: underline !important;
  color: black;
}

.footerContent {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
