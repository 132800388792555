/* This is a duplicate of a file that also exists in polecat,
 * so if changes are made, both must be updated
 */

/*
 * Gecko-Font - version 2.1
 */
@import "variables";

:local(.gecko-font){
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'GeckoFont' !important;
  line-height: 1;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:local(.gecko-bar:before) { display: block; content: $gecko-var-bar; }
:local(.gecko-column:before) { content: $gecko-var-column; }
:local(.gecko-funnel:before) { content: $gecko-var-funnel; }
:local(.gecko-geckometer:before) { content: $gecko-var-geckometer; }
:local(.gecko-leaderboard:before) { content: $gecko-var-leaderboard; }
:local(.gecko-line:before) { content: $gecko-var-line; }
:local(.gecko-number:before) { content: $gecko-var-number; }
:local(.gecko-rag:before) { content: $gecko-var-rag; }
:local(.gecko-text:before) { content: $gecko-var-text; }
:local(.gecko-table:before) { content: $gecko-var-table; }
:local(.gecko-feed:before) { content: $gecko-var-feed; }

// These icons are being reused with different aliases
:local(.gecko-list:before) { content: $gecko-var-feed}
:local(.gecko-ragcolumn:before) { content: $gecko-var-rag; }

:local(.gecko-about:before) { content: $gecko-var-about; }
:local(.gecko-filter:before) { content: $gecko-var-filter; }
:local(.gecko-format:before) { content: $gecko-var-format; }
:local(.gecko-goal:before) { content: $gecko-var-goal; }
:local(.gecko-split-by:before) { content: $gecko-var-split-by; }
:local(.gecko-split-column:before) { content: $gecko-var-split-column; }
:local(.gecko-split-line:before) { content: $gecko-var-split-line; }
:local(.gecko-clock:before) { content: $gecko-var-clock; }

:local(.gecko-sort-up:before) { content: $gecko-var-sort-up; }
:local(.gecko-sort-down:before) { content: $gecko-var-sort-down; }
:local(.gecko-sort:before) { content: $gecko-var-sort; }
:local(.gecko-more-decimals:before) { content: $gecko-var-more-decimals; }
:local(.gecko-less-decimals:before) { content: $gecko-var-less-decimals; }

:local(.gecko-map:before) { content: $gecko-var-map; }

:local(.gecko-monitoring:before) { content: $gecko-var-monitoring; }
:local(.gecko-bullet:before) { content: $gecko-var-bullet; }
:local(.gecko-highcharts:before) { content: $gecko-var-highcharts; }
:local(.gecko-pie:before) { content: $gecko-var-pie; }

:local(.gecko-slack:before) { content: $gecko-var-slack; }
:local(.gecko-star:before) { content: $gecko-var-star; }
