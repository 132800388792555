@mixin fontLight {
  font-family: "visuelt-regular-pro", Arial, sans-serif;
}

@mixin fontMedium {
  font-family: "visuelt-medium-pro", Arial, sans-serif;
}

@mixin fontBold {
  font-family: "visuelt-bold-pro", Arial, sans-serif;
}

// Font sizes ared based on the Majord Third scale displayed here https://typescale.com/
// If adding more font size mixins, use the rems from that scale
// More on typography guidelines in the frontend patterns doc
// https://www.notion.so/Current-Patterns-for-Writing-Frontend-Code-d6e644b8a0224c608ff47ffd5357fce8
@mixin fontSmall {
  font-size: 0.8rem;
  line-height: 1.25;
}

@function scaled($value) {
  @return calc($value * var(--scale-factor, 1));
}
