@import '../../style/colors', '../../style/typography';

.label {
  @include fontLight;
  cursor: pointer;

  display: flex;
  align-items: baseline;

  input {
    cursor: pointer;
    font-size: inherit;
    box-sizing: border-box;
    height: .875em;
    width: .875em;
    margin: 0 8px 0 0;
    position: relative;
    top: 1px;

    &:disabled {
      background-color: $color-background-light;
    }
  }
}
